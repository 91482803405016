/* ==========================================================================
  12.  TEAM
  =========================================================================== */

/*------------------------------------------*/
/*    TEAM MEMBER
/*------------------------------------------*/

.team-member {
	position: relative;
	z-index: 1;
	margin-bottom: 30px;
	overflow: hidden;
}

#team-2 .team-member {
	margin-bottom: 60px;
}

#team-1 .team-member > img {
	transform: scale(1);
	overflow: hidden;
	transition: transform 450ms;
}

/*------------------------------------------*/
/*    TEAM MEMBER META
/*------------------------------------------*/

.tm-meta {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 10%;
	z-index: 20;
	zoom: 1;
	opacity: 0;
	transition: all 200ms ease-in-out;
}

.tm-hiring {
	width: 100%;
	position: absolute;
	bottom: 10%;
	left: 40px;
	z-index: 20;
	zoom: 1;
	opacity: 1;
}

.team-member:hover .tm-meta {
	bottom: 8%;
	opacity: 1;
}

/*------------------------------------------*/
/*    TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/

.tm2-meta {
	padding-top: 25px;
	padding-bottom: 0;
}

.tm-meta h5.h5-sm {
	margin-bottom: 0px;
}

.tm2-meta h5.h5-sm {
	margin-bottom: 5px;
}

.tm-hiring h5.h5-sm {
	margin-bottom: 0;
}

.tm-meta span,
.tm2-meta span {
	font-size: 1rem;
	line-height: 1.1;
	font-weight: 500;
}

.tm2-meta span {
	font-weight: 300;
	display: block;
}

.tm-hiring a {
	font-size: 1rem;
	line-height: 1.1;
	font-weight: 500;
	text-decoration: underline;
}

.tm2-meta p {
	margin-top: 10px;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*    Team Member Social Links
/*------------------------------------------*/

.tm-social ul {
	display: inline-block;
	padding-left: 0;
	margin-top: 14px;
	position: absolute;
	right: 15%;
	bottom: 0;
}

.tm-social ul li {
	width: auto !important;
	display: inline-block !important;
	vertical-align: top;
	clear: none !important;
	padding: 0;
}

.tm-social a {
	width: 32px;
	height: 32px;
	color: #fff;
	font-size: 1rem;
	line-height: 32px !important;
	margin-right: 1px;
	display: block;
	border-radius: 100%;
	transition: all 400ms ease-in-out;
}

.tm-social a.ico-facebook {
	background-color: #3b5998;
}
.tm-social a.ico-twitter {
	background-color: #00a9ed;
}
.tm-social a.ico-instagram {
	background-color: #e44772;
}
.tm-social a.ico-dribbble {
	background-color: #d92d84;
}
.tm-social a.ico-behance {
	background-color: #2473f6;
}
.tm-social a.ico-pinterest {
	background-color: #ac281a;
}
.tm-social a.ico-linkedin {
	background-color: #015886;
}
.tm-social a.ico-google-plus {
	background-color: #cd1111;
}
.tm-social a.ico-youtube {
	background-color: #cd1b20;
}
.tm-social a.ico-tumblr {
	background-color: #3a5976;
}
.tm-social a.ico-vk {
	background-color: #3b5998;
}

/*------------------------------------------*/
/*    Image Hover Overlay
/*------------------------------------------*/

.tm-overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 10, 10, 0.55);
	transition: opacity 400ms ease-in-out;
}

/*------------------------------------------*/
/*    Team Member Hover Effect
/*------------------------------------------*/

#team-1 .team-member:hover > img {
	transform: scale(1.2);
}

#team-1 .team-member:hover .tm-overlay {
	opacity: 1;
}
