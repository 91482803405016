/* ==========================================================================
  05.  HERO
  =========================================================================== */

.hero-section .container {
	position: relative;
	overflow: hidden;
	z-index: 3;
}

#hero-6.hero-section .container,
#hero-11.hero-section .container,
#hero-13.hero-section .container,
#hero-15.hero-section .container,
#hero-19.hero-section .container,
#hero-24.hero-section .container,
#hero-25.hero-section .container {
	overflow: visible;
}

/*------------------------------------------*/
/*   HERO BACKGROUND IMAGES
/*------------------------------------------*/

#hero-1 {
	background-image: url('/vendor/pergo/images/hero-1.jpg');
	background-position: center bottom;
	padding-top: 220px;
	padding-bottom: 190px;
	margin-bottom: 30px;
}

#hero-2 {
	background-image: url('/vendor/pergo/images/hero-2.jpg');
}

#hero-3 {
	background-image: url('/vendor/pergo/images/hero-3.jpg');
	padding-top: 220px;
	padding-bottom: 180px;
}

#hero-4 {
	background-image: url('/vendor/pergo/images/hero-4.jpg');
	margin-top: 70px;
	padding-top: 160px;
	padding-bottom: 120px;
}

#hero-5 {
	background-image: url('/vendor/pergo/images/hero-5.jpg');
	background-position: center bottom;
	padding-top: 180px;
	padding-bottom: 120px;
}

.hero-6-text {
	background-image: url('/vendor/pergo/images/hero-6.jpg');
	background-position: center bottom;
}

#hero-6-content {
	padding-top: 180px;
	padding-bottom: 380px;
}

#hero-8 {
	padding-top: 160px;
	padding-bottom: 20px;
}

#hero-10 {
	background-image: url('/vendor/pergo/images/hero-10.jpg');
	padding-top: 240px;
	padding-bottom: 200px;
}

#hero-11-txt {
	background-image: url('/vendor/pergo/images/hero-11.jpg');
	padding-top: 180px;
	padding-bottom: 450px;
}

#hero-12 {
	padding-top: 220px;
}

#hero-13-txt {
	background-image: url('/vendor/pergo/images/hero-13.jpg');
	padding-top: 230px;
	padding-bottom: 100px;
}

#hero-14 {
	position: relative;
	padding-top: 250px;
	padding-bottom: 100px;
}

#hero-15-txt {
	background-image: url('/vendor/pergo/images/hero-15.jpg');
	padding-top: 220px;
	padding-bottom: 200px;
}

#hero-16 {
	background-image: url('/vendor/pergo/images/hero-16.jpg');
	padding-top: 160px;
}

#hero-17 {
	padding-top: 160px;
	padding-bottom: 20px;
}

#hero-18 {
	background-image: url('/vendor/pergo/images/hero-18.jpg');
	padding-top: 190px;
}

#hero-19-txt {
	background-image: url('/vendor/pergo/images/hero-19.jpg');
	padding-top: 200px;
	padding-bottom: 200px;
}

#hero-20 {
	background-image: url('/vendor/pergo/images/hero-20.jpg');
	padding-top: 220px;
	padding-bottom: 140px;
}

#hero-21 {
	background-image: url('/vendor/pergo/images/hero-21.jpg');
	background-position: center bottom;
	padding-top: 180px;
	padding-bottom: 120px;
}

#hero-22 {
	background-image: url('/vendor/pergo/images/hero-22.jpg');
	padding-top: 220px;
	padding-bottom: 140px;
}

#hero-23 {
	background-image: url('/vendor/pergo/images/hero-23.jpg');
	padding-top: 250px;
	padding-bottom: 160px;
}

#hero-24-content {
	background-image: url('/vendor/pergo/images/hero-24.jpg');
	padding-top: 180px;
	padding-bottom: 200px;
}

#hero-25 {
	padding-top: 200px;
	padding-bottom: 120px;
	border-bottom: 1px solid #ddd;
}

#hero-26 {
	background-image: url('/vendor/pergo/images/hero-26.jpg');
	padding-top: 220px;
	padding-bottom: 180px;
}

#hero-27 {
	background-image: url('/vendor/pergo/images/hero-27.jpg');
	padding-top: 180px;
	padding-bottom: 120px;
}

#hero-28 {
	position: relative;
	background-image: url('/vendor/pergo/images/hero-28.jpg');
	padding-top: 220px;
	padding-bottom: 60px;
}

#hero-29 {
	background-image: url('/vendor/pergo/images/hero-29.jpg');
	padding-top: 280px;
	padding-bottom: 180px;
}

#hero-30 {
	padding-top: 180px;
	padding-bottom: 80px;
}

/*------------------------------------------*/
/*   HERO OVERLAY
/*------------------------------------------*/

#hero-9 .hero-overlay {
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

/*------------------------------------------*/
/*  HERO TEXT ROTATOR
/*------------------------------------------*/

.hero-section .hero-slider {
	height: 760px;
	position: relative;
}

.hero-section .slides {
	position: absolute;
	top: 53%;
	transform: translateY(-50%);
}

/*------------------------------------------*/
/*   HERO FULLSCREEN SLIDER
/*------------------------------------------*/

#hero-7 {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0px;
	z-index: 1;
	display: block;
	overflow: hidden;
	padding: 0 !important;
}

#slides {
	position: relative;
}

#slides .scrollable {
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	*zoom: 1;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

#slides .scrollable:after {
	content: '';
	display: table;
	clear: both;
}

/*------------------------------------------*/
/*   FULLSCREEN SLIDER CONTENT
/*------------------------------------------*/

.hero-content {
	width: 80%;
	position: absolute;
	left: 50%;
	top: 52%;
	color: #fff;
	text-align: center;
	display: inline-block;
	padding: 50px 0;
	transform: translate(-50%, -50%);
}

/*------------------------------------------*/
/*   Hero Headers
/*------------------------------------------*/

.hero-section h4,
.hero-section h3,
.hero-section h2 {
	margin-bottom: 25px;
}

#hero-1.hero-section h2 {
	font-size: 4.25rem;
	letter-spacing: -0.5px;
	margin-bottom: 40px;
}

.slides h2 {
	font-size: 5.25rem;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -0.5px;
	margin-bottom: 15px;
}

#hero-9 .slides h2 {
	font-size: 6rem;
	letter-spacing: -1px;
}

#hero-4.hero-section h2 {
	font-size: 4.5rem;
	font-weight: 800;
	letter-spacing: -1px;
}

#hero-7.hero-section h2 {
	font-size: 5.5rem;
	line-height: 1;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -2px;
	margin-bottom: 20px;
}

#hero-8 h2.h2-xl {
	line-height: 1.2;
	letter-spacing: -1.5px;
}

#hero-10 h2 {
	font-size: 6.25rem;
	letter-spacing: -1.5px;
	font-weight: 800;
	margin-bottom: 10px;
}

#hero-16 h2 {
	font-size: 6.25rem;
	line-height: 1;
	letter-spacing: -1.5px;
	font-weight: 800;
	margin-bottom: 30px;
}

#hero-18 h3.h3-xl {
	margin-bottom: 45px;
}

#hero-19 .hero-txt h2 {
	font-size: 5rem;
	font-weight: 800;
	line-height: 1.15;
	letter-spacing: -1px;
	margin-bottom: 25px;
}

#hero-20 h4 {
	background-color: #ff3366;
	font-weight: 400;
	line-height: 1.3;
	display: inline-block;
	padding: 5px 20px;
}

#hero-20 h2 {
	font-size: 5.75rem;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: 25px;
	margin-bottom: 60px;
}

#hero-21 .hero-txt h2 {
	font-size: 4rem;
	font-weight: 500;
	letter-spacing: -1px;
	margin-bottom: 25px;
}

#hero-22 .hero-txt h2 {
	font-size: 4rem;
	font-weight: 600;
	letter-spacing: 0;
}

#hero-23 .hero-txt h2 {
	font-size: 4.5rem;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -0.5px;
	margin-bottom: 25px;
}

#hero-24 .hero-txt h2 {
	font-size: 13rem;
	line-height: 1;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -5px;
	margin-top: 12px;
	margin-bottom: 12px;
}

#hero-24 .hero-txt h3 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0;
	margin-bottom: 0;
}

#hero-26 .hero-txt h2 {
	font-size: 5.5rem;
	line-height: 1.1;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -0.5px;
	margin-bottom: 15px;
}

#hero-26 .hero-txt h3 {
	font-size: 2rem;
	margin-bottom: 10px;
}

#hero-26 .hero-price h2 {
	font-size: 5.5em;
	line-height: 1;
	font-weight: 800;
	margin-bottom: 5px;
}

#hero-26 .hero-price h4 {
	font-size: 1.5em;
	line-height: 1;
	font-weight: 400;
	margin-bottom: 15px;
}

#hero-28 .hero-txt h3 {
	font-size: 4.5em;
	line-height: 1.15;
	font-weight: 400;
	letter-spacing: -1px;
	margin-bottom: 0;
}

#hero-29 .hero-txt h2 {
	font-size: 5.5em;
	font-weight: 700;
	letter-spacing: -1px;
	margin-bottom: 25px;
}

#hero-30 .hero-txt h2 {
	font-size: 4em;
	line-height: 1;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -1px;
	margin-top: 50px;
	margin-bottom: 20px;
}

#hero-30 .hero-txt h5 {
	font-weight: 300;
	line-height: 1.3;
	padding: 0 10%;
	margin-bottom: 35px;
}

.hero-inner-txt h3 {
	margin-bottom: 35px;
}

.hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
	font-weight: 700;
	letter-spacing: -1px;
	padding-bottom: 11px;
}

.underline {
	background-image: url('/vendor/pergo/images/underline.png');
	background-repeat: no-repeat;
	background-size: 100% 0.25em;
	background-position: center bottom;
}

/*------------------------------------------*/
/*   Hero Paragraph
/*------------------------------------------*/

.hero-txt p.p-hero {
	font-size: 1.35rem;
	margin-bottom: 40px;
}

.hero-price p.p-hero {
	font-size: 1.35rem;
	margin-bottom: 15px;
}

#hero-1 .hero-txt p {
	padding: 0 8%;
}

#hero-2 .hero-txt p,
#hero-3 .hero-txt p,
#hero-6 .hero-txt p,
#hero-9 .hero-txt p,
#hero-15 .hero-txt p,
#hero-18 .hero-txt p {
	padding: 0 12%;
}

#hero-4 .hero-txt p,
#hero-21 .hero-txt p {
	padding-right: 10%;
}

#hero-5 .hero-txt p,
#hero-22 .hero-txt p,
#hero-27 .hero-txt p {
	padding-right: 15%;
}

#hero-11 .hero-txt p,
#hero-23 .hero-txt p {
	padding: 0 10%;
}

#hero-16 .hero-txt p {
	padding-left: 10%;
}

#hero-20 p {
	font-size: 1.5rem;
	margin-bottom: 20px;
	padding-right: 10%;
}

.hero-inner-txt p {
	margin-bottom: 40px;
}

#hero-19 .hero-txt p {
	padding-right: 5%;
}

#hero-24 .hero-txt p {
	margin-top: 30px;
	padding-right: 20%;
	margin-bottom: 0;
}

#hero-29 .hero-txt p {
	padding-right: 20%;
}

/*------------------------------------------*/
/*    HERO BUTTONS
/*------------------------------------------*/

.hero-btns {
	margin-top: 35px;
}

/*------------------------------------------*/
/*    HERO LINKS
/*------------------------------------------*/

.hero-links {
	margin-top: 10px;
}

.hero-links span {
	font-size: 1rem;
	line-height: 1;
	margin-right: 25px;
}

.hero-links span:last-child {
	margin-right: 0;
}

.hero-links span a {
	text-decoration: underline;
}

.hero-links span a:hover {
	opacity: 0.8;
}

/*------------------------------------------*/
/*    HERO STORE BADGES
/*------------------------------------------*/

.hero-stores-badge span {
	display: block;
	font-size: 0.9rem;
	font-style: italic;
	line-height: 1;
	margin-top: 20px;
}

/*------------------------------------------*/
/*    HERO INNER CONTENT
/*------------------------------------------*/

.hero-inner-block {
	background-image: -webkit-linear-gradient(top, rgba(83, 45, 95, 0.8), rgba(92, 67, 155, 0.9));
	background-image: linear-gradient(top, rgba(83, 45, 95, 0.8), rgba(92, 67, 155, 0.9));
	position: relative;
	z-index: 999;
	margin-top: -410px;
	border-radius: 6px;
}

.hero-inner-txt {
	padding: 0 80px 0 40px;
}

.hero-inner-img img {
	border-radius: 6px 0 0 6px;
}

/*------------------------------------------*/
/*    HERO DISCOUNT BANNER
/*------------------------------------------*/

.discount-banner {
	position: relative;
	z-index: 999;
	padding: 60px 50px;
	border-radius: 6px;
	margin-top: -320px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.discount-banner p.p-xl {
	line-height: 1.35;
	margin-bottom: 25px;
}

.discount-banner h2.h2-xl {
	font-size: 6rem;
	line-height: 1;
	margin-bottom: 25px;
}

/*------------------------------------------*/
/*    HERO STATISTIC
/*------------------------------------------*/

.hero-statistic {
	background-color: #fff;
	position: relative;
	z-index: 999;
	padding: 50px 30px 20px 30px;
	border-radius: 6px;
	margin: -100px 80px 0;
	padding-bottom: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.hero-number {
	font-size: 2.25rem;
	line-height: 1;
	font-weight: 700;
	letter-spacing: -0.5px;
	margin-bottom: 5px;
}

.hero-statistic p {
	color: #757575;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   HERO IMAGE
/*------------------------------------------*/

.hero-img {
	text-align: center;
}

.hero-6-image img {
	margin-top: -330px;
}

.hero-14-img {
	position: absolute;
	top: 5%;
	right: -35%;
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/*------------------------------------------*/
/*    HERO BOXES
/*------------------------------------------*/

.hero-19-boxes {
	margin-top: -120px;
}

#hero-28-boxes {
	margin-top: 100px;
}

.hbox-1 {
	background-color: #fff;
	position: relative;
	z-index: 999;
	padding: 55px 35px;
	border-radius: 4px;
	border-top: 3px solid #7ace4c;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.hbox-2 {
	padding-right: 10px;
	margin-bottom: 40px;
}

.hbox-1 h5 {
	line-height: 1.25;
	margin-bottom: 18px;
}

.hbox-2 h5 {
	line-height: 1.25;
	margin-bottom: 20px;
}

.hbox-1 p,
.hbox-2 p {
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   HERO REGISTER FORM
/*------------------------------------------*/

#hero-24 h4.h4-xs {
	font-weight: 800;
	letter-spacing: 0;
	margin-bottom: 15px;
}

.hero-form form {
	background: #fff;
	border: 1px solid #fafafa;
	padding: 55px 40px 55px;
	border-radius: 0;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

#hero-24 .hero-form form {
	background: #333;
	border: 1px solid #303030;
	padding: 65px 40px 65px;
}

/*------------------------------------------*/
/*    Register Form Input
/*------------------------------------------*/

.register-form .col-md-12 {
	padding: 0;
}

.hero-form .form-control {
	background-color: transparent;
	color: #333;
	height: 46px;
	font-size: 15px;
	font-weight: 300;
	padding: 4px 6px;
	margin-bottom: 15px;
	border: none;
	border-bottom: 1px solid #eee;
	border-radius: 0;
	box-shadow: none;
}

#hero-24 .hero-form .form-control {
	background-color: #fff;
	padding: 4px 15px;
}

.hero-form .form-control:focus {
	border-color: #ff3366;
	outline: 0px none;
	box-shadow: none;
}

/*------------------------------------------*/
/*    Register Form Button
/*------------------------------------------*/

.hero-form .btn {
	display: block;
	width: 100%;
	margin-top: 20px;
}

/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/

.hero-form .loading {
	color: #666;
	font-size: 16px;
	font-weight: 700;
	margin-top: 20px;
}

.error {
	color: #e74c3c;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 20px;
}

/*------------------------------------------*/
/*    HERO NEWSLETTER FORM
/*------------------------------------------*/

.hero-section .newsletter-form {
	margin: 0 15%;
}

#hero-21.hero-section .newsletter-form {
	margin-top: 40px;
}

/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/

.hero-section .newsletter-form .form-control {
	height: 57px;
	background-color: #fff;
	border: 1px solid #eee;
	border-right: none;
	color: #222;
	font-size: 1.15rem;
	font-weight: 400;
	padding: 0 1.4rem;
	box-shadow: none;
	border-radius: 15px 0 0 15px;
}

.hero-section .newsletter-form .form-control:focus {
	border: 1px solid #eee;
	border-right: none;
	outline: 0;
	box-shadow: none;
}

/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/

.hero-section .newsletter-form .form-control::-moz-placeholder {
	color: #999;
}
.hero-section .newsletter-form .form-control:-ms-input-placeholder {
	color: #999;
}
.hero-section .newsletter-form .form-control::-webkit-input-placeholder {
	color: #999;
}

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

.hero-section .newsletter-form .btn {
	width: 100%;
	height: 57px;
	background-color: #fff;
	border: 1px solid #eee;
	border-left: none;
	color: #555;
	font-size: 1.25rem;
	margin-top: 0;
	padding: 0 1.4rem;
	border-radius: 0 15px 15px 0;
}

.hero-section .newsletter-form .btn:hover {
	color: #444;
}

/*------------------------------------------*/
/*   HERO TEXT ROTATOR DOTS
/*------------------------------------------*/

.hero-slider .flex-control-nav {
	bottom: 5%;
}

.hero-slider .flex-control-paging li a {
	background: rgba(240, 240, 240, 0.15);
	width: 9px;
	height: 9px;
	border: 2px solid transparent;
}

.hero-slider .flex-control-paging li a.flex-active {
	background: rgba(255, 255, 255, 0.95);
	border: 2px solid rgba(255, 255, 255, 0.95);
}

/*------------------------------------------*/
/*   FULLSCREEN SLIDER PAGINATION
/*------------------------------------------*/

.slides-pagination {
	width: 100%;
	position: absolute;
	bottom: 5%;
	z-index: 20;
	text-align: center;
}

.slides-pagination a {
	width: 30px;
	height: 4px;
	background-color: #ddd;
	color: #1a1a1a;
	opacity: 0.2;
	display: inline-block;
	vertical-align: middle;
	*vertical-align: auto;
	zoom: 1;
	*display: inline;
	margin: 2px;
	overflow: hidden;
	text-indent: -100%;
}

.slides-pagination a:hover,
.slides-pagination a.current {
	background-color: #fff;
	opacity: 1;
}
