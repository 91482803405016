/* ==========================================================================
  20.  FAQs
  =========================================================================== */

/*------------------------------------------*/
/*    FAQs TYPOGRAPHY
/*------------------------------------------*/

.question {
	margin-bottom: 30px;
}

.question h5 {
	line-height: 1.4;
	letter-spacing: 0;
	margin-bottom: 10px;
}

.question p {
	color: #666;
	font-size: 1.05rem;
	line-height: 1.6;
}

.more-questions-btn {
	margin-top: 40px;
}

.faqs-btn {
	margin-top: 30px;
}
