/* ==========================================================================
  26. FOOTER
  =========================================================================== */

.footer {
	padding-top: 80px;
}

.footer.bg-dark {
	padding-top: 100px;
}

/*------------------------------------------*/
/*   FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h4,
.footer h5 {
	margin-bottom: 25px;
}

.footer p.p-lg {
	font-weight: 600;
	margin-bottom: 3px;
}

.footer p.p-lg a {
	text-decoration: underline;
}

.footer-copyright p {
	margin-bottom: 0;
}

.footer-copyright p span {
	font-weight: 700;
}

.bg-dark .footer-form p,
.bg-deepdark .footer-form p,
.bg-dark .footer-info p,
.bg-deepdark .footer-info p,
.bg-dark .footer-copyright p,
.bg-deepdark .footer-copyright p,
.bg-dark .footer-copyright p,
.bg-deepdark .footer-links li a {
	color: #ccc;
}

.bg-dark .footer-copyright p span,
.bg-deepdark .footer-copyright p span {
	color: #fff;
}

/*------------------------------------------*/
/*   FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
	width: auto !important;
	display: block !important;
	vertical-align: top;
	clear: none !important;
	margin: 0 0 10px 0;
	padding: 0;
}

.footer-links li a {
	font-size: 1rem;
}

.footer-links li a:hover {
	color: #000;
	text-decoration: underline;
}

.bg-dark .footer-links li a:hover,
.bg-deepdark .footer-links li a:hover {
	color: #fff;
}

/*------------------------------------------*/
/*   FOOTER SOCIAL LINKS
/*------------------------------------------*/

.foo-links {
	display: inline-block;
	padding-left: 0;
	margin: 20px auto 0;
}

.foo-links li {
	width: auto !important;
	display: inline-block !important;
	vertical-align: top;
	clear: none !important;
	margin: 0 0 8px 0;
	padding: 0;
}

.foo-links a {
	width: 40px;
	height: 40px;
	color: #888;
	border: 2px solid rgba(240, 240, 240, 0.05);
	background-color: rgba(200, 200, 200, 0.45);
	font-size: 1.1rem;
	line-height: 36px !important;
	margin-right: 4px;
	display: block;
	border-radius: 100%;
	transition: all 400ms ease-in-out;
}

.bg-dark .foo-links a,
.bg-deeppurple .foo-links a {
	color: #ccc;
	background-color: rgba(220, 220, 220, 0.2);
	border: 1px solid transparent;
}

.bg-deepdark .foo-links a {
	color: #999;
	background-color: rgba(50, 50, 50, 0.7);
	border: 1px solid rgba(50, 50, 50, 0.7);
}

.foo-links a:hover {
	color: #fff;
}

.foo-links a.ico-facebook:hover {
	background-color: #3b5998;
	border-color: #3b5998;
}
.foo-links a.ico-twitter:hover {
	background-color: #00a9ed;
	border-color: #00a9ed;
}
.foo-links a.ico-instagram:hover {
	background-color: #e44772;
	border-color: #e44772;
}
.foo-links a.ico-dribbble:hover {
	background-color: #d92d84;
	border-color: #d92d84;
}
.foo-links a.ico-behance:hover {
	background-color: #2473f6;
	border-color: #2473f6;
}
.foo-links a.ico-pinterest:hover {
	background-color: #ac281a;
	border-color: #ac281a;
}
.foo-links a.ico-linkedin:hover {
	background-color: #015886;
	border-color: #015886;
}
.foo-links a.ico-google-plus:hover {
	background-color: #cd1111;
	border-color: #cd1111;
}
.foo-links a.ico-youtube:hover {
	background-color: #cd1b20;
	border-color: #cd1b20;
}
.foo-links a.ico-tumblr:hover {
	background-color: #3a5976;
	border-color: #3a5976;
}
.foo-links a.ico-vk:hover {
	background-color: #3b5998;
	border-color: #3b5998;
}

/*------------------------------------------*/
/*   FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form .form-control {
	border-radius: 6px 0 0 6px;
	transition: all 400ms ease-in-out;
}

.bg-lightgrey .footer-form .form-control {
	background-color: #f7f7f7;
	border: 2px solid #bbb;
	border-right: none;
}

.bg-dark .footer-form .form-control {
	background-color: rgba(220, 220, 220, 0.2);
	color: #fff;
	border: 2px solid rgba(220, 220, 220, 0.2);
	border-right: none;
}

.bg-deepdark .footer-form .form-control {
	background-color: rgba(50, 50, 50, 0.7);
	color: #fff;
	border: 2px solid rgba(50, 50, 50, 0.7);
	border-right: none;
}

/*------------------------------------------*/
/*    Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
	border: 1px solid #888;
	border-right: none;
	outline: 0;
	box-shadow: none;
}

.bg-lightgrey .footer-form .form-control:focus {
	background-color: #fff;
	border: 2px solid #999;
	border-right: none;
}

.bg-dark .footer-form .form-control:focus {
	background-color: transparent;
	border: 2px solid #ccc;
	border-right: none;
}

.bg-deepdark .footer-form .form-control:focus {
	background-color: #1d1d1d;
	border: 2px solid #ccc;
	border-right: none;
}

/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/

.bg-dark .footer-form .form-control::-moz-placeholder {
	color: #ccc;
}
.bg-dark .footer-form .form-control:-ms-input-placeholder {
	color: #ccc;
}
.bg-dark .footer-form .form-control::-webkit-input-placeholder {
	color: #ccc;
}

.bg-deepdark .footer-form .form-control::-moz-placeholder {
	color: #999;
}
.bg-deepdark .footer-form .form-control:-ms-input-placeholder {
	color: #999;
}
.bg-deepdark .footer-form .form-control::-webkit-input-placeholder {
	color: #999;
}

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
	padding: 0.7rem 1.45rem;
	border-radius: 0 6px 6px 0;
}

.footer-form .svg-inline--fa {
	font-size: 1.35rem;
	line-height: 1 !important;
}

/*------------------------------------------*/
/*    Newsletter Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1;
	margin-top: 15px;
	margin-left: 5px;
}

/*------------------------------------------*/
/*   BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
	padding-top: 30px;
	padding-bottom: 50px;
}

.bg-dark .bottom-footer {
	padding-bottom: 70px;
}
