/* ==========================================================================
  02.  TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #222;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	letter-spacing: -0.025rem; /* -0.4px */
}

/* Header H5 */
h5.h5-xs {
	font-size: 1.125rem;
} /* 18px */
h5.h5-sm {
	font-size: 1.1875rem;
} /* 19px */
h5.h5-md {
	font-size: 1.25rem;
} /* 20px */
h5.h5-lg {
	font-size: 1.375rem;
} /* 22px */
h5.h5-xl {
	font-size: 1.5rem;
} /* 24px */

/* Header H4 */
h4.h4-xs {
	font-size: 1.625rem;
} /* 26px */
h4.h4-sm {
	font-size: 1.75rem;
} /* 28px */
h4.h4-md {
	font-size: 1.875rem;
} /* 30px */
h4.h4-lg {
	font-size: 2rem;
} /* 32px */
h4.h4-xl {
	font-size: 2.125rem;
} /* 34px */

/* Header H3 */
h3.h3-xs {
	font-size: 2.375rem;
} /* 38px */
h3.h3-sm {
	font-size: 2.5rem;
} /* 40px */
h3.h3-md {
	font-size: 2.625rem;
} /* 42px */
h3.h3-lg {
	font-size: 2.75rem;
} /* 44px */
h3.h3-xl {
	font-size: 3rem;
} /* 48px */

/* Header H2 */
h2.h2-xs {
	font-size: 3.25rem;
} /* 52px */
h2.h2-sm {
	font-size: 3.375rem;
} /* 54px */
h2.h2-md {
	font-size: 3.5rem;
} /* 56px */
h2.h2-lg {
	font-size: 3.75rem;
} /* 60px */
h2.h2-xl {
	font-size: 4rem;
} /* 64px */
h2.h2-huge {
	font-size: 4.25rem;
} /* 64px */

/*------------------------------------------*/
/*   PARAGRAPHS
/*------------------------------------------*/

p.p-sm {
	font-size: 0.95rem;
} /* 15.2px */
p {
	font-size: 1rem;
} /* 16px */
p.p-md {
	font-size: 1.125rem;
} /* 18px */
p.p-lg {
	font-size: 1.25rem;
} /* 20px */
p.p-xl {
	font-size: 1.3175rem;
} /* 22px */

/*------------------------------------------*/
/*   LISTS
/*------------------------------------------*/

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

/*------------------------------------------*/
/*   LINK SETTINGS
/*------------------------------------------*/

a {
	color: #333;
	text-decoration: none;
	transition: all 450ms ease-in-out;
}

a:hover {
	color: #666;
	text-decoration: none;
}

a.black-hover:hover {
	color: #000;
}
a.green-hover:hover {
	color: #56a959;
}
a.lightgreen-hover:hover {
	color: #22bc3f;
}
a.rose-hover:hover {
	color: #ff3366;
}
a.purple-hover:hover {
	color: #6e45e2;
}
a.blue-hover:hover {
	color: #3176ed;
}
a.skyblue-hover:hover {
	color: #0b88ee;
}

a:focus {
	outline: none;
	text-decoration: none;
}

/*------------------------------------------*/
/*   BUTTON SETTINGS
/*------------------------------------------*/

.btn {
	background-color: #ff3366;
	color: #fff;
	font-size: 1rem;
	line-height: 1;
	font-weight: 400;
	letter-spacing: 0;
	padding: 1.025rem 2.5rem;
	border: 2px solid #ff3366;
	border-radius: 100px;
	transition: all 400ms ease-in-out;
}

.btn-arrow {
	position: relative;
}

.btn-arrow span {
	display: inline-block;
	position: relative;
	transition: all 300ms ease-in-out;
	will-change: transform;
}

.btn-arrow:hover span,
.btn-arrow:focus span {
	transform: translate3d(-10px, 0, 0);
}

.btn-arrow .svg-inline--fa {
	position: absolute;
	line-height: 1rem !important;
	right: 0px;
	opacity: 0;
	top: 50%;
	transform: translateY(-50%);
	transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*   Button Size
/*------------------------------------------*/

.btn.btn-sm {
	font-size: 0.9rem;
	padding: 0.85rem 1.95rem;
}

.btn.btn-md {
	font-size: 1rem;
	padding: 1.025rem 2.5rem;
}

.btn.btn-lg {
	font-size: 1.1rem;
	padding: 1.05rem 3rem;
}

/*------------------------------------------*/
/*   Button Color
/*------------------------------------------*/

.btn-tra,
.white-color .btn-tra {
	color: #ff3366;
	background-color: transparent;
	border-color: #ff3366;
}

.btn-tra-purple {
	color: #6e45e2;
	background-color: transparent;
	border-color: #6e45e2;
}

.btn-tra-skyblue {
	color: #0b88ee;
	background-color: transparent;
	border-color: #0b88ee;
}

.btn-tra-black {
	color: #3a3a3a;
	background-color: transparent;
	border-color: #3a3a3a;
}

.btn-tra-white {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}

.btn-semiwhite {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.35);
	border-color: transparent;
}

.btn-purple {
	color: #fff;
	background-color: #6e45e2;
	border-color: #6e45e2;
}

.btn-blue {
	color: #fff;
	background-color: #3176ed;
	border-color: #3176ed;
}

.btn-skyblue {
	color: #fff;
	background-color: #42a5f5;
	border-color: #42a5f5;
}

.btn-black {
	color: #fff;
	background-color: #3a3a3a;
	border-color: #3a3a3a;
}

.btn-white {
	color: #333;
	background-color: #fff;
	border-color: #fff;
}

.white-color .btn-white span,
.white-color .btn-yellow span {
	color: #333;
}

.btn-yellow {
	color: #333;
	background-color: #feca16;
	border-color: #feca16;
}

.btn-green {
	color: #fff;
	background-color: #56a959;
	border-color: #56a959;
}

.btn-lightgreen {
	color: #fff;
	background-color: #22bc3f;
	border-color: #22bc3f;
}

.btn-red {
	color: #fff;
	background-color: #e35029;
	border-color: #e35029;
}

.btn-teal {
	color: #fff;
	background-color: #5fa9a8;
	border-color: #5fa9a8;
}

/*------------------------------------------*/
/*   Button Hover
/*------------------------------------------*/

.btn:hover {
	color: #fff;
	background-color: #e62354;
	border-color: #e62354;
}

.btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
	opacity: 1;
	right: -20px;
}

.btn-tra:hover {
	background-color: #ff3366;
	border-color: #ff3366;
}

.btn-purple:hover,
.btn-tra-purple:hover {
	background-color: #5830c7;
	border-color: #5830c7;
}

.btn-blue:hover {
	background-color: #2b68d1;
	border-color: #2b68d1;
}

.btn-skyblue:hover,
.btn-tra-skyblue:hover {
	background-color: #0b88ee;
	border-color: #0b88ee;
}

.btn-black:hover,
.btn-tra-black:hover {
	background-color: #151515;
	border-color: #151515;
}

.btn-white:hover {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}

.white-color .btn-white:hover span {
	color: #fff;
}

.white-color .btn-tra-white.white-hover:hover,
.btn-tra-white.white-hover:hover {
	color: #333;
	background-color: #fff;
	border-color: #fff;
}

.btn-tra-white.purple-hover:hover {
	color: #fff;
	background-color: #5830c7;
	border-color: #5830c7;
}

.btn-tra-white.green-hover:hover {
	color: #fff;
	background-color: #1da036;
	border-color: #1da036;
}

.btn-tra-white.blue-hover:hover {
	color: #fff;
	background-color: #2b68d1;
	border-color: #2b68d1;
}

.btn.tra-hover:hover,
.btn-yellow.tra-hover:hover,
.btn-purple.tra-hover:hover,
.btn-lightgreen.tra-hover:hover,
.btn-semiwhite:hover {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}

.btn-yellow.tra-hover:hover span {
	color: #fff;
}

.btn-tra-white.tra-hover:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.35);
	border-color: transparent;
}

.btn-yellow:hover {
	color: #333;
	background-color: #f6c007;
	border-color: #f6c007;
}

.btn-green:hover {
	background-color: #4b924e;
	border-color: #4b924e;
}

.btn-lightgreen:hover {
	background-color: #1da036;
	border-color: #1da036;
}

.btn-red:hover {
	background-color: #c84624;
	border-color: #c84624;
}

.btn-teal:hover {
	background-color: #4d8d8c;
	border-color: #4d8d8c;
}

/*------------------------------------------*/
/*   Button Focus
/*------------------------------------------*/

.btn:focus {
	color: #fff;
	box-shadow: none;
}

/*------------------------------------------*/
/*   VIDEO POPUP LINK
/*------------------------------------------*/

.modal-video {
	display: inline-block;
	vertical-align: middle;
	line-height: 2.15rem;
}

.modal-video a span {
	font-size: 2.15rem;
	line-height: 2.15rem;
	margin-right: 4px;
	vertical-align: middle;
}

.modal-video a {
	font-size: 1.05rem;
	line-height: 2.15rem;
	font-weight: 400;
}

/*------------------------------------------*/
/*   VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
	position: relative;
}

.video-btn {
	display: inline-block;
}

.video-block {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
}

/*------------------------------------------*/
/*  Video Button Icon
/*------------------------------------------*/

.video-btn-xs,
.video-btn-sm,
.video-btn-md,
.video-btn-lg {
	position: relative;
	display: inline-block;
	background: rgba(255, 255, 255, 0.3);
	padding: 15px;
	border-radius: 50%;
	transition: all 400ms ease-in-out;
}

.video-btn-xs:before,
.video-btn-sm:before,
.video-btn-md:before,
.video-btn-lg:before {
	content: '';
	position: absolute;
	left: -5px;
	right: -5px;
	top: -5px;
	bottom: -5px;
	background: rgba(255, 255, 255, 0.2);
	opacity: 0;
	border-radius: 50%;
	transition: all 400ms ease-in-out;
}

.video-preview .video-btn-xs,
.video-preview .video-btn-sm,
.video-preview .video-btn-md,
.video-preview .video-btn-lg {
	position: absolute !important;
	top: 50%;
	left: 50%;
}

.video-preview .video-btn-xs {
	margin-top: -40px;
	margin-left: -40px;
}

.video-preview .video-btn-sm {
	margin-top: -45px;
	margin-left: -45px;
}

.video-preview .video-btn-md {
	margin-top: -50px;
	margin-left: -50px;
}

.video-preview .video-btn-lg {
	margin-top: -65px;
	margin-left: -65px;
}

.video-btn-xs,
.video-btn .video-btn-xs {
	width: 110px;
	height: 110px;
}

.video-btn-sm,
.video-btn .video-btn-sm {
	width: 120px;
	height: 120px;
}

.video-btn-md,
.video-btn .video-btn-md {
	width: 130px;
	height: 130px;
}

.video-btn-lg,
.video-btn .video-btn-lg {
	width: 150px;
	height: 150px;
}

.video-btn-xs .svg-inline--fa,
.video-btn-sm .svg-inline--fa,
.video-btn-md .svg-inline--fa,
.video-btn-lg .svg-inline--fa {
	position: relative;
	transition: all 400ms ease-in-out;
}

.video-btn-xs .svg-inline--fa {
	width: 80px;
	height: 80px;
}

.video-btn-sm .svg-inline--fa {
	width: 90px;
	height: 90px;
}

.video-btn-md .svg-inline--fa {
	width: 100px;
	height: 100px;
}

.video-btn-lg .svg-inline--fa {
	width: 120px;
	height: 120px;
}

/*------------------------------------------*/
/*  Video Icon Color
/*------------------------------------------*/

.play-icon-white {
	color: #fff;
}
.play-icon-grey {
	color: #999;
}
.play-icon-purple {
	color: #5830c7;
}
.play-icon-green {
	color: #56a959;
}
.play-icon-lightgreen {
	color: #22bc3f;
}
.play-icon-rose {
	color: #ff3366;
}
.play-icon-blue {
	color: #3176ed;
}
.play-icon-skyblue {
	color: #0b88ee;
}
.play-icon-yellow {
	color: #feca16;
}

/*------------------------------------------*/
/*  Video Icon Background
/*------------------------------------------*/

.ico-bkg {
	background-color: #fff;
	position: absolute;
	top: 40px;
	left: 40px;
}

.play-icon-white .ico-bkg {
	background-color: #444;
}

.video-btn-xs .ico-bkg {
	top: 30px;
	width: 38px;
	height: 50px;
}

.video-btn-sm .ico-bkg {
	top: 30px;
	width: 44px;
	height: 55px;
}

.video-btn-md .ico-bkg {
	width: 50px;
	height: 50px;
}

.video-btn-lg .ico-bkg {
	width: 65px;
	height: 70px;
}

/*------------------------------------------*/
/*   Hover
/*------------------------------------------*/

.video-btn-xs:hover .svg-inline--fa,
.video-btn-sm:hover .svg-inline--fa,
.video-btn-md:hover .svg-inline--fa,
.video-btn-lg:hover .svg-inline--fa {
	transform: scale(0.9);
}

.video-btn-xs:hover:before,
.video-btn-sm:hover:before,
.video-btn-md:hover:before,
.video-btn-lg:hover:before {
	opacity: 1;
	left: -15px;
	right: -15px;
	top: -15px;
	bottom: -15px;
}

/*------------------------------------------*/
/*  Video Icon Text
/*------------------------------------------*/

.play-btn-txt {
	display: block;
	font-size: 0.95rem;
	line-height: 1;
	font-weight: 400;
	margin-top: 20px;
}

/*------------------------------------------*/
/*   APP DEVICES
/*------------------------------------------*/

.app-devices {
	padding: 15px 0 0 5px;
}

/*------------------------------------------*/
/*   App Devices Icons
/*------------------------------------------*/

.app-devices .svg-inline--fa {
	color: rgba(50, 50, 50, 0.3);
	font-size: 2.6rem;
	line-height: 1 !important;
	float: left;
	margin-right: 0.65rem;
}

.bg-green .app-devices .svg-inline--fa,
.bg-lightpurple .app-devices .svg-inline--fa,
.bg-lightblue .app-devices .svg-inline--fa {
	color: rgba(240, 240, 240, 0.5);
}

.app-devices .svg-inline--fa.f-tablet {
	transform: rotate(90deg);
}

.app-devices .svg-inline--fa.f-phone {
	margin-right: 1.25rem;
}

.app-devices-desc p {
	font-style: italic;
	line-height: 1.25;
	padding-right: 20%;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   ANIMATED PROGRESS BAR
/*------------------------------------------*/

.barWrapper {
	position: relative;
	margin-bottom: 25px;
}

.progress {
	height: 8px;
}

.bg-dark .progress {
	background-color: #777;
}

.bg-deepdark .progress {
	background-color: #777;
}

.bg-lightgrey .progress {
	background-color: #ccc;
}

.progress-bar {
	width: 0;
}

/*------------------------------------------*/
/*   Progress Bar Color
/*------------------------------------------*/

.black-progress .progress-bar {
	background-color: #333;
}

.green-progress .progress-bar {
	background-color: #56a959;
}

.rose-progress .progress-bar {
	background-color: #ff3366;
}

.blue-progress .progress-bar {
	background-color: #0b88ee;
}

.purple-progress .progress-bar {
	background-color: #5830c7;
}

.barWrapper p {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 7px;
}

.skill-percent {
	display: inline-block;
	position: absolute;
	font-size: 1rem;
	line-height: 1;
	font-weight: 600;
	top: 7px;
	right: 2px;
}

/*------------------------------------------*/
/*  BOX ICONS
/*------------------------------------------*/

.box-icon [class^='flaticon-']:before,
.box-icon [class*=' flaticon-']:before,
.box-icon [class^='flaticon-']:after,
.box-icon [class*=' flaticon-']:after {
	font-family: Flaticon;
	font-size: 4rem;
	line-height: 4rem !important;
	font-style: normal;
	margin-left: 0;
}

.box-icon-sm [class^='flaticon-']:before,
.box-icon-sm [class*=' flaticon-']:before,
.box-icon-sm [class^='flaticon-']:after,
.box-icon-sm [class*=' flaticon-']:after {
	font-family: Flaticon;
	font-size: 3.5rem;
	line-height: 3.5rem !important;
	font-style: normal;
	margin-left: 0;
}

.box-icon-md [class^='flaticon-']:before,
.box-icon-md [class*=' flaticon-']:before,
.box-icon-md [class^='flaticon-']:after,
.box-icon-md [class*=' flaticon-']:after {
	font-family: Flaticon;
	font-size: 4.5rem;
	line-height: 4.5rem !important;
	font-style: normal;
	margin-left: 0;
}

.box-icon-lg [class^='flaticon-']:before,
.box-icon-lg [class*=' flaticon-']:before,
.box-icon-lg [class^='flaticon-']:after,
.box-icon-lg [class*=' flaticon-']:after {
	font-family: Flaticon;
	font-size: 5rem;
	line-height: 5rem !important;
	font-style: normal;
	margin-left: 0;
}

/*------------------------------------------*/
/*   BOX ICON COLOR
/*------------------------------------------*/

.white-color .grey-icon span,
.grey-icon span {
	color: #757575;
	transition: all 400ms ease-in-out;
}

.white-color .red-icon span,
.red-icon span {
	background: -webkit-gradient(linear, left top, left bottom, from(#ee041a), to(#ff8212));
}

.white-color .rose-icon span,
.rose-icon span {
	color: #ff3366;
}

.white-color .green-icon span,
.green-icon span {
	color: #22bc3f;
}

.white-color .blue-icon span,
.blue-icon span {
	color: #0767c7;
}

.white-color .skyblue-icon span,
.skyblue-icon span {
	color: #42a5f5;
}

.white-color .purple-icon span,
.purple-icon span {
	background: -webkit-gradient(linear, left top, left bottom, from(#1d07f4), to(#f402b8));
}

/*------------------------------------------*/
/*   BOX ICON HOVER
/*------------------------------------------*/

.rose-hover:hover .grey-icon span {
	color: #ff3366;
	transform: scale(1.1);
}

.skyblue-hover:hover .grey-icon span {
	color: #42a5f5;
	transform: scale(1.1);
}

.lightgreen-hover:hover .grey-icon span {
	color: #22bc3f;
	transform: scale(1.1);
}

.red-hover:hover .grey-icon span {
	color: #e35029;
	transform: scale(1.1);
}

/*------------------------------------------*/
/*   TECHNOLOGIES ICONS
/*------------------------------------------*/

.technologies {
	margin-top: 35px;
}

.technologies p {
	color: #999;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.technologies .svg-inline--fa {
	color: #888;
	font-size: 2.65rem;
	line-height: 1;
	margin-right: 10px;
}

/*------------------------------------------*/
/*    STORE BAGE ICONS
/*------------------------------------------*/

a.store {
	margin-right: 15px;
}

.btn:last-child,
a.store:last-child {
	margin-right: 0;
}

/*------------------------------------------*/
/*   BOX LIST
/*------------------------------------------*/

.box-list-icon {
	font-size: 0.9rem;
	line-height: 1.5rem;
	float: left;
	margin-right: 8px;
}

.box-list p {
	overflow: hidden;
	margin-bottom: 8px;
}

/*------------------------------------------*/
/*   COUNTDOWN ELEMENT
/*------------------------------------------*/

#clock {
	display: inline-block;
}

/*------------------------------------------*/
/*    Countdown Box
/*------------------------------------------*/

#clock .cbox {
	float: left;
	text-align: center;
	padding: 0 30px;
	display: inline-block;
}

#clock .cbox:last-child {
	border-right: none;
}

/*------------------------------------------*/
/*    Countdown Digit
/*------------------------------------------*/

#clock span.cbox-digit {
	font-size: 4.5rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -2px;
}

#clock span.cbox-txt {
	background-color: transparent;
	border: none;
	font-size: 0.975rem;
	line-height: 1;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 0;
	display: block;
	padding-top: 10px;
}

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

span.section-id {
	color: #aaa;
	font-size: 0.9rem;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	display: block;
	position: relative;
	margin-bottom: 30px;
}

/*------------------------------------------*/
/*   SECTION TITLE
/*------------------------------------------*/

.section-title {
	position: relative;
	text-align: center;
	margin-bottom: 60px;
}

.section-title h3 {
	letter-spacing: -0.5px;
	margin-bottom: 15px;
}

.section-title p {
	font-size: 1.3125rem; /* 21px */
	padding: 0 10%;
	margin-bottom: 0;
}

.bg-deepdark .section-title p {
	color: #ccc;
}

/*------------------------------------------*/
/*   TEXT WEIGHT
/*------------------------------------------*/

.txt-300 {
	font-weight: 300;
}
.txt-400 {
	font-weight: 400;
}
.txt-500 {
	font-weight: 500;
}
.txt-600 {
	font-weight: 600;
}
.txt-700 {
	font-weight: 700;
}
.txt-800 {
	font-weight: 800;
}
.txt-900 {
	font-weight: 900;
}

.txt-upper {
	text-transform: uppercase;
}

/*------------------------------------------*/
/*   TEXT SETTINGS FOR DEMOS
/*------------------------------------------*/

.black-color,
.black-color h2,
.black-color h3,
.black-color h4,
.black-color h5,
.black-color h6,
.black-color p,
.black-color a,
.black-color li,
.black-color i,
.black-color span {
	color: #000;
}

.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span {
	color: #fff;
}

.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color span {
	color: #666;
}

.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.white-color .lightgrey-color,
.lightgrey-color span {
	color: #ccc;
}

.yellow-color,
.yellow-color h2,
.yellow-color h3,
.yellow-color h4,
.yellow-color h5,
.yellow-color h6,
.yellow-color p,
.yellow-color a,
.yellow-color li,
.yellow-color i,
.white-color .yellow-color,
.yellow-color span {
	color: #fcb80b;
}

.green-color,
.green-color h2,
.green-color h3,
.green-color h4,
.green-color h5,
.green-color h6,
.green-color p,
.green-color a,
.green-color li,
.green-color i,
.white-color .green-color,
.green-color span {
	color: #56a959;
}

.lightgreen-color,
.lightgreen-color h2,
.lightgreen-color h3,
.lightgreen-color h4,
.lightgreen-color h5,
.lightgreen-color h6,
.lightgreen-color p,
.lightgreen-color a,
.lightgreen-color li,
.lightgreen-color i,
.white-color .lightgreen-color,
.lightgreen-color span {
	color: #22bc3f;
}

.rose-color,
.rose-color h2,
.rose-color h3,
.rose-color h4,
.rose-color h5,
.rose-color h6,
.rose-color p,
.rose-color a,
.rose-color li,
.rose-color i,
.white-color .rose-color,
.rose-color span {
	color: #ff3366;
}

.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.white-color .blue-color,
.blue-color span {
	color: #3176ed;
}

.skyblue-color,
.skyblue-color h2,
.skyblue-color h3,
.skyblue-color h4,
.skyblue-color h5,
.skyblue-color h6,
.skyblue-color p,
.skyblue-color a,
.skyblue-color li,
.skyblue-color i,
.white-color .skyblue-color,
.skyblue-color span {
	color: #01b7de;
}

.red-color,
.red-color h2,
.red-color h3,
.red-color h4,
.red-color h5,
.red-color h6,
.red-color p,
.red-color a,
.red-color li,
.red-color i,
.white-color .red-color,
.red-color span {
	color: #e35029;
}

.purple-color,
.purple-color h2,
.purple-color h3,
.purple-color h4,
.purple-color h5,
.purple-color h6,
.purple-color p,
.purple-color a,
.purple-color li,
.purple-color i,
.white-color .purple-color,
.purple-color span {
	color: #6e45e2;
}

.deepblue-color,
.deepblue-color h2,
.deepblue-color h3,
.deepblue-color h4,
.deepblue-color h5,
.deepblue-color h6,
.deepblue-color p,
.deepblue-color a,
.deepblue-color li,
.deepblue-color i,
.white-color .deepblue-color,
.deepblue-color span {
	color: #004861;
}
