/* ==========================================================================
  08.  CONTENT
  =========================================================================== */

#content-3,
#content-4 {
	position: relative;
}

#content-5,
#content-6 {
	position: relative;
}

.inner-block {
	border-radius: 6px;
}

#content-7-title {
	position: relative;
	z-index: 3;
	padding-top: 100px;
	padding-bottom: 280px;
}

#content-7-boxes {
	position: relative;
	padding-bottom: 60px;
	z-index: 999;
	margin-top: -280px;
}

/*------------------------------------------*/
/*   CONTENT TEXT
/*------------------------------------------*/

.content-txt {
	margin-bottom: 40px;
}

#content-3 .content-txt {
	padding-left: 60px;
}

#content-5 .content-txt,
#content-6 .content-txt,
#content-9 .content-txt,
#content-10 .content-txt {
	margin-bottom: 0;
}

#content-4 .content-txt {
	padding-right: 60px;
}

#content-9 .content-txt {
	padding: 0 40px 0 80px;
}

#content-10 .content-txt {
	padding: 0 80px 0 40px;
}

/*------------------------------------------*/
/*   CONTENT TEXT TYPOGRAPHY
/*------------------------------------------*/

.content-txt h2.h2-huge {
	font-size: 5rem;
	line-height: 1;
	letter-spacing: -1px;
	margin-bottom: 30px;
}

.content-txt h2.h2-lg,
.content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
	margin-bottom: 30px;
}

.content-txt h5.h5-sm {
	margin-top: 20px;
	margin-bottom: 18px;
}

.content-txt .small-statistic h5.h5-sm {
	margin-top: 0;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   Content List
/*------------------------------------------*/

ul.content-list {
	list-style: disc;
	margin-top: 20px;
	margin-left: 15px;
}

ul.content-list li {
	margin-bottom: 8px;
}

ul.content-list li:last-child {
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   Content Button
/*------------------------------------------*/

.content-btn {
	margin-top: 35px;
}

/*------------------------------------------*/
/*   CONTENT BOX
/*------------------------------------------*/

.cbox-1 {
	margin-bottom: 40px;
}

.cbox-txt {
	background-color: #fff;
	padding: 30px 30px 25px;
	border: 1px solid #ccc;
	border-radius: 0 0 6px 6px;
}

.cbox-txt h5 {
	margin-bottom: 15px;
}

/*------------------------------------------*/
/*   CONTENT IMAGE
/*------------------------------------------*/

.content-img {
	text-align: center;
	margin-bottom: 40px;
}

.content-3-img {
	background-image: url('/vendor/pergo/images/content-3.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 50%;
	left: 0;
}

.content-4-img {
	background-image: url('/vendor/pergo/images/content-4.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 50%;
	left: 50%;
}

.content-right-img {
	position: absolute;
	top: 0;
	right: -30%;
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.content-left-img {
	position: absolute;
	top: 0;
	left: -30%;
	width: 70%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

#content-9 .content-img,
#content-10 .content-img {
	margin-bottom: 0;
}

.inner-block img {
	border-radius: 0 6px 6px 0;
}

#content-10 .inner-block img {
	border-radius: 6px 0 0 6px;
}
