/* ==========================================================================
  10.  VIDEO
  =========================================================================== */

#video-1 {
	background-image: url('/vendor/pergo/images/video-1.jpg');
	padding-top: 140px;
	padding-bottom: 140px;
}

#video-2 {
	background-image: url('/vendor/pergo/images/video-2.jpg');
	padding-top: 180px;
	padding-bottom: 180px;
}

/*------------------------------------------*/
/*   VIDEO TYPOGRAPHY
/*------------------------------------------*/

#video-3 p {
	margin-top: 15px;
	margin-bottom: 50px;
	padding: 0 10%;
}
