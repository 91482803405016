/* ==========================================================================
  11.  PORTFOLIO
  =========================================================================== */

#single-project {
	margin-top: 65px;
}

#portfolio-1 .section-title {
	margin-bottom: 40px;
}

/*------------------------------------------*/
/*    FILTER BUTTONS
/*------------------------------------------*/

.btn-toolbar {
	display: inline-block;
	margin: 0 auto 60px;
}

.portfolio-filter button {
	background-color: #eee;
	color: #666;
	font-size: 0.8rem;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0 3px;
	padding: 0.65rem 1.65rem;
	border: none;
	border-radius: 100px;
	transition: all 400ms ease-in-out;
	outline: 0px none;
	cursor: pointer;
}

.bg-dark .portfolio-filter button {
	color: #fff;
	background-color: rgba(30, 30, 30, 0.3);
}

.bg-lightgrey .portfolio-filter button {
	background-color: #e0e0e0;
}

/*------------------------------------------*/
/*    Filter Button Active
/*------------------------------------------*/

.portfolio-filter button.is-checked,
.bg-dark .portfolio-filter button.is-checked {
	color: #fff;
	background-color: #f23472;
	cursor: default;
	outline: 0px none;
}

.portfolio-filter.yellow-btngroup button.is-checked,
.bg-dark .portfolio-filter.yellow-btngroup button.is-checked {
	color: #333;
	background-color: #fcb80b;
	cursor: default;
	outline: 0px none;
}

/*------------------------------------------*/
/*     Filter Button Hover
/*------------------------------------------*/

.portfolio-filter button:hover {
	background-color: #ccc;
	color: #333;
}

.bg-dark .portfolio-filter button:hover {
	background-color: rgba(30, 30, 30, 0.5);
	color: #fff;
}

/*------------------------------------------*/
/*    PORTFOLIO IMAGES HOLDER
/*------------------------------------------*/

.masonry-wrap .portfolio-item {
	width: 33.33%;
	padding: 0;
	margin-bottom: 0;
}

#portfolio-3 .masonry-wrap .portfolio-item {
	width: 25%;
	padding: 0;
	margin-bottom: 0;
}

#portfolio-1 .masonry-wrap .portfolio-item,
#portfolio-2 .masonry-wrap .portfolio-item {
	padding: 0 10px;
	margin-bottom: 20px;
}

/*------------------------------------------*/
/*    Portfolio Image Meta
/*------------------------------------------*/

.project-description {
	position: absolute;
	bottom: 10%;
	left: 0;
	z-index: 20;
	width: 100%;
	opacity: 0;
	transition: all 400ms ease-in-out;
}

.project-description h5.h5-sm {
	margin-top: 3px;
}

.project-description span {
	font-size: 1rem;
	line-height: 1.1;
	font-weight: 500;
}

/*------------------------------------------*/
/*    Image Hover Overlay
/*------------------------------------------*/

.hover-overlay {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.hover-overlay img {
	transform: scale(1.1);
	overflow: hidden;
	transition: transform 400ms;
}

/*------------------------------------------*/
/*    Overlay Background
/*------------------------------------------*/

.item-overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(10, 10, 10, 0.7);
	transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*    Image Hover Effect
/*------------------------------------------*/

.hover-overlay:hover img {
	transform: scale(1);
}

.hover-overlay:hover .item-overlay {
	opacity: 1;
}

.portfolio-item:hover .project-description {
	left: 8%;
	opacity: 1;
}

/*------------------------------------------*/
/*    SINGLE PROJECT DATA
/*------------------------------------------*/

.project-txt h3.h3-xs {
	margin-top: 25px;
	margin-bottom: 20px;
}

.project-data {
	margin-top: 20px;
}

.project-data h5 {
	margin-bottom: 4px;
}

.project-data p {
	margin-bottom: 0;
}

.project-data p span {
	font-weight: 700;
}

/*------------------------------------------*/
/*    Share Project Links
/*------------------------------------------*/

.share-project {
	margin-top: 5px;
	display: inline-block;
	padding-left: 0;
}

.share-project li {
	width: auto !important;
	display: inline-block !important;
	vertical-align: top;
	clear: none !important;
	padding: 0;
}

.share-project a {
	color: #666;
}

.share-project .svg-inline--fa {
	font-size: 1.05rem;
	margin-right: 8px;
	transition: transform 450ms;
}

.share-project a:hover {
	color: #000;
}
