/* ==========================================================================
  25.  CONTACTS
  =========================================================================== */

.bg-clouds {
	width: 100%;
	background-image: url('/vendor/pergo/images/clouds.jpg');
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 80px;
}

#contacts-1.bg-clouds {
	padding-bottom: 240px;
}
#contacts-2.bg-clouds {
	padding-bottom: 170px;
}
#contacts-3.bg-clouds {
	padding-bottom: 120px;
}

.contacts-section .form-holder {
	position: relative;
}

#contacts-map {
	margin-top: 70px;
}

/*------------------------------------------*/
/*    CONTACT BOX
/*------------------------------------------*/

#contacts-1 .contact-box {
	text-align: center;
	margin-bottom: 30px;
}

#contacts-2 .contact-box {
	margin-bottom: 10px;
}

#contacts-3 .contact-box,
#contacts-5 .contact-box {
	margin-bottom: 40px;
}

/*------------------------------------------*/
/*    CONTACTS TYPOGRAPHY
/*------------------------------------------*/

.contact-box .svg-inline--fa {
	font-size: 3.5rem;
}

.contacts-section h4.h4-xs {
	margin-bottom: 15px;
}

.contacts-section h3.h3-lg {
	margin-bottom: 25px;
}

.contact-box h5 {
	margin-top: 20px;
	margin-bottom: 12px;
}

#contacts-2 .contact-box h5,
#contacts-3 .contact-box h5 {
	margin-top: 0;
	margin-bottom: 10px;
}

p.support-number-txt {
	margin-top: 45px;
	margin-bottom: 5px;
}

span.support-number {
	font-size: 2rem;
	font-family: 'Montserrat', sans-serif;
	line-height: 1;
	font-weight: 700;
	letter-spacing: 1px;
	display: block;
}

.contact-box p {
	font-size: 1.05rem;
	font-weight: 400;
	margin-bottom: 0;
}

.bg-dark.contacts-section p,
.bg-deepdark.contacts-section p,
.bg-dark .contact-box a:hover,
.bg-deepdark .contact-box a:hover {
	color: #ccc;
}

.contact-box a {
	text-decoration: underline;
}

.bg-dark .contact-box a,
.bg-deepdark .contact-box a {
	color: #fff;
}

.contact-box a:hover {
	color: #000;
}

/*------------------------------------------*/
/*    CONTACT FORM
/*------------------------------------------*/

.contact-form .form-control {
	height: 52px;
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
	font-size: 1rem;
	font-weight: 300;
	padding: 0 20px;
	margin-bottom: 22px;
	border-radius: 6px;
	transition: all 400ms ease-in-out;
}

.bg-dark .contact-form .form-control {
	background-color: rgba(220, 220, 220, 0.2);
	color: #fff;
	border-color: rgba(220, 220, 220, 0.2);
}

.bg-deepdark .contact-form .form-control {
	background-color: rgba(50, 50, 50, 0.7);
	color: #fff;
	border-color: rgba(50, 50, 50, 0.7);
}

.bg-clouds .contact-form .form-control,
.bg-lightgrey .contact-form .form-control {
	background-color: #fcfcfc;
	border-color: #ccc;
}

/*------------------------------------------*/
/*    Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea {
	min-height: 200px;
}
.contact-form textarea.form-control {
	padding: 20px;
}

/*------------------------------------------*/
/*    Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder {
	color: #555;
}
.contact-form .form-control:-ms-input-placeholder {
	color: #555;
}
.contact-form .form-control::-webkit-input-placeholder {
	color: #555;
}

.bg-dark .contact-form .form-control::-moz-placeholder {
	color: #ccc;
}
.bg-dark .contact-form .form-control:-ms-input-placeholder {
	color: #ccc;
}
.bg-dark .contact-form .form-control::-webkit-input-placeholder {
	color: #ccc;
}

.bg-deepdark .contact-form .form-control::-moz-placeholder {
	color: #999;
}
.bg-deepdark .contact-form .form-control:-ms-input-placeholder {
	color: #999;
}
.bg-deepdark .contact-form .form-control::-webkit-input-placeholder {
	color: #999;
}

/*------------------------------------------*/
/*    Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus,
.bg-lightgrey .contact-form .form-control:focus {
	outline: 0px none;
	box-shadow: none;
	border-color: #ff3366;
}

.bg-dark .contact-form .form-control:focus {
	background-color: rgba(255, 255, 255, 0.05);
	border-color: #fff;
}

.bg-deepdark .contact-form .form-control:focus {
	background-color: #1d1d1d;
	border-color: #ccc;
}

/*------------------------------------------*/
/*    Contact Form Message
/*------------------------------------------*/

.contact-form label.error {
	color: #f6412d;
	font-size: 0.95rem;
	line-height: 1;
	margin-bottom: 27px;
}

#contacts-2 .contact-form label.error {
	margin-left: 5px;
	margin-bottom: 30px;
}

.contact-form-msg {
	margin-top: 30px;
	margin-left: 12px;
}

.hero-form .contact-form-msg {
	display: inline-block;
	margin: 20px auto 0;
}

.contact-form-msg .error,
.contact-form-msg .loading {
	color: #45afff;
	font-size: 1.1rem;
	line-height: 1;
	font-weight: 600;
}

.contact-form-msg .error {
	color: #f6412d;
}

/*------------------------------------------*/
/*  CONTACTS GOOGLE MAP
/*------------------------------------------*/

#gmap {
	width: 100%;
	height: 400px;
}

#contacts-2 #gmap {
	height: 375px;
}

#contacts-5 #gmap,
#contacts-map #gmap {
	width: 100%;
	height: 480px;
}
