/* ==========================================================================
  23.  NEWSLETTER
  =========================================================================== */

.newsletter-section.bg-image {
	background-image: url('/vendor/pergo/images/newsletter.jpg');
}

.newsletter-section.bg-purple,
.newsletter-section.bg-deeppurple {
	background-image: url('/vendor/pergo/images/tra-bg.png');
}

/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/

#newsletter-1 .form-control {
	height: 60px;
	font-weight: 300;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #ccc;
	color: #fff;
	font-size: 1.35rem;
	padding: 0 0.5rem;
	margin-right: 0;
	border-radius: 0;
	box-shadow: 0 0;
	transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*    Newsletter Form Input Focus
/*------------------------------------------*/

#newsletter-1 .form-control:focus {
	border-color: #fff;
	outline: 0;
	box-shadow: none;
}

/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/

.newsletter-section .newsletter-form .form-control::-moz-placeholder {
	color: #ddd;
}
.newsletter-section .newsletter-form .form-control:-ms-input-placeholder {
	color: #ddd;
}
.newsletter-section .newsletter-form .form-control::-webkit-input-placeholder {
	color: #ddd;
}

/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/

#newsletter-1 .newsletter-form .btn {
	height: 60px;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #ccc;
	font-size: 1.25rem;
	padding: 0 1rem;
	border-radius: 0;
	box-shadow: 0 0;
}

/*------------------------------------------*/
/*    Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	margin: 20px auto 0;
}
