/* ==========================================================================
  15.  STATISTIC
  =========================================================================== */

#statistic-1 {
	padding-top: 80px;
	padding-bottom: 50px;
}

.statistic-section.bg-image {
	background-image: url('/vendor/pergo/images/statistic.jpg');
}

/*------------------------------------------*/
/*    STATISTIC TYPOGRAPHY
/*------------------------------------------*/

.statistic-title {
	text-align: center;
	margin-bottom: 50px;
}

.statistic-title h3 {
	margin-bottom: 15px;
}

.statistic-title p {
	padding: 0 10%;
}

/*------------------------------------------*/
/*    STATISTIC BLOCK
/*------------------------------------------*/

.statistic-block {
	text-align: center;
	margin-bottom: 30px;
}

.small-statistic .statistic-block {
	text-align: left;
}

/*------------------------------------------*/
/*    Statistic Block Number
/*------------------------------------------*/

.statistic-number {
	font-size: 4.15rem;
	line-height: 1;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 2px;
	margin-bottom: 14px;
}

.small-statistic .statistic-number {
	font-size: 4.5rem;
	letter-spacing: -1px;
}

.statistic-block h5 {
	margin-bottom: 5px;
}

.small-statistic .statistic-block h5 {
	margin-left: 6px;
}

.statistic-block p {
	font-style: italic;
	margin-bottom: 0;
}
