/* ==========================================================================
  28 INNER PAGE HERO
  =========================================================================== */

.page-hero-section {
	padding-top: 200px;
	padding-bottom: 140px;
}

#about-hero {
	background-image: url('/vendor/pergo/images/about-hero.jpg');
}

#services-hero {
	background-image: url('/vendor/pergo/images/services-hero.jpg');
}

#team-hero {
	background-image: url('/vendor/pergo/images/team-hero.jpg');
}

#pricing-hero {
	background-image: url('/vendor/pergo/images/pricing-hero.jpg');
}

#blog-listing-hero {
	background-image: url('/vendor/pergo/images/blog-listing-hero.jpg');
}

/*------------------------------------------*/
/*   INNER PAGE HERO TYPOGRAPHY
/*------------------------------------------*/

.page-hero-section h2 {
	font-size: 4.5rem;
	letter-spacing: -0.5px;
}

.page-hero-section p {
	font-weight: 400;
	padding: 0 12%;
	margin-top: 15px;
	margin-bottom: 0;
}
