@charset "UTF-8";
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#6c757d;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
*, ::after, ::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b, strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button, input {
  overflow: visible;
}
button, select {
  text-transform: none;
}
[role=button] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1, h1 {
  font-size: 2.5rem;
}
.h2, h2 {
  font-size: 2rem;
}
.h3, h3 {
  font-size: 1.75rem;
}
.h4, h4 {
  font-size: 1.5rem;
}
.h5, h5 {
  font-size: 1.25rem;
}
.h6, h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small, small {
  font-size: 80%;
  font-weight: 400;
}
.mark, mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm td, .table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple], select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}
.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-valid, .was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}
.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid, .was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select, .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus, .btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus, .btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus, .btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn, .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after, .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after, .input-group.has-validation > .custom-select:nth-last-child(n+3), .input-group.has-validation > .form-control:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0;
}
.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label, .custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before, .custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label, .custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: none;
  }
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item, .nav-fill > .nav-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item, .nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img, .card-img-bottom, .card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
.card-img, .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus, a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus, a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus, a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus, a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus, a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus, a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus, a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^=top] > .arrow, .bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^=top] > .arrow::before, .bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^=top] > .arrow::after, .bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^=right] > .arrow, .bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^=right] > .arrow::before, .bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^=right] > .arrow::after, .bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^=bottom] > .arrow::before, .bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^=bottom] > .arrow::after, .bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^=left] > .arrow, .bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^=left] > .arrow::before, .bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^=left] > .arrow::after, .bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}
.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0, .my-0 {
  margin-top: 0 !important;
}
.mr-0, .mx-0 {
  margin-right: 0 !important;
}
.mb-0, .my-0 {
  margin-bottom: 0 !important;
}
.ml-0, .mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}
.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}
.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3, .my-3 {
  margin-top: 1rem !important;
}
.mr-3, .mx-3 {
  margin-right: 1rem !important;
}
.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}
.ml-3, .mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}
.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5, .my-5 {
  margin-top: 3rem !important;
}
.mr-5, .mx-5 {
  margin-right: 3rem !important;
}
.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}
.ml-5, .mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0, .py-0 {
  padding-top: 0 !important;
}
.pr-0, .px-0 {
  padding-right: 0 !important;
}
.pb-0, .py-0 {
  padding-bottom: 0 !important;
}
.pl-0, .px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}
.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}
.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}
.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}
.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3, .py-3 {
  padding-top: 1rem !important;
}
.pr-3, .px-3 {
  padding-right: 1rem !important;
}
.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}
.pl-3, .px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}
.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}
.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5, .py-5 {
  padding-top: 3rem !important;
}
.pr-5, .px-5 {
  padding-right: 3rem !important;
}
.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}
.pl-5, .px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1, .my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2, .my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}
.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}
.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto, .my-auto {
  margin-top: auto !important;
}
.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.mb-auto, .my-auto {
  margin-bottom: auto !important;
}
.ml-auto, .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus, a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */
/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */
html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
}
/*------------------------------------------------------------------*/
/*   IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/
#page {
  overflow: hidden;
}
/*------------------------------------------*/
/*  ONSCROLL ANIMATIONS
/*------------------------------------------*/
.animated {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/
.ind-60 {
  padding-right: 60px;
  padding-left: 60px;
}
.ind-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.ind-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.ind-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.ind-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.ind-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.ind-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.ind-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.ind-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.ind-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.wide-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.wide-90 {
  padding-top: 100px;
  padding-bottom: 90px;
}
.wide-80 {
  padding-top: 100px;
  padding-bottom: 80px;
}
.wide-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}
.wide-60 {
  padding-top: 100px;
  padding-bottom: 60px;
}
.wide-50 {
  padding-top: 100px;
  padding-bottom: 50px;
}
.wide-40 {
  padding-top: 100px;
  padding-bottom: 40px;
}
.wide-30 {
  padding-top: 100px;
  padding-bottom: 30px;
}
.wide-20 {
  padding-top: 100px;
  padding-bottom: 20px;
}
/*------------------------------------------*/
/*    Margin Top
/*------------------------------------------*/
.m-top-160 {
  margin-top: 160px;
}
.m-top-150 {
  margin-top: 150px;
}
.m-top-140 {
  margin-top: 140px;
}
.m-top-130 {
  margin-top: 130px;
}
.m-top-120 {
  margin-top: 120px;
}
.m-top-110 {
  margin-top: 110px;
}
.m-top-100 {
  margin-top: 100px;
}
.m-top-90 {
  margin-top: 90px;
}
.m-top-80 {
  margin-top: 80px;
}
.m-top-70 {
  margin-top: 70px;
}
.m-top-60 {
  margin-top: 60px;
}
.m-top-50 {
  margin-top: 50px;
}
.m-top-45 {
  margin-top: 45px;
}
.m-top-40 {
  margin-top: 40px;
}
.m-top-35 {
  margin-top: 35px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-top-25 {
  margin-top: 25px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-top-15 {
  margin-top: 15px;
}
.m-top-10 {
  margin-top: 10px;
}
.m-top-5 {
  margin-top: 5px;
}
/*------------------------------------------*/
/*    Margin Bottom
/*------------------------------------------*/
.m-bottom-160 {
  margin-bottom: 160px;
}
.m-bottom-150 {
  margin-bottom: 150px;
}
.m-bottom-140 {
  margin-bottom: 140px;
}
.m-bottom-130 {
  margin-bottom: 130px;
}
.m-bottom-120 {
  margin-bottom: 120px;
}
.m-bottom-110 {
  margin-bottom: 110px;
}
.m-bottom-100 {
  margin-bottom: 100px;
}
.m-bottom-90 {
  margin-bottom: 90px;
}
.m-bottom-80 {
  margin-bottom: 80px;
}
.m-bottom-70 {
  margin-bottom: 70px;
}
.m-bottom-60 {
  margin-bottom: 60px;
}
.m-bottom-50 {
  margin-bottom: 50px;
}
.m-bottom-45 {
  margin-bottom: 45px;
}
.m-bottom-40 {
  margin-bottom: 40px;
}
.m-bottom-35 {
  margin-bottom: 35px;
}
.m-bottom-30 {
  margin-bottom: 30px;
}
.m-bottom-25 {
  margin-bottom: 25px;
}
.m-bottom-20 {
  margin-bottom: 20px;
}
.m-bottom-15 {
  margin-bottom: 15px;
}
.m-bottom-10 {
  margin-bottom: 10px;
}
.m-bottom-5 {
  margin-bottom: 5px;
}
.m-bottom-0 {
  margin-bottom: 0;
}
/*------------------------------------------*/
/*    Margin Left
/*------------------------------------------*/
.m-left-70 {
  margin-left: 70px;
}
.m-left-60 {
  margin-left: 60px;
}
.m-left-50 {
  margin-left: 50px;
}
.m-left-45 {
  margin-left: 45px;
}
.m-left-40 {
  margin-left: 40px;
}
.m-left-35 {
  margin-left: 35px;
}
.m-left-30 {
  margin-left: 30px;
}
.m-left-25 {
  margin-left: 25px;
}
.m-left-20 {
  margin-left: 20px;
}
.m-left-15 {
  margin-left: 15px;
}
.m-left-10 {
  margin-left: 10px;
}
.m-left-5 {
  margin-left: 5px;
}
/*------------------------------------------*/
/*    Margin Right
/*------------------------------------------*/
.m-right-70 {
  margin-right: 70px;
}
.m-right-60 {
  margin-right: 60px;
}
.m-right-50 {
  margin-right: 50px;
}
.m-right-45 {
  margin-right: 45px;
}
.m-right-40 {
  margin-right: 40px;
}
.m-right-35 {
  margin-right: 35px;
}
.m-right-30 {
  margin-right: 30px;
}
.m-right-25 {
  margin-right: 25px;
}
.m-right-20 {
  margin-right: 20px;
}
.m-right-15 {
  margin-right: 15px;
}
.m-right-10 {
  margin-right: 10px;
}
.m-right-5 {
  margin-right: 5px;
}
/*------------------------------------------*/
/*    Padding Top
/*------------------------------------------*/
.p-top-160 {
  padding-top: 160px;
}
.p-top-150 {
  padding-top: 150px;
}
.p-top-140 {
  padding-top: 140px;
}
.p-top-130 {
  padding-top: 130px;
}
.p-top-120 {
  padding-top: 120px;
}
.p-top-110 {
  padding-top: 110px;
}
.p-top-100 {
  padding-top: 100px;
}
.p-top-90 {
  padding-top: 90px;
}
.p-top-80 {
  padding-top: 80px;
}
.p-top-70 {
  padding-top: 70px;
}
.p-top-60 {
  padding-top: 60px;
}
.p-top-50 {
  padding-top: 50px;
}
.p-top-45 {
  padding-top: 45px;
}
.p-top-40 {
  padding-top: 40px;
}
.p-top-35 {
  padding-top: 35px;
}
.p-top-30 {
  padding-top: 30px;
}
.p-top-25 {
  padding-top: 25px;
}
.p-top-20 {
  padding-top: 20px;
}
.p-top-15 {
  padding-top: 15px;
}
.p-top-10 {
  padding-top: 10px;
}
/*------------------------------------------*/
/*    Padding Bottom
/*------------------------------------------*/
.p-bottom-160 {
  padding-bottom: 160px;
}
.p-bottom-150 {
  padding-bottom: 150px;
}
.p-bottom-140 {
  padding-bottom: 140px;
}
.p-bottom-130 {
  padding-bottom: 130px;
}
.p-bottom-120 {
  padding-bottom: 120px;
}
.p-bottom-110 {
  padding-bottom: 110px;
}
.p-bottom-100 {
  padding-bottom: 100px;
}
.p-bottom-90 {
  padding-bottom: 90px;
}
.p-bottom-80 {
  padding-bottom: 80px;
}
.p-bottom-70 {
  padding-bottom: 70px;
}
.p-bottom-60 {
  padding-bottom: 60px;
}
.p-bottom-50 {
  padding-bottom: 50px;
}
.p-bottom-45 {
  padding-bottom: 45px;
}
.p-bottom-40 {
  padding-bottom: 40px;
}
.p-bottom-35 {
  padding-bottom: 35px;
}
.p-bottom-30 {
  padding-bottom: 30px;
}
.p-bottom-25 {
  padding-bottom: 25px;
}
.p-bottom-20 {
  padding-bottom: 20px;
}
.p-bottom-15 {
  padding-bottom: 15px;
}
.p-bottom-10 {
  padding-bottom: 10px;
}
.p-bottom-0 {
  padding-bottom: 0;
}
/*------------------------------------------*/
/*    Padding Left
/*------------------------------------------*/
.p-left-100 {
  padding-left: 100px;
}
.p-left-90 {
  padding-left: 90px;
}
.p-left-80 {
  padding-left: 80px;
}
.p-left-70 {
  padding-left: 70px;
}
.p-left-60 {
  padding-left: 60px;
}
.p-left-50 {
  padding-left: 50px;
}
.p-left-45 {
  padding-left: 45px;
}
.p-left-40 {
  padding-left: 40px;
}
.p-left-35 {
  padding-left: 35px;
}
.p-left-30 {
  padding-left: 30px;
}
.p-left-25 {
  padding-left: 25px;
}
.p-left-20 {
  padding-left: 20px;
}
.p-left-15 {
  padding-left: 15px;
}
.p-left-10 {
  padding-left: 10px;
}
.p-left-5 {
  padding-left: 5px;
}
.p-left-0 {
  padding-left: 0px;
}
/*------------------------------------------*/
/*    Padding Right
/*------------------------------------------*/
.p-right-100 {
  padding-right: 100px;
}
.p-right-90 {
  padding-right: 90px;
}
.p-right-80 {
  padding-right: 80px;
}
.p-right-70 {
  padding-right: 70px;
}
.p-right-60 {
  padding-right: 60px;
}
.p-right-50 {
  padding-right: 50px;
}
.p-right-45 {
  padding-right: 45px;
}
.p-right-40 {
  padding-right: 40px;
}
.p-right-35 {
  padding-right: 35px;
}
.p-right-30 {
  padding-right: 30px;
}
.p-right-25 {
  padding-right: 25px;
}
.p-right-20 {
  padding-right: 20px;
}
.p-right-15 {
  padding-right: 15px;
}
.p-right-10 {
  padding-right: 10px;
}
.p-right-5 {
  padding-right: 5px;
}
.p-right-0 {
  padding-right: 0px;
}
/*------------------------------------------*/
/*   BACKGROUND SETTINGS
/*------------------------------------------*/
.bg-scroll {
  width: 100%;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-fixed {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
/*------------------------------------------*/
/*   Background Colors for Sections
/*------------------------------------------*/
.bg-dark {
  background-color: #2c353f;
}
.bg-lightdark {
  background-color: #252d35;
}
.bg-deepdark {
  background-color: #1d1d1d;
}
.bg-lightgrey {
  background-color: #f0f0f0;
}
.bg-grey {
  background-color: #e8e8e8;
}
.bg-deepgrey {
  background-color: #ddd;
}
.bg-rose {
  background-color: #ff3366;
}
.bg-red {
  background-color: #e35029;
}
.bg-yellow {
  background-color: #feca16;
}
.bg-green {
  background-color: #42a045;
}
.bg-lightgreen {
  background-color: #59bd56;
}
.bg-deepgreen {
  background-color: #009587;
}
.bg-blue {
  background-color: #2154cf;
}
.bg-lightblue {
  background-color: #1e88e5;
}
.bg-deepblue {
  background-color: #004861;
}
.bg-tinyblue {
  background-color: #e6f9fa;
}
.bg-purple {
  background-color: #6e45e2;
}
.bg-deeppurple {
  background-color: #510fa7;
}
.bg-lightpurple {
  background-color: #715fef;
}
/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/
.b-top {
  border-top: 1px solid #ddd;
}
.b-bottom {
  border-bottom: 1px solid #ddd;
}
.b-left {
  border-left: 1px solid #ddd;
}
.b-right {
  border-right: 1px solid #ddd;
}
/* ==========================================================================
  02.  TYPOGRAPHY
  =========================================================================== */
/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.025rem;
  /* -0.4px */
}
/* Header H5 */
h5.h5-xs {
  font-size: 1.125rem;
}
/* 18px */
h5.h5-sm {
  font-size: 1.1875rem;
}
/* 19px */
h5.h5-md {
  font-size: 1.25rem;
}
/* 20px */
h5.h5-lg {
  font-size: 1.375rem;
}
/* 22px */
h5.h5-xl {
  font-size: 1.5rem;
}
/* 24px */
/* Header H4 */
h4.h4-xs {
  font-size: 1.625rem;
}
/* 26px */
h4.h4-sm {
  font-size: 1.75rem;
}
/* 28px */
h4.h4-md {
  font-size: 1.875rem;
}
/* 30px */
h4.h4-lg {
  font-size: 2rem;
}
/* 32px */
h4.h4-xl {
  font-size: 2.125rem;
}
/* 34px */
/* Header H3 */
h3.h3-xs {
  font-size: 2.375rem;
}
/* 38px */
h3.h3-sm {
  font-size: 2.5rem;
}
/* 40px */
h3.h3-md {
  font-size: 2.625rem;
}
/* 42px */
h3.h3-lg {
  font-size: 2.75rem;
}
/* 44px */
h3.h3-xl {
  font-size: 3rem;
}
/* 48px */
/* Header H2 */
h2.h2-xs {
  font-size: 3.25rem;
}
/* 52px */
h2.h2-sm {
  font-size: 3.375rem;
}
/* 54px */
h2.h2-md {
  font-size: 3.5rem;
}
/* 56px */
h2.h2-lg {
  font-size: 3.75rem;
}
/* 60px */
h2.h2-xl {
  font-size: 4rem;
}
/* 64px */
h2.h2-huge {
  font-size: 4.25rem;
}
/* 64px */
/*------------------------------------------*/
/*   PARAGRAPHS
/*------------------------------------------*/
p.p-sm {
  font-size: 0.95rem;
}
/* 15.2px */
p {
  font-size: 1rem;
}
/* 16px */
p.p-md {
  font-size: 1.125rem;
}
/* 18px */
p.p-lg {
  font-size: 1.25rem;
}
/* 20px */
p.p-xl {
  font-size: 1.3175rem;
}
/* 22px */
/*------------------------------------------*/
/*   LISTS
/*------------------------------------------*/
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/*------------------------------------------*/
/*   LINK SETTINGS
/*------------------------------------------*/
a {
  color: #333;
  text-decoration: none;
  transition: all 450ms ease-in-out;
}
a:hover {
  color: #666;
  text-decoration: none;
}
a.black-hover:hover {
  color: #000;
}
a.green-hover:hover {
  color: #56a959;
}
a.lightgreen-hover:hover {
  color: #22bc3f;
}
a.rose-hover:hover {
  color: #ff3366;
}
a.purple-hover:hover {
  color: #6e45e2;
}
a.blue-hover:hover {
  color: #3176ed;
}
a.skyblue-hover:hover {
  color: #0b88ee;
}
a:focus {
  outline: none;
  text-decoration: none;
}
/*------------------------------------------*/
/*   BUTTON SETTINGS
/*------------------------------------------*/
.btn {
  background-color: #ff3366;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0;
  padding: 1.025rem 2.5rem;
  border: 2px solid #ff3366;
  border-radius: 100px;
  transition: all 400ms ease-in-out;
}
.btn-arrow {
  position: relative;
}
.btn-arrow span {
  display: inline-block;
  position: relative;
  transition: all 300ms ease-in-out;
  will-change: transform;
}
.btn-arrow:hover span,
.btn-arrow:focus span {
  transform: translate3d(-10px, 0, 0);
}
.btn-arrow .svg-inline--fa {
  position: absolute;
  line-height: 1rem !important;
  right: 0px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 300ms ease-in-out;
}
/*------------------------------------------*/
/*   Button Size
/*------------------------------------------*/
.btn.btn-sm {
  font-size: 0.9rem;
  padding: 0.85rem 1.95rem;
}
.btn.btn-md {
  font-size: 1rem;
  padding: 1.025rem 2.5rem;
}
.btn.btn-lg {
  font-size: 1.1rem;
  padding: 1.05rem 3rem;
}
/*------------------------------------------*/
/*   Button Color
/*------------------------------------------*/
.btn-tra,
.white-color .btn-tra {
  color: #ff3366;
  background-color: transparent;
  border-color: #ff3366;
}
.btn-tra-purple {
  color: #6e45e2;
  background-color: transparent;
  border-color: #6e45e2;
}
.btn-tra-skyblue {
  color: #0b88ee;
  background-color: transparent;
  border-color: #0b88ee;
}
.btn-tra-black {
  color: #3a3a3a;
  background-color: transparent;
  border-color: #3a3a3a;
}
.btn-tra-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.btn-semiwhite {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.35);
  border-color: transparent;
}
.btn-purple {
  color: #fff;
  background-color: #6e45e2;
  border-color: #6e45e2;
}
.btn-blue {
  color: #fff;
  background-color: #3176ed;
  border-color: #3176ed;
}
.btn-skyblue {
  color: #fff;
  background-color: #42a5f5;
  border-color: #42a5f5;
}
.btn-black {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #3a3a3a;
}
.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.white-color .btn-white span,
.white-color .btn-yellow span {
  color: #333;
}
.btn-yellow {
  color: #333;
  background-color: #feca16;
  border-color: #feca16;
}
.btn-green {
  color: #fff;
  background-color: #56a959;
  border-color: #56a959;
}
.btn-lightgreen {
  color: #fff;
  background-color: #22bc3f;
  border-color: #22bc3f;
}
.btn-red {
  color: #fff;
  background-color: #e35029;
  border-color: #e35029;
}
.btn-teal {
  color: #fff;
  background-color: #5fa9a8;
  border-color: #5fa9a8;
}
/*------------------------------------------*/
/*   Button Hover
/*------------------------------------------*/
.btn:hover {
  color: #fff;
  background-color: #e62354;
  border-color: #e62354;
}
.btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
  opacity: 1;
  right: -20px;
}
.btn-tra:hover {
  background-color: #ff3366;
  border-color: #ff3366;
}
.btn-purple:hover,
.btn-tra-purple:hover {
  background-color: #5830c7;
  border-color: #5830c7;
}
.btn-blue:hover {
  background-color: #2b68d1;
  border-color: #2b68d1;
}
.btn-skyblue:hover,
.btn-tra-skyblue:hover {
  background-color: #0b88ee;
  border-color: #0b88ee;
}
.btn-black:hover,
.btn-tra-black:hover {
  background-color: #151515;
  border-color: #151515;
}
.btn-white:hover {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.white-color .btn-white:hover span {
  color: #fff;
}
.white-color .btn-tra-white.white-hover:hover,
.btn-tra-white.white-hover:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-tra-white.purple-hover:hover {
  color: #fff;
  background-color: #5830c7;
  border-color: #5830c7;
}
.btn-tra-white.green-hover:hover {
  color: #fff;
  background-color: #1da036;
  border-color: #1da036;
}
.btn-tra-white.blue-hover:hover {
  color: #fff;
  background-color: #2b68d1;
  border-color: #2b68d1;
}
.btn.tra-hover:hover,
.btn-yellow.tra-hover:hover,
.btn-purple.tra-hover:hover,
.btn-lightgreen.tra-hover:hover,
.btn-semiwhite:hover {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.btn-yellow.tra-hover:hover span {
  color: #fff;
}
.btn-tra-white.tra-hover:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.35);
  border-color: transparent;
}
.btn-yellow:hover {
  color: #333;
  background-color: #f6c007;
  border-color: #f6c007;
}
.btn-green:hover {
  background-color: #4b924e;
  border-color: #4b924e;
}
.btn-lightgreen:hover {
  background-color: #1da036;
  border-color: #1da036;
}
.btn-red:hover {
  background-color: #c84624;
  border-color: #c84624;
}
.btn-teal:hover {
  background-color: #4d8d8c;
  border-color: #4d8d8c;
}
/*------------------------------------------*/
/*   Button Focus
/*------------------------------------------*/
.btn:focus {
  color: #fff;
  box-shadow: none;
}
/*------------------------------------------*/
/*   VIDEO POPUP LINK
/*------------------------------------------*/
.modal-video {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.15rem;
}
.modal-video a span {
  font-size: 2.15rem;
  line-height: 2.15rem;
  margin-right: 4px;
  vertical-align: middle;
}
.modal-video a {
  font-size: 1.05rem;
  line-height: 2.15rem;
  font-weight: 400;
}
/*------------------------------------------*/
/*   VIDEO POPUP ICON
/*------------------------------------------*/
.video-preview {
  position: relative;
}
.video-btn {
  display: inline-block;
}
.video-block {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}
/*------------------------------------------*/
/*  Video Button Icon
/*------------------------------------------*/
.video-btn-xs,
.video-btn-sm,
.video-btn-md,
.video-btn-lg {
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  padding: 15px;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
}
.video-btn-xs:before,
.video-btn-sm:before,
.video-btn-md:before,
.video-btn-lg:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
}
.video-preview .video-btn-xs,
.video-preview .video-btn-sm,
.video-preview .video-btn-md,
.video-preview .video-btn-lg {
  position: absolute !important;
  top: 50%;
  left: 50%;
}
.video-preview .video-btn-xs {
  margin-top: -40px;
  margin-left: -40px;
}
.video-preview .video-btn-sm {
  margin-top: -45px;
  margin-left: -45px;
}
.video-preview .video-btn-md {
  margin-top: -50px;
  margin-left: -50px;
}
.video-preview .video-btn-lg {
  margin-top: -65px;
  margin-left: -65px;
}
.video-btn-xs,
.video-btn .video-btn-xs {
  width: 110px;
  height: 110px;
}
.video-btn-sm,
.video-btn .video-btn-sm {
  width: 120px;
  height: 120px;
}
.video-btn-md,
.video-btn .video-btn-md {
  width: 130px;
  height: 130px;
}
.video-btn-lg,
.video-btn .video-btn-lg {
  width: 150px;
  height: 150px;
}
.video-btn-xs .svg-inline--fa,
.video-btn-sm .svg-inline--fa,
.video-btn-md .svg-inline--fa,
.video-btn-lg .svg-inline--fa {
  position: relative;
  transition: all 400ms ease-in-out;
}
.video-btn-xs .svg-inline--fa {
  width: 80px;
  height: 80px;
}
.video-btn-sm .svg-inline--fa {
  width: 90px;
  height: 90px;
}
.video-btn-md .svg-inline--fa {
  width: 100px;
  height: 100px;
}
.video-btn-lg .svg-inline--fa {
  width: 120px;
  height: 120px;
}
/*------------------------------------------*/
/*  Video Icon Color
/*------------------------------------------*/
.play-icon-white {
  color: #fff;
}
.play-icon-grey {
  color: #999;
}
.play-icon-purple {
  color: #5830c7;
}
.play-icon-green {
  color: #56a959;
}
.play-icon-lightgreen {
  color: #22bc3f;
}
.play-icon-rose {
  color: #ff3366;
}
.play-icon-blue {
  color: #3176ed;
}
.play-icon-skyblue {
  color: #0b88ee;
}
.play-icon-yellow {
  color: #feca16;
}
/*------------------------------------------*/
/*  Video Icon Background
/*------------------------------------------*/
.ico-bkg {
  background-color: #fff;
  position: absolute;
  top: 40px;
  left: 40px;
}
.play-icon-white .ico-bkg {
  background-color: #444;
}
.video-btn-xs .ico-bkg {
  top: 30px;
  width: 38px;
  height: 50px;
}
.video-btn-sm .ico-bkg {
  top: 30px;
  width: 44px;
  height: 55px;
}
.video-btn-md .ico-bkg {
  width: 50px;
  height: 50px;
}
.video-btn-lg .ico-bkg {
  width: 65px;
  height: 70px;
}
/*------------------------------------------*/
/*   Hover
/*------------------------------------------*/
.video-btn-xs:hover .svg-inline--fa,
.video-btn-sm:hover .svg-inline--fa,
.video-btn-md:hover .svg-inline--fa,
.video-btn-lg:hover .svg-inline--fa {
  transform: scale(0.9);
}
.video-btn-xs:hover:before,
.video-btn-sm:hover:before,
.video-btn-md:hover:before,
.video-btn-lg:hover:before {
  opacity: 1;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
}
/*------------------------------------------*/
/*  Video Icon Text
/*------------------------------------------*/
.play-btn-txt {
  display: block;
  font-size: 0.95rem;
  line-height: 1;
  font-weight: 400;
  margin-top: 20px;
}
/*------------------------------------------*/
/*   APP DEVICES
/*------------------------------------------*/
.app-devices {
  padding: 15px 0 0 5px;
}
/*------------------------------------------*/
/*   App Devices Icons
/*------------------------------------------*/
.app-devices .svg-inline--fa {
  color: rgba(50, 50, 50, 0.3);
  font-size: 2.6rem;
  line-height: 1 !important;
  float: left;
  margin-right: 0.65rem;
}
.bg-green .app-devices .svg-inline--fa,
.bg-lightpurple .app-devices .svg-inline--fa,
.bg-lightblue .app-devices .svg-inline--fa {
  color: rgba(240, 240, 240, 0.5);
}
.app-devices .svg-inline--fa.f-tablet {
  transform: rotate(90deg);
}
.app-devices .svg-inline--fa.f-phone {
  margin-right: 1.25rem;
}
.app-devices-desc p {
  font-style: italic;
  line-height: 1.25;
  padding-right: 20%;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   ANIMATED PROGRESS BAR
/*------------------------------------------*/
.barWrapper {
  position: relative;
  margin-bottom: 25px;
}
.progress {
  height: 8px;
}
.bg-dark .progress {
  background-color: #777;
}
.bg-deepdark .progress {
  background-color: #777;
}
.bg-lightgrey .progress {
  background-color: #ccc;
}
.progress-bar {
  width: 0;
}
/*------------------------------------------*/
/*   Progress Bar Color
/*------------------------------------------*/
.black-progress .progress-bar {
  background-color: #333;
}
.green-progress .progress-bar {
  background-color: #56a959;
}
.rose-progress .progress-bar {
  background-color: #ff3366;
}
.blue-progress .progress-bar {
  background-color: #0b88ee;
}
.purple-progress .progress-bar {
  background-color: #5830c7;
}
.barWrapper p {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 7px;
}
.skill-percent {
  display: inline-block;
  position: absolute;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  top: 7px;
  right: 2px;
}
/*------------------------------------------*/
/*  BOX ICONS
/*------------------------------------------*/
.box-icon [class^=flaticon-]:before,
.box-icon [class*=" flaticon-"]:before,
.box-icon [class^=flaticon-]:after,
.box-icon [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4rem;
  line-height: 4rem !important;
  font-style: normal;
  margin-left: 0;
}
.box-icon-sm [class^=flaticon-]:before,
.box-icon-sm [class*=" flaticon-"]:before,
.box-icon-sm [class^=flaticon-]:after,
.box-icon-sm [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 3.5rem;
  line-height: 3.5rem !important;
  font-style: normal;
  margin-left: 0;
}
.box-icon-md [class^=flaticon-]:before,
.box-icon-md [class*=" flaticon-"]:before,
.box-icon-md [class^=flaticon-]:after,
.box-icon-md [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4.5rem;
  line-height: 4.5rem !important;
  font-style: normal;
  margin-left: 0;
}
.box-icon-lg [class^=flaticon-]:before,
.box-icon-lg [class*=" flaticon-"]:before,
.box-icon-lg [class^=flaticon-]:after,
.box-icon-lg [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 5rem;
  line-height: 5rem !important;
  font-style: normal;
  margin-left: 0;
}
/*------------------------------------------*/
/*   BOX ICON COLOR
/*------------------------------------------*/
.white-color .grey-icon span,
.grey-icon span {
  color: #757575;
  transition: all 400ms ease-in-out;
}
.white-color .red-icon span,
.red-icon span {
  background: -webkit-gradient(linear, left top, left bottom, from(#ee041a), to(#ff8212));
}
.white-color .rose-icon span,
.rose-icon span {
  color: #ff3366;
}
.white-color .green-icon span,
.green-icon span {
  color: #22bc3f;
}
.white-color .blue-icon span,
.blue-icon span {
  color: #0767c7;
}
.white-color .skyblue-icon span,
.skyblue-icon span {
  color: #42a5f5;
}
.white-color .purple-icon span,
.purple-icon span {
  background: -webkit-gradient(linear, left top, left bottom, from(#1d07f4), to(#f402b8));
}
/*------------------------------------------*/
/*   BOX ICON HOVER
/*------------------------------------------*/
.rose-hover:hover .grey-icon span {
  color: #ff3366;
  transform: scale(1.1);
}
.skyblue-hover:hover .grey-icon span {
  color: #42a5f5;
  transform: scale(1.1);
}
.lightgreen-hover:hover .grey-icon span {
  color: #22bc3f;
  transform: scale(1.1);
}
.red-hover:hover .grey-icon span {
  color: #e35029;
  transform: scale(1.1);
}
/*------------------------------------------*/
/*   TECHNOLOGIES ICONS
/*------------------------------------------*/
.technologies {
  margin-top: 35px;
}
.technologies p {
  color: #999;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
}
.technologies .svg-inline--fa {
  color: #888;
  font-size: 2.65rem;
  line-height: 1;
  margin-right: 10px;
}
/*------------------------------------------*/
/*    STORE BAGE ICONS
/*------------------------------------------*/
a.store {
  margin-right: 15px;
}
.btn:last-child,
a.store:last-child {
  margin-right: 0;
}
/*------------------------------------------*/
/*   BOX LIST
/*------------------------------------------*/
.box-list-icon {
  font-size: 0.9rem;
  line-height: 1.5rem;
  float: left;
  margin-right: 8px;
}
.box-list p {
  overflow: hidden;
  margin-bottom: 8px;
}
/*------------------------------------------*/
/*   COUNTDOWN ELEMENT
/*------------------------------------------*/
#clock {
  display: inline-block;
}
/*------------------------------------------*/
/*    Countdown Box
/*------------------------------------------*/
#clock .cbox {
  float: left;
  text-align: center;
  padding: 0 30px;
  display: inline-block;
}
#clock .cbox:last-child {
  border-right: none;
}
/*------------------------------------------*/
/*    Countdown Digit
/*------------------------------------------*/
#clock span.cbox-digit {
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -2px;
}
#clock span.cbox-txt {
  background-color: transparent;
  border: none;
  font-size: 0.975rem;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: block;
  padding-top: 10px;
}
/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/
span.section-id {
  color: #aaa;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
/*------------------------------------------*/
/*   SECTION TITLE
/*------------------------------------------*/
.section-title {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}
.section-title h3 {
  letter-spacing: -0.5px;
  margin-bottom: 15px;
}
.section-title p {
  font-size: 1.3125rem;
  /* 21px */
  padding: 0 10%;
  margin-bottom: 0;
}
.bg-deepdark .section-title p {
  color: #ccc;
}
/*------------------------------------------*/
/*   TEXT WEIGHT
/*------------------------------------------*/
.txt-300 {
  font-weight: 300;
}
.txt-400 {
  font-weight: 400;
}
.txt-500 {
  font-weight: 500;
}
.txt-600 {
  font-weight: 600;
}
.txt-700 {
  font-weight: 700;
}
.txt-800 {
  font-weight: 800;
}
.txt-900 {
  font-weight: 900;
}
.txt-upper {
  text-transform: uppercase;
}
/*------------------------------------------*/
/*   TEXT SETTINGS FOR DEMOS
/*------------------------------------------*/
.black-color,
.black-color h2,
.black-color h3,
.black-color h4,
.black-color h5,
.black-color h6,
.black-color p,
.black-color a,
.black-color li,
.black-color i,
.black-color span {
  color: #000;
}
.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span {
  color: #fff;
}
.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color span {
  color: #666;
}
.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.white-color .lightgrey-color,
.lightgrey-color span {
  color: #ccc;
}
.yellow-color,
.yellow-color h2,
.yellow-color h3,
.yellow-color h4,
.yellow-color h5,
.yellow-color h6,
.yellow-color p,
.yellow-color a,
.yellow-color li,
.yellow-color i,
.white-color .yellow-color,
.yellow-color span {
  color: #fcb80b;
}
.green-color,
.green-color h2,
.green-color h3,
.green-color h4,
.green-color h5,
.green-color h6,
.green-color p,
.green-color a,
.green-color li,
.green-color i,
.white-color .green-color,
.green-color span {
  color: #56a959;
}
.lightgreen-color,
.lightgreen-color h2,
.lightgreen-color h3,
.lightgreen-color h4,
.lightgreen-color h5,
.lightgreen-color h6,
.lightgreen-color p,
.lightgreen-color a,
.lightgreen-color li,
.lightgreen-color i,
.white-color .lightgreen-color,
.lightgreen-color span {
  color: #22bc3f;
}
.rose-color,
.rose-color h2,
.rose-color h3,
.rose-color h4,
.rose-color h5,
.rose-color h6,
.rose-color p,
.rose-color a,
.rose-color li,
.rose-color i,
.white-color .rose-color,
.rose-color span {
  color: #ff3366;
}
.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.white-color .blue-color,
.blue-color span {
  color: #3176ed;
}
.skyblue-color,
.skyblue-color h2,
.skyblue-color h3,
.skyblue-color h4,
.skyblue-color h5,
.skyblue-color h6,
.skyblue-color p,
.skyblue-color a,
.skyblue-color li,
.skyblue-color i,
.white-color .skyblue-color,
.skyblue-color span {
  color: #01b7de;
}
.red-color,
.red-color h2,
.red-color h3,
.red-color h4,
.red-color h5,
.red-color h6,
.red-color p,
.red-color a,
.red-color li,
.red-color i,
.white-color .red-color,
.red-color span {
  color: #e35029;
}
.purple-color,
.purple-color h2,
.purple-color h3,
.purple-color h4,
.purple-color h5,
.purple-color h6,
.purple-color p,
.purple-color a,
.purple-color li,
.purple-color i,
.white-color .purple-color,
.purple-color span {
  color: #6e45e2;
}
.deepblue-color,
.deepblue-color h2,
.deepblue-color h3,
.deepblue-color h4,
.deepblue-color h5,
.deepblue-color h6,
.deepblue-color p,
.deepblue-color a,
.deepblue-color li,
.deepblue-color i,
.white-color .deepblue-color,
.deepblue-color span {
  color: #004861;
}
/* ==========================================================================
  03.  PRELOAD SPINNER
  =========================================================================== */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #f5f5f5;
}
.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  box-shadow: 25px 25px rgb(49, 118, 237), -25px 25px rgb(255, 51, 102), -25px -25px rgb(49, 118, 237), 25px -25px rgb(255, 51, 102);
  animation: cssload-spin ease infinite 4.6s;
}
@keyframes cssload-spin {
  0%, 100% {
    box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102);
  }
  25% {
    box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226);
  }
  50% {
    box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102);
  }
  75% {
    box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
  }
}
@-o-keyframes cssload-spin {
  0%, 100% {
    box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102);
  }
  25% {
    box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226);
  }
  50% {
    box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102);
  }
  75% {
    box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
  }
}
@-ms-keyframes cssload-spin {
  0%, 100% {
    box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102);
  }
  25% {
    box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226);
  }
  50% {
    box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102);
  }
  75% {
    box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
  }
}
@-webkit-keyframes cssload-spin {
  0%, 100% {
    box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102);
  }
  25% {
    box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226);
  }
  50% {
    box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102);
  }
  75% {
    box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
  }
}
@-moz-keyframes cssload-spin {
  0%, 100% {
    box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102);
  }
  25% {
    box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226);
  }
  50% {
    box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102);
  }
  75% {
    box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
  }
}
/* ==========================================================================
  04.  HEADER & NAVIGATION
  =========================================================================== */
/*------------------------------------------*/
/*   LOGO IMAGE
/*------------------------------------------*/
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 5rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #555;
}
.bg-light {
  background-color: #fff !important;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}
.header .nb-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}
/*------------------------------------------*/
/*   NAVIGATION BAR
/*------------------------------------------*/
.navbar {
  padding: 0.75rem 1rem;
  transition: all 400ms ease-in-out;
}
.navbar.bg-tra {
  padding: 2.25rem 1rem;
  background-color: transparent;
}
/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/
.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 1.05rem;
  font-weight: 500;
  padding: 0.65rem 1.5rem;
  margin-left: 1px;
  border-radius: 6px;
}
/*------------------------------------------*/
/*   NAVIGATION MENU TEXT
/*------------------------------------------*/
.navbar-text {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-text.phone-number {
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 10px;
}
.navbar-text.phone-number .svg-inline--fa {
  margin-right: 4px;
}
.navbar-dark .navbar-text.lightgreen-color {
  color: #22bc3f;
}
.navbar-dark .navbar-text.yellow-color {
  color: #fcb80b;
}
.navbar-dark .navbar-text.blue-color {
  color: #01b7de;
}
.navbar-dark .navbar-text.rose-color {
  color: #ff3366;
}
/*------------------------------------------*/
/*   Dropdown Menu
/*------------------------------------------*/
.dropdown-menu {
  min-width: 10rem;
  padding: 0.85rem 0.45rem;
  margin: 0.85rem 0 0;
  font-size: 1.05rem;
  color: #555;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.dropdown-menu::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -5px;
  left: 20px;
  height: 0.9375rem;
  width: 0.9375rem;
  transform: rotate(45deg);
  z-index: -2;
}
.dropdown-item {
  font-weight: 500;
  padding: 0.6rem 4.5rem 0.6rem 1rem;
}
.dropdown-toggle::after {
  vertical-align: 0.175em;
}
/*------------------------------------------*/
/*   Navbar Social Icons
/*------------------------------------------*/
.header-socials {
  margin-top: 4px;
  margin-left: 3px;
  display: inline-block;
  text-align: center;
}
.header-socials span {
  float: left;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin-left: 5px;
}
.header-socials a {
  width: 38px;
  height: 38px;
  background-color: transparent;
  display: block;
  color: #fff;
  font-size: 1rem;
  line-height: 34px !important;
  border: 2px solid #fff;
  margin-left: 3px;
  border-radius: 100%;
}
.navbar-light .header-socials a {
  color: #333;
  border: 2px solid #333;
}
.header-socials a.ico-facebook:hover,
.navbar.scroll .header-socials a.ico-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}
.header-socials a.ico-twitter:hover,
.navbar.scroll .header-socials a.ico-twitter:hover {
  background-color: #00a9ed;
  border-color: #00a9ed;
}
.header-socials a.ico-behance:hover,
.navbar.scroll .header-socials a.ico-behance:hover {
  background-color: #2473f6;
  border-color: #2473f6;
}
.header-socials a.ico-google-plus:hover,
.navbar.scroll .header-socials a.ico-google-plus:hover {
  background-color: #cd1111;
  border-color: #cd1111;
}
.header-socials a.ico-linkedin:hover,
.navbar.scroll .header-socials a.ico-linkedin:hover {
  background-color: #015886;
  border-color: #015886;
}
.header-socials a.ico-dribbble:hover,
.navbar.scroll .header-socials a.ico-dribbble:hover {
  background-color: #d92d84;
  border-color: #d92d84;
}
.header-socials a.ico-instagram:hover,
.navbar.scroll .header-socials a.ico-instagram:hover {
  background-color: #beb3a8;
  border-color: #beb3a8;
}
.header-socials a.ico-pinterest:hover,
.navbar.scroll .header-socials a.ico-pinterest:hover {
  background-color: #ac281a;
  border-color: #ac281a;
}
.header-socials a.ico-youtube:hover,
.navbar.scroll .header-socials a.ico-youtube:hover {
  background-color: #cd1b20;
  border-color: #cd1b20;
}
.header-socials a.ico-tumblr:hover,
.navbar.scroll .header-socials a.ico-tumblr:hover {
  background-color: #3a5976;
  border-color: #3a5976;
}
.header-socials a.ico-vk:hover,
.navbar.scroll .header-socials a.ico-vk:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}
/*------------------------------------------*/
/*   Navbar Button
/*------------------------------------------*/
.navbar .btn {
  font-size: 1.05rem;
  font-weight: 500;
  padding: 0.785rem 2.15rem;
  margin-top: 0;
  margin-left: 12px;
}
/*------------------------------------------*/
/*   Navigation Menu Hover
/*------------------------------------------*/
.navbar-light .nav-link:focus,
.navbar-light .nav-link:hover {
  color: #fff;
  background-color: rgba(140, 140, 140, 0.2);
}
.navbar-dark .nav-link:focus,
.navbar-dark .nav-link:hover {
  color: #fff;
  background-color: rgba(240, 240, 240, 0.2);
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #333;
  text-decoration: none;
  background-color: #eee;
}
/*------------------------------------------*/
/*   Navbar Scroll
/*------------------------------------------*/
.navbar.scroll.bg-tra {
  padding: 12px 0;
  background-color: #fcfcfc;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}
.navbar.scroll.navbar-dark.bg-tra .nav-link,
.navbar.scroll .navbar-text span.phone-text.white-color {
  color: #555;
}
.navbar.scroll.navbar-dark.bg-tra .nav-link:hover {
  background-color: rgba(200, 200, 200, 0.35);
}
.navbar.scroll .header-socials a,
.navbar.scroll .btn.btn-tra-white {
  color: #555;
  border-color: #555;
}
.navbar.scroll .header-socials a:hover {
  color: #fff;
}
.navbar.scroll .btn.btn-tra-white:hover {
  background-color: #ff3366;
  color: #fff;
  border-color: #ff3366;
}
.navbar.scroll .btn.btn-semiwhite,
.navbar.scroll .btn.tra-hover:hover,
.navbar.scroll .btn.btn-purple.tra-hover:hover,
.navbar.scroll .btn.btn-lightgreen.tra-hover:hover,
.navbar.scroll .btn.btn-semiwhite.tra-hover:hover {
  color: #555;
  background-color: transparent;
  border-color: #555;
}
/*------------------------------------------*/
/*   Responsive Burger Menu
/*------------------------------------------*/
.navbar-light .navbar-toggler,
.navbar.bg-tra .navbar-toggler {
  color: #888;
  border-color: #888;
  padding: 0.25rem 0.5rem;
  font-size: 1.05rem;
}
.navbar-dark.bg-tra .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-toggler:focus {
  outline: 0;
}
/*------------------------------------------*/
/*   Logo Image
/*------------------------------------------*/
.logo-white,
.logo-black {
  display: block;
}
.navbar-light.bg-light .logo-white,
.navbar-dark.bg-tra .logo-black,
.navbar-light.bg-tra .logo-white,
.scroll.navbar-dark.bg-tra .logo-white,
.navbar-dark.bg-dark .logo-black,
.navbar-light.bg-light .logo-white {
  display: none;
}
.navbar-dark.bg-tra .logo-white,
.navbar-light.bg-tra .logo-black,
.scroll.navbar-dark.bg-tra .logo-black,
.navbar-dark.bg-dark .logo-white,
.navbar-light.bg-light .logo-black {
  display: block;
}
/* ==========================================================================
  05.  HERO
  =========================================================================== */
.hero-section .container {
  position: relative;
  overflow: hidden;
  z-index: 3;
}
#hero-6.hero-section .container,
#hero-11.hero-section .container,
#hero-13.hero-section .container,
#hero-15.hero-section .container,
#hero-19.hero-section .container,
#hero-24.hero-section .container,
#hero-25.hero-section .container {
  overflow: visible;
}
/*------------------------------------------*/
/*   HERO BACKGROUND IMAGES
/*------------------------------------------*/
#hero-1 {
  background-image: url("hero-1.feac0d25.jpg");
  background-position: center bottom;
  padding-top: 220px;
  padding-bottom: 190px;
  margin-bottom: 30px;
}
#hero-2 {
  background-image: url("hero-2.7ab1ab03.jpg");
}
#hero-3 {
  background-image: url("hero-3.7ab1ab03.jpg");
  padding-top: 220px;
  padding-bottom: 180px;
}
#hero-4 {
  background-image: url("hero-4.562134db.jpg");
  margin-top: 70px;
  padding-top: 160px;
  padding-bottom: 120px;
}
#hero-5 {
  background-image: url("hero-5.cdcb964e.jpg");
  background-position: center bottom;
  padding-top: 180px;
  padding-bottom: 120px;
}
.hero-6-text {
  background-image: url("hero-6.562134db.jpg");
  background-position: center bottom;
}
#hero-6-content {
  padding-top: 180px;
  padding-bottom: 380px;
}
#hero-8 {
  padding-top: 160px;
  padding-bottom: 20px;
}
#hero-10 {
  background-image: url("hero-10.7ab1ab03.jpg");
  padding-top: 240px;
  padding-bottom: 200px;
}
#hero-11-txt {
  background-image: url("hero-11.7ab1ab03.jpg");
  padding-top: 180px;
  padding-bottom: 450px;
}
#hero-12 {
  padding-top: 220px;
}
#hero-13-txt {
  background-image: url("hero-13.7ab1ab03.jpg");
  padding-top: 230px;
  padding-bottom: 100px;
}
#hero-14 {
  position: relative;
  padding-top: 250px;
  padding-bottom: 100px;
}
#hero-15-txt {
  background-image: url("hero-15.7ab1ab03.jpg");
  padding-top: 220px;
  padding-bottom: 200px;
}
#hero-16 {
  background-image: url("hero-16.7ab1ab03.jpg");
  padding-top: 160px;
}
#hero-17 {
  padding-top: 160px;
  padding-bottom: 20px;
}
#hero-18 {
  background-image: url("hero-18.7ab1ab03.jpg");
  padding-top: 190px;
}
#hero-19-txt {
  background-image: url("hero-19.7ab1ab03.jpg");
  padding-top: 200px;
  padding-bottom: 200px;
}
#hero-20 {
  background-image: url("hero-20.7ab1ab03.jpg");
  padding-top: 220px;
  padding-bottom: 140px;
}
#hero-21 {
  background-image: url("hero-21.b8e908a7.jpg");
  background-position: center bottom;
  padding-top: 180px;
  padding-bottom: 120px;
}
#hero-22 {
  background-image: url("hero-22.7ab1ab03.jpg");
  padding-top: 220px;
  padding-bottom: 140px;
}
#hero-23 {
  background-image: url("hero-23.7ab1ab03.jpg");
  padding-top: 250px;
  padding-bottom: 160px;
}
#hero-24-content {
  background-image: url("hero-24.7ab1ab03.jpg");
  padding-top: 180px;
  padding-bottom: 200px;
}
#hero-25 {
  padding-top: 200px;
  padding-bottom: 120px;
  border-bottom: 1px solid #ddd;
}
#hero-26 {
  background-image: url("hero-26.7ab1ab03.jpg");
  padding-top: 220px;
  padding-bottom: 180px;
}
#hero-27 {
  background-image: url("hero-27.7ab1ab03.jpg");
  padding-top: 180px;
  padding-bottom: 120px;
}
#hero-28 {
  position: relative;
  background-image: url("hero-28.562134db.jpg");
  padding-top: 220px;
  padding-bottom: 60px;
}
#hero-29 {
  background-image: url("hero-29.7ab1ab03.jpg");
  padding-top: 280px;
  padding-bottom: 180px;
}
#hero-30 {
  padding-top: 180px;
  padding-bottom: 80px;
}
/*------------------------------------------*/
/*   HERO OVERLAY
/*------------------------------------------*/
#hero-9 .hero-overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
/*------------------------------------------*/
/*  HERO TEXT ROTATOR
/*------------------------------------------*/
.hero-section .hero-slider {
  height: 760px;
  position: relative;
}
.hero-section .slides {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
}
/*------------------------------------------*/
/*   HERO FULLSCREEN SLIDER
/*------------------------------------------*/
#hero-7 {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px;
  z-index: 1;
  display: block;
  overflow: hidden;
  padding: 0 !important;
}
#slides {
  position: relative;
}
#slides .scrollable {
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  *zoom: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
#slides .scrollable:after {
  content: "";
  display: table;
  clear: both;
}
/*------------------------------------------*/
/*   FULLSCREEN SLIDER CONTENT
/*------------------------------------------*/
.hero-content {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 52%;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 50px 0;
  transform: translate(-50%, -50%);
}
/*------------------------------------------*/
/*   Hero Headers
/*------------------------------------------*/
.hero-section h4,
.hero-section h3,
.hero-section h2 {
  margin-bottom: 25px;
}
#hero-1.hero-section h2 {
  font-size: 4.25rem;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
}
.slides h2 {
  font-size: 5.25rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
}
#hero-9 .slides h2 {
  font-size: 6rem;
  letter-spacing: -1px;
}
#hero-4.hero-section h2 {
  font-size: 4.5rem;
  font-weight: 800;
  letter-spacing: -1px;
}
#hero-7.hero-section h2 {
  font-size: 5.5rem;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -2px;
  margin-bottom: 20px;
}
#hero-8 h2.h2-xl {
  line-height: 1.2;
  letter-spacing: -1.5px;
}
#hero-10 h2 {
  font-size: 6.25rem;
  letter-spacing: -1.5px;
  font-weight: 800;
  margin-bottom: 10px;
}
#hero-16 h2 {
  font-size: 6.25rem;
  line-height: 1;
  letter-spacing: -1.5px;
  font-weight: 800;
  margin-bottom: 30px;
}
#hero-18 h3.h3-xl {
  margin-bottom: 45px;
}
#hero-19 .hero-txt h2 {
  font-size: 5rem;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: -1px;
  margin-bottom: 25px;
}
#hero-20 h4 {
  background-color: #ff3366;
  font-weight: 400;
  line-height: 1.3;
  display: inline-block;
  padding: 5px 20px;
}
#hero-20 h2 {
  font-size: 5.75rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 25px;
  margin-bottom: 60px;
}
#hero-21 .hero-txt h2 {
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -1px;
  margin-bottom: 25px;
}
#hero-22 .hero-txt h2 {
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 0;
}
#hero-23 .hero-txt h2 {
  font-size: 4.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}
#hero-24 .hero-txt h2 {
  font-size: 13rem;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -5px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#hero-24 .hero-txt h3 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 0;
}
#hero-26 .hero-txt h2 {
  font-size: 5.5rem;
  line-height: 1.1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
}
#hero-26 .hero-txt h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}
#hero-26 .hero-price h2 {
  font-size: 5.5em;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 5px;
}
#hero-26 .hero-price h4 {
  font-size: 1.5em;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 15px;
}
#hero-28 .hero-txt h3 {
  font-size: 4.5em;
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 0;
}
#hero-29 .hero-txt h2 {
  font-size: 5.5em;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 25px;
}
#hero-30 .hero-txt h2 {
  font-size: 4em;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-top: 50px;
  margin-bottom: 20px;
}
#hero-30 .hero-txt h5 {
  font-weight: 300;
  line-height: 1.3;
  padding: 0 10%;
  margin-bottom: 35px;
}
.hero-inner-txt h3 {
  margin-bottom: 35px;
}
.hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
  font-weight: 700;
  letter-spacing: -1px;
  padding-bottom: 11px;
}
.underline {
  background-image: url("underline.c5a4ea8c.png");
  background-repeat: no-repeat;
  background-size: 100% 0.25em;
  background-position: center bottom;
}
/*------------------------------------------*/
/*   Hero Paragraph
/*------------------------------------------*/
.hero-txt p.p-hero {
  font-size: 1.35rem;
  margin-bottom: 40px;
}
.hero-price p.p-hero {
  font-size: 1.35rem;
  margin-bottom: 15px;
}
#hero-1 .hero-txt p {
  padding: 0 8%;
}
#hero-2 .hero-txt p,
#hero-3 .hero-txt p,
#hero-6 .hero-txt p,
#hero-9 .hero-txt p,
#hero-15 .hero-txt p,
#hero-18 .hero-txt p {
  padding: 0 12%;
}
#hero-4 .hero-txt p,
#hero-21 .hero-txt p {
  padding-right: 10%;
}
#hero-5 .hero-txt p,
#hero-22 .hero-txt p,
#hero-27 .hero-txt p {
  padding-right: 15%;
}
#hero-11 .hero-txt p,
#hero-23 .hero-txt p {
  padding: 0 10%;
}
#hero-16 .hero-txt p {
  padding-left: 10%;
}
#hero-20 p {
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-right: 10%;
}
.hero-inner-txt p {
  margin-bottom: 40px;
}
#hero-19 .hero-txt p {
  padding-right: 5%;
}
#hero-24 .hero-txt p {
  margin-top: 30px;
  padding-right: 20%;
  margin-bottom: 0;
}
#hero-29 .hero-txt p {
  padding-right: 20%;
}
/*------------------------------------------*/
/*    HERO BUTTONS
/*------------------------------------------*/
.hero-btns {
  margin-top: 35px;
}
/*------------------------------------------*/
/*    HERO LINKS
/*------------------------------------------*/
.hero-links {
  margin-top: 10px;
}
.hero-links span {
  font-size: 1rem;
  line-height: 1;
  margin-right: 25px;
}
.hero-links span:last-child {
  margin-right: 0;
}
.hero-links span a {
  text-decoration: underline;
}
.hero-links span a:hover {
  opacity: 0.8;
}
/*------------------------------------------*/
/*    HERO STORE BADGES
/*------------------------------------------*/
.hero-stores-badge span {
  display: block;
  font-size: 0.9rem;
  font-style: italic;
  line-height: 1;
  margin-top: 20px;
}
/*------------------------------------------*/
/*    HERO INNER CONTENT
/*------------------------------------------*/
.hero-inner-block {
  background-image: -webkit-linear-gradient(top, rgba(83, 45, 95, 0.8), rgba(92, 67, 155, 0.9));
  background-image: linear-gradient(top, rgba(83, 45, 95, 0.8), rgba(92, 67, 155, 0.9));
  position: relative;
  z-index: 999;
  margin-top: -410px;
  border-radius: 6px;
}
.hero-inner-txt {
  padding: 0 80px 0 40px;
}
.hero-inner-img img {
  border-radius: 6px 0 0 6px;
}
/*------------------------------------------*/
/*    HERO DISCOUNT BANNER
/*------------------------------------------*/
.discount-banner {
  position: relative;
  z-index: 999;
  padding: 60px 50px;
  border-radius: 6px;
  margin-top: -320px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.discount-banner p.p-xl {
  line-height: 1.35;
  margin-bottom: 25px;
}
.discount-banner h2.h2-xl {
  font-size: 6rem;
  line-height: 1;
  margin-bottom: 25px;
}
/*------------------------------------------*/
/*    HERO STATISTIC
/*------------------------------------------*/
.hero-statistic {
  background-color: #fff;
  position: relative;
  z-index: 999;
  padding: 50px 30px 20px 30px;
  border-radius: 6px;
  margin: -100px 80px 0;
  padding-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.hero-number {
  font-size: 2.25rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 5px;
}
.hero-statistic p {
  color: #757575;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   HERO IMAGE
/*------------------------------------------*/
.hero-img {
  text-align: center;
}
.hero-6-image img {
  margin-top: -330px;
}
.hero-14-img {
  position: absolute;
  top: 5%;
  right: -35%;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/*------------------------------------------*/
/*    HERO BOXES
/*------------------------------------------*/
.hero-19-boxes {
  margin-top: -120px;
}
#hero-28-boxes {
  margin-top: 100px;
}
.hbox-1 {
  background-color: #fff;
  position: relative;
  z-index: 999;
  padding: 55px 35px;
  border-radius: 4px;
  border-top: 3px solid #7ace4c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.hbox-2 {
  padding-right: 10px;
  margin-bottom: 40px;
}
.hbox-1 h5 {
  line-height: 1.25;
  margin-bottom: 18px;
}
.hbox-2 h5 {
  line-height: 1.25;
  margin-bottom: 20px;
}
.hbox-1 p,
.hbox-2 p {
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   HERO REGISTER FORM
/*------------------------------------------*/
#hero-24 h4.h4-xs {
  font-weight: 800;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.hero-form form {
  background: #fff;
  border: 1px solid #fafafa;
  padding: 55px 40px 55px;
  border-radius: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
#hero-24 .hero-form form {
  background: #333;
  border: 1px solid #303030;
  padding: 65px 40px 65px;
}
/*------------------------------------------*/
/*    Register Form Input
/*------------------------------------------*/
.register-form .col-md-12 {
  padding: 0;
}
.hero-form .form-control {
  background-color: transparent;
  color: #333;
  height: 46px;
  font-size: 15px;
  font-weight: 300;
  padding: 4px 6px;
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}
#hero-24 .hero-form .form-control {
  background-color: #fff;
  padding: 4px 15px;
}
.hero-form .form-control:focus {
  border-color: #ff3366;
  outline: 0px none;
  box-shadow: none;
}
/*------------------------------------------*/
/*    Register Form Button
/*------------------------------------------*/
.hero-form .btn {
  display: block;
  width: 100%;
  margin-top: 20px;
}
/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/
.hero-form .loading {
  color: #666;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
/*------------------------------------------*/
/*    HERO NEWSLETTER FORM
/*------------------------------------------*/
.hero-section .newsletter-form {
  margin: 0 15%;
}
#hero-21.hero-section .newsletter-form {
  margin-top: 40px;
}
/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/
.hero-section .newsletter-form .form-control {
  height: 57px;
  background-color: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #222;
  font-size: 1.15rem;
  font-weight: 400;
  padding: 0 1.4rem;
  box-shadow: none;
  border-radius: 15px 0 0 15px;
}
.hero-section .newsletter-form .form-control:focus {
  border: 1px solid #eee;
  border-right: none;
  outline: 0;
  box-shadow: none;
}
/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/
.hero-section .newsletter-form .form-control::-moz-placeholder {
  color: #999;
}
.hero-section .newsletter-form .form-control:-ms-input-placeholder {
  color: #999;
}
.hero-section .newsletter-form .form-control::-webkit-input-placeholder {
  color: #999;
}
/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/
.hero-section .newsletter-form .btn {
  width: 100%;
  height: 57px;
  background-color: #fff;
  border: 1px solid #eee;
  border-left: none;
  color: #555;
  font-size: 1.25rem;
  margin-top: 0;
  padding: 0 1.4rem;
  border-radius: 0 15px 15px 0;
}
.hero-section .newsletter-form .btn:hover {
  color: #444;
}
/*------------------------------------------*/
/*   HERO TEXT ROTATOR DOTS
/*------------------------------------------*/
.hero-slider .flex-control-nav {
  bottom: 5%;
}
.hero-slider .flex-control-paging li a {
  background: rgba(240, 240, 240, 0.15);
  width: 9px;
  height: 9px;
  border: 2px solid transparent;
}
.hero-slider .flex-control-paging li a.flex-active {
  background: rgba(255, 255, 255, 0.95);
  border: 2px solid rgba(255, 255, 255, 0.95);
}
/*------------------------------------------*/
/*   FULLSCREEN SLIDER PAGINATION
/*------------------------------------------*/
.slides-pagination {
  width: 100%;
  position: absolute;
  bottom: 5%;
  z-index: 20;
  text-align: center;
}
.slides-pagination a {
  width: 30px;
  height: 4px;
  background-color: #ddd;
  color: #1a1a1a;
  opacity: 0.2;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  margin: 2px;
  overflow: hidden;
  text-indent: -100%;
}
.slides-pagination a:hover,
.slides-pagination a.current {
  background-color: #fff;
  opacity: 1;
}
/* ==========================================================================
  06.  ABOUT
  =========================================================================== */
#about-2.bg-dark {
  background-image: url("tra-map.d10b520d.png");
}
#about-6 .bg-inner {
  position: relative;
  z-index: 1;
}
/*------------------------------------------*/
/*   ABOUT TEXT
/*------------------------------------------*/
.about-txt {
  margin-bottom: 40px;
}
.abox-4 {
  padding: 0 15px;
}
#a4-1.abox-4 {
  padding: 0 35px;
}
.abox-4 h5 {
  margin-bottom: 10px;
}
#a4-1.abox-4 h5 {
  margin-bottom: 25px;
}
#a4-1 .btn {
  margin-top: 20px;
}
#about-4 .a4-img {
  padding: 0;
}
/*------------------------------------------*/
/*   ABOUT TYPOGRAPHY
/*------------------------------------------*/
.about-txt h2.h2-lg,
.about-txt h3.h3-sm,
.about-txt h3.h3-xl {
  margin-bottom: 30px;
}
.layer-phone {
  margin-top: 60px;
  margin-bottom: 40px;
}
.layer-phone h5 {
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
}
/*------------------------------------------*/
/*   About Button
/*------------------------------------------*/
.about-btn {
  margin-top: 45px;
}
/*------------------------------------------*/
/*   ABOUT IMAGE
/*------------------------------------------*/
.about-img {
  text-align: center;
  margin-bottom: 40px;
}
#about-4 .about-img {
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   ABOUT REGISTER FORM
/*------------------------------------------*/
.about-form {
  margin-top: -200px;
  position: relative;
  z-index: 999;
}
.about-form form {
  background: #21262b;
  border: 1px solid #222;
  padding: 70px 40px 55px;
  border-radius: 0;
}
/*------------------------------------------*/
/*    About Form Input
/*------------------------------------------*/
.about-form .col-md-12 {
  padding: 0;
}
.about-form .form-control {
  background-color: #fff;
  color: #333;
  height: 46px;
  font-size: 15px;
  font-weight: 300;
  padding: 4px 10px;
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}
.about-form .form-control:focus {
  border-color: #ff3366;
  outline: 0px none;
  box-shadow: none;
}
/*------------------------------------------*/
/*    Register Form Button
/*------------------------------------------*/
.about-form .btn {
  display: block;
  width: 100%;
  margin-top: 20px;
}
/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/
.about-form .loading {
  color: #666;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
/* ==========================================================================
  07.  SERVICES
  =========================================================================== */
#services-2.bg-image {
  background-image: url("services-2.7ab1ab03.jpg");
}
/*------------------------------------------*/
/*   SERVICES SECTION TITLE
/*------------------------------------------*/
#services-1 .section-title {
  margin-bottom: 60px;
}
/*------------------------------------------*/
/*   SERVICE BOX
/*------------------------------------------*/
.sbox-1,
.sbox-5 {
  margin-bottom: 60px;
}
.sbox-2 {
  margin-bottom: 40px;
  padding: 0 20px;
}
.sbox-3 {
  margin-bottom: 50px;
}
.sbox-4 {
  margin-bottom: 40px;
}
.sbox-6 {
  padding: 70px 40px;
  border: 1px solid #ddd;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 6px;
}
/*------------------------------------------*/
/*   SERVICES BOX TEXT
/*------------------------------------------*/
.sbox-1-txt,
.sbox-3-txt {
  overflow: hidden;
  padding-left: 18px;
}
.sbox-4-txt {
  overflow: hidden;
  padding-left: 30px;
}
.sbox-5-txt1 {
  margin-top: 12px;
}
/*------------------------------------------*/
/*   SERVICES BOX TYPOGRAPHY
/*------------------------------------------*/
.sbox-1 h5,
.sbox-3 h5,
.sbox-4 h5 {
  margin-bottom: 10px;
}
.sbox-2 h5,
.sbox-5 h5 {
  margin-top: 25px;
  margin-bottom: 10px;
}
.sbox-6 h4 {
  margin-top: 30px;
  margin-bottom: 0;
}
.sbox-1 p,
.sbox-2 p,
.sbox-3 p,
.sbox-4 p,
.sbox-5 p,
.sbox-6 p {
  line-height: 1.5;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   Service Box Icon
/*------------------------------------------*/
.sbox-1 span,
.sbox-3 span,
.sbox-4 span {
  text-align: center;
  float: left;
}
.sbox-2 span {
  display: block;
  position: relative;
  z-index: 999;
}
/*------------------------------------------*/
/*   Service Image
/*------------------------------------------*/
.services-img {
  text-align: center;
  margin-bottom: 40px;
}
/* ==========================================================================
  08.  CONTENT
  =========================================================================== */
#content-3,
#content-4 {
  position: relative;
}
#content-5,
#content-6 {
  position: relative;
}
.inner-block {
  border-radius: 6px;
}
#content-7-title {
  position: relative;
  z-index: 3;
  padding-top: 100px;
  padding-bottom: 280px;
}
#content-7-boxes {
  position: relative;
  padding-bottom: 60px;
  z-index: 999;
  margin-top: -280px;
}
/*------------------------------------------*/
/*   CONTENT TEXT
/*------------------------------------------*/
.content-txt {
  margin-bottom: 40px;
}
#content-3 .content-txt {
  padding-left: 60px;
}
#content-5 .content-txt,
#content-6 .content-txt,
#content-9 .content-txt,
#content-10 .content-txt {
  margin-bottom: 0;
}
#content-4 .content-txt {
  padding-right: 60px;
}
#content-9 .content-txt {
  padding: 0 40px 0 80px;
}
#content-10 .content-txt {
  padding: 0 80px 0 40px;
}
/*------------------------------------------*/
/*   CONTENT TEXT TYPOGRAPHY
/*------------------------------------------*/
.content-txt h2.h2-huge {
  font-size: 5rem;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 30px;
}
.content-txt h2.h2-lg,
.content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
  margin-bottom: 30px;
}
.content-txt h5.h5-sm {
  margin-top: 20px;
  margin-bottom: 18px;
}
.content-txt .small-statistic h5.h5-sm {
  margin-top: 0;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   Content List
/*------------------------------------------*/
ul.content-list {
  list-style: disc;
  margin-top: 20px;
  margin-left: 15px;
}
ul.content-list li {
  margin-bottom: 8px;
}
ul.content-list li:last-child {
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   Content Button
/*------------------------------------------*/
.content-btn {
  margin-top: 35px;
}
/*------------------------------------------*/
/*   CONTENT BOX
/*------------------------------------------*/
.cbox-1 {
  margin-bottom: 40px;
}
.cbox-txt {
  background-color: #fff;
  padding: 30px 30px 25px;
  border: 1px solid #ccc;
  border-radius: 0 0 6px 6px;
}
.cbox-txt h5 {
  margin-bottom: 15px;
}
/*------------------------------------------*/
/*   CONTENT IMAGE
/*------------------------------------------*/
.content-img {
  text-align: center;
  margin-bottom: 40px;
}
.content-3-img {
  background-image: url("content-3.562134db.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 0;
}
.content-4-img {
  background-image: url("content-4.7ab1ab03.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 50%;
}
.content-right-img {
  position: absolute;
  top: 0;
  right: -30%;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content-left-img {
  position: absolute;
  top: 0;
  left: -30%;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#content-9 .content-img,
#content-10 .content-img {
  margin-bottom: 0;
}
.inner-block img {
  border-radius: 0 6px 6px 0;
}
#content-10 .inner-block img {
  border-radius: 6px 0 0 6px;
}
/* ==========================================================================
  09.  BANNER
  =========================================================================== */
#banner-1 {
  background-image: url("banner-1.562134db.jpg");
  background-position: top center;
  padding-top: 100px;
  padding-bottom: 100px;
}
#banner-2 {
  padding-top: 100px;
  padding-bottom: 10px;
}
#banner-4 {
  background-image: url("banner-4.562134db.jpg");
  background-position: center center;
  padding-top: 150px;
  padding-bottom: 150px;
}
/*------------------------------------------*/
/*    BANNER TYPOGRAPHY
/*------------------------------------------*/
#banner-2 .banner-txt h2,
#banner-3 .banner-txt h2,
#banner-4 .banner-txt h2 {
  margin-bottom: 30px;
}
#banner-1 .banner-txt h2,
#banner-3 .banner-txt h3 {
  margin-bottom: 25px;
}
#banner-2 .banner-txt h2 span {
  font-weight: 800;
  letter-spacing: -1px;
}
#banner-1 .banner-txt p {
  padding-right: 10%;
}
#banner-3 .banner-txt p {
  padding: 0 10%;
}
/*------------------------------------------*/
/*   Banner Button
/*------------------------------------------*/
.banner-btn {
  margin-top: 35px;
}
/* ==========================================================================
  10.  VIDEO
  =========================================================================== */
#video-1 {
  background-image: url("video-1.7ab1ab03.jpg");
  padding-top: 140px;
  padding-bottom: 140px;
}
#video-2 {
  background-image: url("video-2.7ab1ab03.jpg");
  padding-top: 180px;
  padding-bottom: 180px;
}
/*------------------------------------------*/
/*   VIDEO TYPOGRAPHY
/*------------------------------------------*/
#video-3 p {
  margin-top: 15px;
  margin-bottom: 50px;
  padding: 0 10%;
}
/* ==========================================================================
  11.  PORTFOLIO
  =========================================================================== */
#single-project {
  margin-top: 65px;
}
#portfolio-1 .section-title {
  margin-bottom: 40px;
}
/*------------------------------------------*/
/*    FILTER BUTTONS
/*------------------------------------------*/
.btn-toolbar {
  display: inline-block;
  margin: 0 auto 60px;
}
.portfolio-filter button {
  background-color: #eee;
  color: #666;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 3px;
  padding: 0.65rem 1.65rem;
  border: none;
  border-radius: 100px;
  transition: all 400ms ease-in-out;
  outline: 0px none;
  cursor: pointer;
}
.bg-dark .portfolio-filter button {
  color: #fff;
  background-color: rgba(30, 30, 30, 0.3);
}
.bg-lightgrey .portfolio-filter button {
  background-color: #e0e0e0;
}
/*------------------------------------------*/
/*    Filter Button Active
/*------------------------------------------*/
.portfolio-filter button.is-checked,
.bg-dark .portfolio-filter button.is-checked {
  color: #fff;
  background-color: #f23472;
  cursor: default;
  outline: 0px none;
}
.portfolio-filter.yellow-btngroup button.is-checked,
.bg-dark .portfolio-filter.yellow-btngroup button.is-checked {
  color: #333;
  background-color: #fcb80b;
  cursor: default;
  outline: 0px none;
}
/*------------------------------------------*/
/*     Filter Button Hover
/*------------------------------------------*/
.portfolio-filter button:hover {
  background-color: #ccc;
  color: #333;
}
.bg-dark .portfolio-filter button:hover {
  background-color: rgba(30, 30, 30, 0.5);
  color: #fff;
}
/*------------------------------------------*/
/*    PORTFOLIO IMAGES HOLDER
/*------------------------------------------*/
.masonry-wrap .portfolio-item {
  width: 33.33%;
  padding: 0;
  margin-bottom: 0;
}
#portfolio-3 .masonry-wrap .portfolio-item {
  width: 25%;
  padding: 0;
  margin-bottom: 0;
}
#portfolio-1 .masonry-wrap .portfolio-item,
#portfolio-2 .masonry-wrap .portfolio-item {
  padding: 0 10px;
  margin-bottom: 20px;
}
/*------------------------------------------*/
/*    Portfolio Image Meta
/*------------------------------------------*/
.project-description {
  position: absolute;
  bottom: 10%;
  left: 0;
  z-index: 20;
  width: 100%;
  opacity: 0;
  transition: all 400ms ease-in-out;
}
.project-description h5.h5-sm {
  margin-top: 3px;
}
.project-description span {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
}
/*------------------------------------------*/
/*    Image Hover Overlay
/*------------------------------------------*/
.hover-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.hover-overlay img {
  transform: scale(1.1);
  overflow: hidden;
  transition: transform 400ms;
}
/*------------------------------------------*/
/*    Overlay Background
/*------------------------------------------*/
.item-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.7);
  transition: all 400ms ease-in-out;
}
/*------------------------------------------*/
/*    Image Hover Effect
/*------------------------------------------*/
.hover-overlay:hover img {
  transform: scale(1);
}
.hover-overlay:hover .item-overlay {
  opacity: 1;
}
.portfolio-item:hover .project-description {
  left: 8%;
  opacity: 1;
}
/*------------------------------------------*/
/*    SINGLE PROJECT DATA
/*------------------------------------------*/
.project-txt h3.h3-xs {
  margin-top: 25px;
  margin-bottom: 20px;
}
.project-data {
  margin-top: 20px;
}
.project-data h5 {
  margin-bottom: 4px;
}
.project-data p {
  margin-bottom: 0;
}
.project-data p span {
  font-weight: 700;
}
/*------------------------------------------*/
/*    Share Project Links
/*------------------------------------------*/
.share-project {
  margin-top: 5px;
  display: inline-block;
  padding-left: 0;
}
.share-project li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}
.share-project a {
  color: #666;
}
.share-project .svg-inline--fa {
  font-size: 1.05rem;
  margin-right: 8px;
  transition: transform 450ms;
}
.share-project a:hover {
  color: #000;
}
/* ==========================================================================
  12.  TEAM
  =========================================================================== */
/*------------------------------------------*/
/*    TEAM MEMBER
/*------------------------------------------*/
.team-member {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
}
#team-2 .team-member {
  margin-bottom: 60px;
}
#team-1 .team-member > img {
  transform: scale(1);
  overflow: hidden;
  transition: transform 450ms;
}
/*------------------------------------------*/
/*    TEAM MEMBER META
/*------------------------------------------*/
.tm-meta {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 10%;
  z-index: 20;
  zoom: 1;
  opacity: 0;
  transition: all 200ms ease-in-out;
}
.tm-hiring {
  width: 100%;
  position: absolute;
  bottom: 10%;
  left: 40px;
  z-index: 20;
  zoom: 1;
  opacity: 1;
}
.team-member:hover .tm-meta {
  bottom: 8%;
  opacity: 1;
}
/*------------------------------------------*/
/*    TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/
.tm2-meta {
  padding-top: 25px;
  padding-bottom: 0;
}
.tm-meta h5.h5-sm {
  margin-bottom: 0px;
}
.tm2-meta h5.h5-sm {
  margin-bottom: 5px;
}
.tm-hiring h5.h5-sm {
  margin-bottom: 0;
}
.tm-meta span,
.tm2-meta span {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
}
.tm2-meta span {
  font-weight: 300;
  display: block;
}
.tm-hiring a {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  text-decoration: underline;
}
.tm2-meta p {
  margin-top: 10px;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*    Team Member Social Links
/*------------------------------------------*/
.tm-social ul {
  display: inline-block;
  padding-left: 0;
  margin-top: 14px;
  position: absolute;
  right: 15%;
  bottom: 0;
}
.tm-social ul li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}
.tm-social a {
  width: 32px;
  height: 32px;
  color: #fff;
  font-size: 1rem;
  line-height: 32px !important;
  margin-right: 1px;
  display: block;
  border-radius: 100%;
  transition: all 400ms ease-in-out;
}
.tm-social a.ico-facebook {
  background-color: #3b5998;
}
.tm-social a.ico-twitter {
  background-color: #00a9ed;
}
.tm-social a.ico-instagram {
  background-color: #e44772;
}
.tm-social a.ico-dribbble {
  background-color: #d92d84;
}
.tm-social a.ico-behance {
  background-color: #2473f6;
}
.tm-social a.ico-pinterest {
  background-color: #ac281a;
}
.tm-social a.ico-linkedin {
  background-color: #015886;
}
.tm-social a.ico-google-plus {
  background-color: #cd1111;
}
.tm-social a.ico-youtube {
  background-color: #cd1b20;
}
.tm-social a.ico-tumblr {
  background-color: #3a5976;
}
.tm-social a.ico-vk {
  background-color: #3b5998;
}
/*------------------------------------------*/
/*    Image Hover Overlay
/*------------------------------------------*/
.tm-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.55);
  transition: opacity 400ms ease-in-out;
}
/*------------------------------------------*/
/*    Team Member Hover Effect
/*------------------------------------------*/
#team-1 .team-member:hover > img {
  transform: scale(1.2);
}
#team-1 .team-member:hover .tm-overlay {
  opacity: 1;
}
/* ==========================================================================
  13.  SEARCH
  =========================================================================== */
#search-1 {
  padding-top: 80px;
  padding-bottom: 80px;
}
/*------------------------------------------*/
/*   SEARCH TYPOGRAPHY
/*------------------------------------------*/
#search-1 h3 {
  margin-bottom: 10px;
}
.domain-names {
  background-color: #fff;
  margin-top: 30px;
}
/*------------------------------------------*/
/*   Domain Name Box
/*------------------------------------------*/
.dn-box {
  padding: 25px 20px;
}
.dn-box p {
  margin-bottom: 0;
}
.dn-box span {
  color: #333;
  font-weight: 700;
  display: block;
}
/*------------------------------------------*/
/*   SEARCH FORM
/*------------------------------------------*/
#hero-28 .search-form {
  margin: 0 15%;
}
.search-form .form-control {
  height: 60px;
  color: #fff;
  font-size: 1.05rem;
  padding: 0.375rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 6px 0 0 6px;
  transition: all 400ms ease-in-out;
}
/*------------------------------------------*/
/*    Search Form Input Focus
/*------------------------------------------*/
.search-form .form-control:focus {
  background-color: transparent;
  border: 1px solid #ccc;
  border-right: none;
  box-shadow: none;
}
/*------------------------------------------*/
/*    Search Form Placeholder
/*------------------------------------------*/
.search-form .form-control::-moz-placeholder {
  color: #ccc;
}
.search-form .form-control:-ms-input-placeholder {
  color: #ccc;
}
.search-form .form-control::-webkit-input-placeholder {
  color: #ccc;
}
/*------------------------------------------*/
/*    Search Form Button
/*------------------------------------------*/
.search-form .btn {
  height: 60px;
  font-size: 1.05rem;
  padding: 0.375rem 1.45rem;
  border-radius: 0 6px 6px 0;
}
#hero-28 .search-form .btn {
  padding: 0.375rem 2.45rem;
}
/* ==========================================================================
  14.  SCHEDULE
  =========================================================================== */
/*------------------------------------------*/
/*   SCHEDULE TABS NAVIGATION
/*------------------------------------------*/
.nav-tabs {
  margin-bottom: 60px;
}
.bg-dark .nav-tabs {
  border-color: #666;
}
.nav {
  width: 100%;
}
.nav-tabs .nav-link {
  color: #999;
  font-size: 1.15rem;
  font-weight: 500;
  letter-spacing: 0;
  border: none;
  padding: 0.85rem 0;
}
.nav-tabs.rose-nav .nav-link.active,
.nav-tabs.rose-nav .nav-item.show .nav-link {
  color: #fff;
  background-color: #ff3366;
}
.nav-tabs.yellow-nav .nav-link.active,
.nav-tabs.yelloe-nav .nav-item.show .nav-link {
  color: #333;
  background-color: #feca16;
}
.nav-tabs.grey-nav .nav-link.active,
.nav-tabs.grey-nav .nav-item.show .nav-link {
  color: #333;
  background-color: #ccc;
}
/*------------------------------------------*/
/*   SCHEDULE TABS CONTENT
/*------------------------------------------*/
.schedule-event {
  margin-bottom: 15px;
}
/*------------------------------------------*/
/*    Event Time
/*------------------------------------------*/
.event-time span {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 700;
  display: block;
  padding-left: 10px;
}
/*------------------------------------------*/
/*    Session Description
/*------------------------------------------*/
.event-description {
  padding-left: 0;
}
.event-description h5 {
  margin-bottom: 7px;
}
.event-description p.event-speaker {
  color: #757575;
  margin-bottom: 0;
}
.bg-dark .event-description p.event-speaker {
  color: #ddd;
}
.event-description p.p-small {
  color: #777;
  line-height: 1.3;
  margin-top: 8px;
}
.bg-dark .event-description p.p-small {
  color: #aaa;
}
.event-description hr {
  border-color: #ccc;
  margin-top: 25px;
  margin-right: 25px;
}
.bg-dark .event-description hr {
  border-color: #666;
}
/*------------------------------------------*/
/*    AGENDA TITLE
/*------------------------------------------*/
.agenda-tittle {
  margin-top: 45px;
  margin-bottom: 90px;
  padding: 25px 30px;
  background-color: #444;
}
.agenda-tittle h3 {
  line-height: 1;
  margin-bottom: 3px;
}
.agenda-tittle span {
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 0;
}
/* ==========================================================================
  15.  STATISTIC
  =========================================================================== */
#statistic-1 {
  padding-top: 80px;
  padding-bottom: 50px;
}
.statistic-section.bg-image {
  background-image: url("statistic.522d5407.jpg");
}
/*------------------------------------------*/
/*    STATISTIC TYPOGRAPHY
/*------------------------------------------*/
.statistic-title {
  text-align: center;
  margin-bottom: 50px;
}
.statistic-title h3 {
  margin-bottom: 15px;
}
.statistic-title p {
  padding: 0 10%;
}
/*------------------------------------------*/
/*    STATISTIC BLOCK
/*------------------------------------------*/
.statistic-block {
  text-align: center;
  margin-bottom: 30px;
}
.small-statistic .statistic-block {
  text-align: left;
}
/*------------------------------------------*/
/*    Statistic Block Number
/*------------------------------------------*/
.statistic-number {
  font-size: 4.15rem;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  margin-bottom: 14px;
}
.small-statistic .statistic-number {
  font-size: 4.5rem;
  letter-spacing: -1px;
}
.statistic-block h5 {
  margin-bottom: 5px;
}
.small-statistic .statistic-block h5 {
  margin-left: 6px;
}
.statistic-block p {
  font-style: italic;
  margin-bottom: 0;
}
/* ==========================================================================
  16. PRICING TABLES
  =========================================================================== */
#pricing-2-title {
  position: relative;
  z-index: 3;
  padding-top: 80px;
  padding-bottom: 320px;
}
#pricing-2-title.bg-deepgreen,
#pricing-2-title.bg-purple {
  background-image: url("tra-bg.7c8eb794.png");
}
#pricing-2-content {
  margin-top: -330px;
  padding-bottom: 80px;
  position: relative;
  z-index: 999;
}
/*------------------------------------------*/
/*   PRICING PAGE TABS FILTER
/*------------------------------------------*/
#pills-tab {
  margin: 30px auto 40px;
}
.nav-pills {
  background-color: rgba(240, 240, 240, 0.3);
  border: 1px solid #ccc;
  border-radius: 100px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e35029;
  border-color: #e35029;
  color: #fff;
}
.nav-pills.rose-navi .nav-link.active {
  background-color: #ff3366;
  border-color: #ff3366;
  color: #fff;
}
.nav-pills.green-navi .nav-link.active {
  background-color: #22bc3f;
  border-color: #22bc3f;
}
.nav-pills.blue-navi .nav-link.active {
  background-color: #3176ed;
  border-color: #3176ed;
}
.nav-pills .nav-link {
  background-color: transparent;
  border: 1px solid transparent;
  color: #555;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 30px;
  margin-right: 0px;
  border-radius: 100px;
  border: 1px solid transparent;
  transition: all 450ms ease-in-out;
}
.nav-pills .nav-link span {
  color: #ff3366;
  font-size: 0.95rem;
  font-weight: 700;
  margin-left: 10px;
}
.nav-pills .nav-link.active span,
.nav-pills .nav-link.active span:hover,
.nav-pills .nav-link.active span:focus {
  color: #fff;
}
/*------------------------------------------*/
/*   PRICING TABLE
/*------------------------------------------*/
.pricing-table {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  padding: 60px 50px;
  margin-bottom: 40px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#pricing-2 .pricing-table {
  background-color: #fdfdfd;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0 6px rgba(40, 40, 40, 0.1);
}
#pricing-3 .pricing-table {
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 60px 35px;
  border-radius: 6px;
}
/*------------------------------------------*/
/*    Pricing Table Price Plan
/*------------------------------------------*/
.pricing-plan {
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
}
.pricing-plan.rose-border {
  border-color: #ff3366;
}
.pricing-plan.skyblue-border {
  border-color: #42a5f5;
}
.pricing-plan.blue-border {
  border-color: #3176ed;
}
.pricing-plan.green-border {
  border-color: #56a959;
}
.pricing-plan.lightgreen-border {
  border-color: #22bc3f;
}
.pricing-plan.red-border {
  border-color: #e35029;
}
.pricing-plan.purple-border {
  border-color: #6e45e2;
}
.pricing-plan.deepgreen-border {
  border-color: #009587;
}
.pricing-plan h5 {
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}
.pricing-table span.price {
  font-size: 75px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.pricing-table sup {
  font-size: 55px;
  font-weight: 600;
  top: -7px;
  right: 2px;
}
.pricing-table sup.pricing-coins {
  top: -25px;
  font-size: 40px;
  font-weight: 600;
}
p.validity {
  color: #888;
  font-style: italic;
  font-size: 1.2rem;
  margin-top: 5px;
}
/*------------------------------------------*/
/*   Plan Price
/*------------------------------------------*/
#pricing-3 .price h5 {
  margin-bottom: 25px;
}
#pricing-3 .price span {
  font-size: 65px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  letter-spacing: 0.5px;
}
#pricing-3 .pricing-table .price sup {
  font-size: 40px;
  font-weight: 400;
  top: -10px;
  margin-right: 5px;
}
#pricing-3 .price span.price-vat {
  display: block;
  color: #999;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 300;
  margin-top: 14px;
  padding: 0 7%;
}
/*------------------------------------------*/
/*    Pricing Description
/*------------------------------------------*/
.pricing-text {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ddd;
}
.pricing-text p {
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 0;
}
.pricing-table p span {
  color: #000;
  font-weight: 800;
}
/*------------------------------------------*/
/*    Pricing Table Body
/*------------------------------------------*/
.pricing-table ul.features {
  padding: 30px 0 35px;
}
.pricing-table ul.features li {
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 400;
  padding: 11px 0;
}
.pricing-table ul.features li:last-child {
  border-bottom: none;
}
/*------------------------------------------*/
/*    Pricing Text
/*------------------------------------------*/
.pricing-txt {
  margin-top: 30px;
  margin-bottom: 40px;
}
.pricing-txt p span {
  font-weight: 700;
}
/* ===================================================================================
  17.  MORE ITEMS
  =================================================================================== */
/*------------------------------------------*/
/*    MORE APPS TYPOGRAPHY
/*------------------------------------------*/
.more-item-box h5 {
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 35px;
}
.more-item-box span.app-cat {
  color: #888;
  font-size: 0.95rem;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
}
.more-item-box .btn {
  margin-top: 10px;
}
/* ==========================================================================
  18.  TESTIMONIALS
  =========================================================================== */
#reviews-2.ipage {
  background-image: url("tra-map.d10b520d.png");
}
.reviews-section.bg-image {
  background-image: url("reviews.522d5407.jpg");
}
/*------------------------------------------*/
/*   TRANSPARENT QUOTE ICON
/*------------------------------------------*/
#reviews-2 .quote-icon {
  width: 125px;
  height: 110px;
  background: url("quote-big.289a1921.png");
  opacity: 0.2;
  margin: 0 auto -50px;
}
/*------------------------------------------*/
/*   TESTIMONIALS CAROUSEL
/*------------------------------------------*/
.review-1 {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 30px;
  padding: 0;
  transform: scale(0.9);
  transition: all 450ms ease-in-out;
}
.review-2 {
  text-align: center;
  margin-bottom: 80px;
}
.review-1 .review-txt {
  background-color: #fff;
  border: 1px solid #eee;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 50px 40px 55px;
  margin: 0 2px;
  transition: all 450ms ease-in-out;
}
.review-1.slick-current {
  transform: scale(1);
}
.review-1.slick-current .review-txt {
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/*------------------------------------------*/
/*    Testimonial Message Avatar
/*------------------------------------------*/
.review-1 .testimonial-avatar img {
  width: 90px;
  height: 90px;
  display: inline-block;
  margin: -45px auto 15px;
  border-radius: 100%;
}
#reviews-2 img {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin: 0 auto 15px;
  border-radius: 100%;
}
/*------------------------------------------*/
/*   Testimonial Message Text
/*------------------------------------------*/
.review-1 p {
  color: #666;
  font-size: 1.05rem;
  font-style: italic;
}
#reviews-2 .review-txt p {
  font-size: 1.85rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 20px 8% 35px;
}
p.testimonial-autor {
  color: #000;
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 0;
}
p.testimonial-autor.deepblue-color {
  color: #004861;
}
.testimonial-avatar span {
  font-size: 0.95rem;
  line-height: 1;
}
.review-author p {
  color: #333;
  font-size: 1.25rem;
  line-height: 1.2;
  font-style: normal;
  margin-bottom: 0;
}
#reviews-2 .review-author p {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 2px;
}
#reviews-2 .review-author span {
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
}
/*------------------------------------------*/
/*   Reviews Slick Carousel Dots
/*------------------------------------------*/
.reviews-section .slick-dots {
  position: absolute;
  z-index: 4;
  bottom: -30px;
}
.reviews-section .slick-dots li {
  margin: 0 2px;
  width: 15px;
  height: 15px;
}
.reviews-section .slick-dots li button:before {
  content: "";
  background: #ccc;
  border-radius: 50%;
  opacity: 1;
  width: 8px;
  height: 8px;
  left: auto;
  transition: all ease-in-out 0.2s;
}
.reviews-section .slick-dots li button:hover:before {
  background: #999;
}
.reviews-section .slick-dots li.slick-active button:before {
  background: #666;
}
/*------------------------------------------*/
/*   Reviews FlexSlider Navigation
/*------------------------------------------*/
.flex-control-paging li a {
  background: rgba(90, 90, 90, 0.25);
  width: 10px;
  height: 10px;
  border: 2px solid rgba(90, 90, 90, 0.02);
}
.flex-control-paging li a.flex-active {
  background: rgba(255, 255, 255, 0.65);
  border: 2px solid rgba(255, 255, 255, 0.02);
}
.rose-nav .flex-control-paging li a.flex-active {
  background: #ff3366;
  border: 2px solid #ff3366;
}
.purple-nav .flex-control-paging li a.flex-active {
  background: #6e45e2;
  border: 2px solid #6e45e2;
}
.skyblue-nav .flex-control-paging li a.flex-active {
  background: #0b88ee;
  border: 2px solid #0b88ee;
}
.green-nav .flex-control-paging li a.flex-active {
  background: #1da036;
  border: 2px solid #1da036;
}
/* ==========================================================================
  19.  BRANDS
  =========================================================================== */
#brands-1 {
  padding-top: 80px;
  padding-bottom: 60px;
}
.brands-section.bg-dark {
  background-image: url("tra-map.d10b520d.png");
}
.brands-section.bg-image {
  background-image: url("brands.7ab1ab03.jpg");
}
/*------------------------------------------*/
/*    BRANDS LOGO HOLDER
/*------------------------------------------*/
#brands-1 .brand-logo,
#pricing-2 .brand-logo {
  width: 20%;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}
#brands-2 .brand-logo {
  padding: 0 30px;
  margin-bottom: 30px;
}
.brand-logo img {
  padding: 0 10px;
}
/*------------------------------------------*/
/*    BRANDS TYPOGRAPHY
/*------------------------------------------*/
.brands-title {
  text-align: center;
}
#brands-2 .brands-title {
  margin-bottom: 50px;
}
.brands-title h5 {
  font-weight: 500;
  margin-bottom: 35px;
}
.brands-title h3 {
  margin-bottom: 15px;
}
.brands-title h5 span {
  font-weight: 700;
}
.brands-title p {
  padding: 0 10%;
}
/* ==========================================================================
  20.  FAQs
  =========================================================================== */
/*------------------------------------------*/
/*    FAQs TYPOGRAPHY
/*------------------------------------------*/
.question {
  margin-bottom: 30px;
}
.question h5 {
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.question p {
  color: #666;
  font-size: 1.05rem;
  line-height: 1.6;
}
.more-questions-btn {
  margin-top: 40px;
}
.faqs-btn {
  margin-top: 30px;
}
/* ==========================================================================
  21.  CALL TO ACTION
  =========================================================================== */
#cta-1 {
  padding-top: 130px;
  padding-bottom: 140px;
}
.cta-section.bg-image {
  background-image: url("call-to-action.522d5407.jpg");
}
.cta-section.bg-purple {
  background-image: url("tra-bg.7c8eb794.png");
}
#cta-4 .bg-image.bg-inner {
  background-image: url("cta-4.7ab1ab03.jpg");
  position: relative;
  z-index: 1;
}
#cta-5 {
  padding-top: 60px;
  padding-bottom: 60px;
}
/*------------------------------------------*/
/*    CALL TO ACTION TYPOGRAPHY
/*------------------------------------------*/
.cta-txt h2.h2-lg {
  letter-spacing: -1px;
  margin-bottom: 25px;
}
.cta-txt h2.h2-xl {
  letter-spacing: -1px;
}
.cta-txt h2.h2-md,
.cta-txt h2.h2-xs {
  margin-bottom: 20px;
}
.cta-txt h2.h2-xs span,
.cta-txt h2.h2-xl span {
  font-weight: 700;
}
.cta-txt h2.h2-xl span {
  font-weight: 800;
  letter-spacing: 0;
}
#cta-1 .cta-txt p {
  padding: 0 8%;
}
#cta-2 .cta-txt p {
  padding: 0 14%;
}
#cta-4 .cta-txt p {
  padding-right: 5%;
  margin-top: 30px;
  margin-bottom: 0;
}
#cta-1 .cta-btn {
  margin-top: 40px;
}
#cta-2 .cta-btn {
  margin-top: 30px;
}
#cta-3 .cta-btn {
  display: inline-block;
}
#cta-3 .cta-btn p span {
  font-weight: 700;
}
#cta-4 .cta-btn {
  margin-top: 40px;
}
/*------------------------------------------*/
/*    CALL TO ACTION IMAGE
/*------------------------------------------*/
.cta-4-img {
  text-align: center;
  margin-top: -80px;
  position: relative;
  z-index: 999;
}
/* ==========================================================================
  22.  BLOG
  =========================================================================== */
#single-blog-page {
  margin-top: 65px;
}
/*------------------------------------------*/
/*    BLOG POST
/*------------------------------------------*/
.blog-post {
  margin-bottom: 40px;
}
.posts-holder .blog-post {
  margin-bottom: 60px;
}
/*------------------------------------------*/
/*    BLOG TYPOGRAPHY
/*------------------------------------------*/
.blog-post h5 {
  line-height: 1.4;
  margin-bottom: 15px;
}
#blog-page .blog-post h5 {
  line-height: 1.3;
  margin-bottom: 10px;
}
#single-post-page.blog-post h5 {
  line-height: 1.3;
  margin-top: 30px;
  margin-bottom: 35px;
}
.related-post .blog-post-txt h5 {
  margin-bottom: 10px;
}
.blog-post-txt p.post-meta {
  color: #666;
  margin-bottom: 15px;
}
.related-post .blog-post-txt p.post-meta {
  margin-bottom: 6px;
}
.bg-deepdark .blog-post-txt p {
  color: #ddd;
}
.post-inner-img p.p-sm {
  color: #999;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 0;
}
.blog-post-txt p.post-meta span,
.sblog-post-txt p.post-meta span {
  font-weight: 700;
}
.blog-post-txt a:hover {
  text-decoration: underline;
}
/*------------------------------------------*/
/*    BLOG PAGE RIGHT SIDEBAR
/*------------------------------------------*/
#sidebar-right h5.h5-sm {
  margin-bottom: 25px;
}
/*------------------------------------------*/
/*   Blog Search Form
/*------------------------------------------*/
#search-field .form-control {
  background-color: #f0f0f0;
  padding-left: 20px;
  border: none;
  border-radius: 6px 0 0 6px;
}
#search-field .btn {
  background-color: #f0f0f0;
  color: #777;
  border: none;
  padding: 22px 18px;
  margin-top: 0;
  box-shadow: 0 0 0;
  border-radius: 0px 6px 6px 0;
}
#search-field .form-control:focus {
  border-color: #999;
  outline: 0px none;
  box-shadow: none;
}
/*------------------------------------------*/
/*   Blog Categories
/*------------------------------------------*/
ul.blog-category-list > li {
  padding: 10px 0;
  border-bottom: 1px dashed #c0c0c0;
  transition: all 300ms ease-in-out;
}
ul.blog-category-list > li:first-child {
  padding: 0 0 10px;
}
ul.blog-category-list > li:last-child {
  padding: 10px 0 0;
  border-bottom: none;
}
ul.blog-category-list > li a,
ul.blog-category-list > li span {
  color: #555;
}
ul.blog-category-list li .svg-inline--fa {
  margin-right: 10px;
}
ul.blog-category-list li a:hover {
  color: #000;
  text-decoration: none;
}
/*------------------------------------------*/
/*   Popular Posts
/*------------------------------------------*/
.popular-posts li {
  padding: 14px 0;
  border-bottom: 1px dashed #c0c0c0;
}
.popular-posts li:first-child {
  padding: 0 0 14px;
}
.popular-posts li:last-child {
  padding: 14px 0 0;
  border-bottom: 0;
}
.popular-posts img {
  text-align: center;
  float: left;
}
.post-summary {
  overflow: hidden;
  padding-left: 20px;
}
/*------------------------------------------*/
/*  Popular Post Typography
--------------------------------------------*/
.post-summary a {
  color: #555;
  transition: all 400ms ease-in-out;
}
.popular-posts .post-summary a:hover {
  text-decoration: underline;
}
.popular-posts p {
  color: #888;
  font-size: 0.875rem;
  margin-top: 6px;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   Blog Page Popular Tags
/*------------------------------------------*/
.badge {
  font-size: 0.75rem;
  font-weight: 600;
  color: #222;
  border: none;
  background-color: #f0f0f0;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 5px 10px;
  margin-right: 2px;
  transition: all 450ms ease-in-out;
}
.badge a {
  color: #666 !important;
  font-size: 0.75rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.42857;
}
.badge:hover {
  background-color: #2c353f;
  border-color: #2c353f;
  color: #fff;
}
.badge:hover a {
  color: #fff !important;
}
/*------------------------------------------*/
/*    BLOG PAGE PAGINATION
/*------------------------------------------*/
.page-link {
  color: #666;
  padding: 0.65rem 0.95rem;
}
.page-link:hover,
.page-link:focus {
  color: #444;
  background-color: #eee;
  border-color: #dee2e6;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2c353f;
  border-color: #2c353f;
}
/*------------------------------------------*/
/*    SINGLE POST SHARE ICONS
/*-----------------------------------------*/
.share-social-icons {
  display: inline-block;
  padding-left: 0;
}
.share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}
a.share-ico {
  width: 90px;
  height: 35px;
  font-size: 18px;
  line-height: 35px !important;
  text-align: center;
  margin-right: 3px;
  display: block;
  opacity: 0.75;
  border-radius: 6px;
}
a.share-ico.ico-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
a.share-ico.ico-twitter {
  background-color: #00a9ed;
  border-color: #00a9ed;
  color: #fff;
}
a.share-ico.ico-behance {
  background-color: #00a8e7;
  border-color: #00a8e7;
  color: #fff;
}
a.share-ico.ico-google-plus {
  background-color: #cd1111;
  border-color: #cd1111;
  color: #fff;
}
a.share-ico.ico-linkedin {
  background-color: #015886;
  border-color: #015886;
  color: #fff;
}
a.share-ico.ico-dribbble {
  background-color: #d92d84;
  border-color: #d92d84;
  color: #fff;
}
a.share-ico.ico-instagram {
  background-color: #beb3a8;
  border-color: #beb3a8;
  color: #fff;
}
a.share-ico.ico-pinterest {
  background-color: #ac281a;
  border-color: #ac281a;
  color: #fff;
}
a.share-ico.ico-dropbox {
  background-color: #008ad2;
  border-color: #008ad2;
  color: #fff;
}
a.share-ico.ico-skype {
  background-color: #00a9ed;
  border-color: #00a9ed;
  color: #fff;
}
a.share-ico.ico-youtube {
  background-color: #cd1b20;
  border-color: #cd1b20;
  color: #fff;
}
a.share-ico.ico-tumblr {
  background-color: #3a5976;
  border-color: #3a5976;
  color: #fff;
}
a.share-ico.ico-vimeo {
  background-color: #00adee;
  border-color: #00adee;
  color: #fff;
}
a.share-ico.ico-flickr {
  background-color: #d2d2d2;
  border-color: #d2d2d2;
  color: #fff;
}
a.share-ico.ico-github {
  background-color: #222;
  border-color: #222;
  color: #fff;
}
a.share-ico.ico-renren {
  background-color: #364a83;
  border-color: #364a83;
  color: #fff;
}
a.share-ico.ico-vk {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
a.share-ico.ico-xing {
  background-color: #015f5e;
  border-color: #015f5e;
  color: #fff;
}
a.share-ico.ico-weibo {
  background-color: #be4443;
  border-color: #be4443;
  color: #fff;
}
a.share-ico.ico-rss {
  background-color: #ff6600;
  border-color: #ff6600;
  color: #fff;
}
a.share-ico.ico-digg {
  background-color: #222;
  border-color: #222;
  color: #fff;
}
a.share-ico.ico-deviantart {
  background-color: #57675d;
  border-color: #57675d;
  color: #fff;
}
a.share-ico.ico-envelope {
  background-color: #999;
  border-color: #999;
  color: #fff;
}
a.share-ico.ico-delicious {
  background-color: #0a0a0a;
  border-color: #0a0a0a;
  color: #fff;
}
a.share-ico:hover {
  opacity: 1;
}
/*-----------------------------------------*/
/*    RELATED POST
/*-----------------------------------------*/
.related-post {
  margin-top: 70px;
  margin-bottom: 60px;
}
/*------------------------------------------*/
/*    SINGLE POST META
/*------------------------------------------*/
.comment-meta {
  margin-bottom: 10px;
}
.comment-meta h5 {
  line-height: 1.1;
  margin-bottom: 0;
}
.comment-date {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.btn-reply a {
  background-color: #888;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 8px;
  text-transform: uppercase;
  border-radius: 2px;
}
.btn-reply a:hover {
  color: #fff;
  background-color: #2c353f;
}
.comment-reply {
  margin-left: 40px;
}
.single-post-comments hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
/*------------------------------------------*/
/*    SINGLE POST COMMENT FORM
/*------------------------------------------*/
#leave-comment h4.h4-lg {
  margin-bottom: 5px;
}
.comment-form {
  position: relative;
}
.comment-form p {
  color: #333;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 5px;
  display: block;
}
.comment-form .form-control {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  height: 46px;
  font-size: 15px;
  font-weight: 300;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: none;
}
.comment-form .form-control:focus {
  border-color: #48af4b;
  outline: 0px none;
  box-shadow: none;
}
.comment-form textarea {
  min-height: 200px;
}
/*------------------------------------------*/
/*    Forms Message
/*------------------------------------------*/
.comment-form label.error {
  color: #f6412d;
  font-size: 0.95rem;
  line-height: 1;
  margin-bottom: 20px;
}
.comment-form-msg {
  position: absolute;
  bottom: 10px;
  left: 350px;
}
.comment-form-msg .error,
.comment-form-msg .loading {
  color: #45afff;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 600;
}
.comment-form-msg .error {
  color: #f6412d;
}
/* ==========================================================================
  23.  NEWSLETTER
  =========================================================================== */
.newsletter-section.bg-image {
  background-image: url("newsletter.7ab1ab03.jpg");
}
.newsletter-section.bg-purple,
.newsletter-section.bg-deeppurple {
  background-image: url("tra-bg.7c8eb794.png");
}
/*------------------------------------------*/
/*    Newsletter Form Input
/*------------------------------------------*/
#newsletter-1 .form-control {
  height: 60px;
  font-weight: 300;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
  color: #fff;
  font-size: 1.35rem;
  padding: 0 0.5rem;
  margin-right: 0;
  border-radius: 0;
  box-shadow: 0 0;
  transition: all 400ms ease-in-out;
}
/*------------------------------------------*/
/*    Newsletter Form Input Focus
/*------------------------------------------*/
#newsletter-1 .form-control:focus {
  border-color: #fff;
  outline: 0;
  box-shadow: none;
}
/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/
.newsletter-section .newsletter-form .form-control::-moz-placeholder {
  color: #ddd;
}
.newsletter-section .newsletter-form .form-control:-ms-input-placeholder {
  color: #ddd;
}
.newsletter-section .newsletter-form .form-control::-webkit-input-placeholder {
  color: #ddd;
}
/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/
#newsletter-1 .newsletter-form .btn {
  height: 60px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 1.25rem;
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: 0 0;
}
/*------------------------------------------*/
/*    Newsletter Form Notification
/*------------------------------------------*/
.newsletter-section .form-notification {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  margin: 20px auto 0;
}
/* ==========================================================================
  24. BOOKING FORM
  ========================================================================== */
#booking {
  background: url("tra-bg1.96771b8a.png");
}
#booking-form {
  margin: 0 25px;
}
/*------------------------------------------*/
/*    Register Form Input
/*------------------------------------------*/
#booking-form .form-control {
  height: 54px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 1rem;
  padding: 5px 15px;
  margin-bottom: 20px;
  border-radius: 0;
  transition: all 300ms ease-in-out;
}
/*------------------------------------------*/
/*    Bookingr Form Placeholder
/*------------------------------------------*/
#booking-form .form-control::-moz-placeholder {
  color: #aaa;
}
#booking-form .form-control:-ms-input-placeholder {
  color: #aaa;
}
#booking-form .form-control::-webkit-input-placeholder {
  color: #aaa;
}
/*------------------------------------------*/
/*    Register Form Input Focus
/*------------------------------------------*/
#booking-form .form-control:focus {
  border-color: #64b671;
  outline: 0;
  box-shadow: none;
}
/*------------------------------------------*/
/*   Register Form Submit Button
/*------------------------------------------*/
#booking-form .btn {
  width: 100%;
  height: 54px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0;
}
/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/
.booking-form-msg {
  margin-top: 20px;
}
.booking-form-msg .loading {
  font-size: 1.1875rem;
  line-height: 1;
  font-weight: 400;
}
.error {
  color: #fe4918;
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 300;
}
/* ==========================================================================
  25.  CONTACTS
  =========================================================================== */
.bg-clouds {
  width: 100%;
  background-image: url("clouds.8de0ed1f.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
}
#contacts-1.bg-clouds {
  padding-bottom: 240px;
}
#contacts-2.bg-clouds {
  padding-bottom: 170px;
}
#contacts-3.bg-clouds {
  padding-bottom: 120px;
}
.contacts-section .form-holder {
  position: relative;
}
#contacts-map {
  margin-top: 70px;
}
/*------------------------------------------*/
/*    CONTACT BOX
/*------------------------------------------*/
#contacts-1 .contact-box {
  text-align: center;
  margin-bottom: 30px;
}
#contacts-2 .contact-box {
  margin-bottom: 10px;
}
#contacts-3 .contact-box,
#contacts-5 .contact-box {
  margin-bottom: 40px;
}
/*------------------------------------------*/
/*    CONTACTS TYPOGRAPHY
/*------------------------------------------*/
.contact-box .svg-inline--fa {
  font-size: 3.5rem;
}
.contacts-section h4.h4-xs {
  margin-bottom: 15px;
}
.contacts-section h3.h3-lg {
  margin-bottom: 25px;
}
.contact-box h5 {
  margin-top: 20px;
  margin-bottom: 12px;
}
#contacts-2 .contact-box h5,
#contacts-3 .contact-box h5 {
  margin-top: 0;
  margin-bottom: 10px;
}
p.support-number-txt {
  margin-top: 45px;
  margin-bottom: 5px;
}
span.support-number {
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  display: block;
}
.contact-box p {
  font-size: 1.05rem;
  font-weight: 400;
  margin-bottom: 0;
}
.bg-dark.contacts-section p,
.bg-deepdark.contacts-section p,
.bg-dark .contact-box a:hover,
.bg-deepdark .contact-box a:hover {
  color: #ccc;
}
.contact-box a {
  text-decoration: underline;
}
.bg-dark .contact-box a,
.bg-deepdark .contact-box a {
  color: #fff;
}
.contact-box a:hover {
  color: #000;
}
/*------------------------------------------*/
/*    CONTACT FORM
/*------------------------------------------*/
.contact-form .form-control {
  height: 52px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 1rem;
  font-weight: 300;
  padding: 0 20px;
  margin-bottom: 22px;
  border-radius: 6px;
  transition: all 400ms ease-in-out;
}
.bg-dark .contact-form .form-control {
  background-color: rgba(220, 220, 220, 0.2);
  color: #fff;
  border-color: rgba(220, 220, 220, 0.2);
}
.bg-deepdark .contact-form .form-control {
  background-color: rgba(50, 50, 50, 0.7);
  color: #fff;
  border-color: rgba(50, 50, 50, 0.7);
}
.bg-clouds .contact-form .form-control,
.bg-lightgrey .contact-form .form-control {
  background-color: #fcfcfc;
  border-color: #ccc;
}
/*------------------------------------------*/
/*    Contact Form Textarea
/*------------------------------------------*/
.contact-form textarea {
  min-height: 200px;
}
.contact-form textarea.form-control {
  padding: 20px;
}
/*------------------------------------------*/
/*    Contact Form Placeholder
/*------------------------------------------*/
.contact-form .form-control::-moz-placeholder {
  color: #555;
}
.contact-form .form-control:-ms-input-placeholder {
  color: #555;
}
.contact-form .form-control::-webkit-input-placeholder {
  color: #555;
}
.bg-dark .contact-form .form-control::-moz-placeholder {
  color: #ccc;
}
.bg-dark .contact-form .form-control:-ms-input-placeholder {
  color: #ccc;
}
.bg-dark .contact-form .form-control::-webkit-input-placeholder {
  color: #ccc;
}
.bg-deepdark .contact-form .form-control::-moz-placeholder {
  color: #999;
}
.bg-deepdark .contact-form .form-control:-ms-input-placeholder {
  color: #999;
}
.bg-deepdark .contact-form .form-control::-webkit-input-placeholder {
  color: #999;
}
/*------------------------------------------*/
/*    Contact Form Input Focus
/*------------------------------------------*/
.contact-form .form-control:focus,
.bg-lightgrey .contact-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
  border-color: #ff3366;
}
.bg-dark .contact-form .form-control:focus {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: #fff;
}
.bg-deepdark .contact-form .form-control:focus {
  background-color: #1d1d1d;
  border-color: #ccc;
}
/*------------------------------------------*/
/*    Contact Form Message
/*------------------------------------------*/
.contact-form label.error {
  color: #f6412d;
  font-size: 0.95rem;
  line-height: 1;
  margin-bottom: 27px;
}
#contacts-2 .contact-form label.error {
  margin-left: 5px;
  margin-bottom: 30px;
}
.contact-form-msg {
  margin-top: 30px;
  margin-left: 12px;
}
.hero-form .contact-form-msg {
  display: inline-block;
  margin: 20px auto 0;
}
.contact-form-msg .error,
.contact-form-msg .loading {
  color: #45afff;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 600;
}
.contact-form-msg .error {
  color: #f6412d;
}
/*------------------------------------------*/
/*  CONTACTS GOOGLE MAP
/*------------------------------------------*/
#gmap {
  width: 100%;
  height: 400px;
}
#contacts-2 #gmap {
  height: 375px;
}
#contacts-5 #gmap,
#contacts-map #gmap {
  width: 100%;
  height: 480px;
}
/* ==========================================================================
  26. FOOTER
  =========================================================================== */
.footer {
  padding-top: 80px;
}
.footer.bg-dark {
  padding-top: 100px;
}
/*------------------------------------------*/
/*   FOOTER TYPOGRAPHY
/*------------------------------------------*/
.footer h4,
.footer h5 {
  margin-bottom: 25px;
}
.footer p.p-lg {
  font-weight: 600;
  margin-bottom: 3px;
}
.footer p.p-lg a {
  text-decoration: underline;
}
.footer-copyright p {
  margin-bottom: 0;
}
.footer-copyright p span {
  font-weight: 700;
}
.bg-dark .footer-form p,
.bg-deepdark .footer-form p,
.bg-dark .footer-info p,
.bg-deepdark .footer-info p,
.bg-dark .footer-copyright p,
.bg-deepdark .footer-copyright p,
.bg-dark .footer-copyright p,
.bg-deepdark .footer-links li a {
  color: #ccc;
}
.bg-dark .footer-copyright p span,
.bg-deepdark .footer-copyright p span {
  color: #fff;
}
/*------------------------------------------*/
/*   FOOTER LINKS
/*------------------------------------------*/
.footer-links li {
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 0 10px 0;
  padding: 0;
}
.footer-links li a {
  font-size: 1rem;
}
.footer-links li a:hover {
  color: #000;
  text-decoration: underline;
}
.bg-dark .footer-links li a:hover,
.bg-deepdark .footer-links li a:hover {
  color: #fff;
}
/*------------------------------------------*/
/*   FOOTER SOCIAL LINKS
/*------------------------------------------*/
.foo-links {
  display: inline-block;
  padding-left: 0;
  margin: 20px auto 0;
}
.foo-links li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 0 8px 0;
  padding: 0;
}
.foo-links a {
  width: 40px;
  height: 40px;
  color: #888;
  border: 2px solid rgba(240, 240, 240, 0.05);
  background-color: rgba(200, 200, 200, 0.45);
  font-size: 1.1rem;
  line-height: 36px !important;
  margin-right: 4px;
  display: block;
  border-radius: 100%;
  transition: all 400ms ease-in-out;
}
.bg-dark .foo-links a,
.bg-deeppurple .foo-links a {
  color: #ccc;
  background-color: rgba(220, 220, 220, 0.2);
  border: 1px solid transparent;
}
.bg-deepdark .foo-links a {
  color: #999;
  background-color: rgba(50, 50, 50, 0.7);
  border: 1px solid rgba(50, 50, 50, 0.7);
}
.foo-links a:hover {
  color: #fff;
}
.foo-links a.ico-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}
.foo-links a.ico-twitter:hover {
  background-color: #00a9ed;
  border-color: #00a9ed;
}
.foo-links a.ico-instagram:hover {
  background-color: #e44772;
  border-color: #e44772;
}
.foo-links a.ico-dribbble:hover {
  background-color: #d92d84;
  border-color: #d92d84;
}
.foo-links a.ico-behance:hover {
  background-color: #2473f6;
  border-color: #2473f6;
}
.foo-links a.ico-pinterest:hover {
  background-color: #ac281a;
  border-color: #ac281a;
}
.foo-links a.ico-linkedin:hover {
  background-color: #015886;
  border-color: #015886;
}
.foo-links a.ico-google-plus:hover {
  background-color: #cd1111;
  border-color: #cd1111;
}
.foo-links a.ico-youtube:hover {
  background-color: #cd1b20;
  border-color: #cd1b20;
}
.foo-links a.ico-tumblr:hover {
  background-color: #3a5976;
  border-color: #3a5976;
}
.foo-links a.ico-vk:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}
/*------------------------------------------*/
/*   FOOTER NEWSLETTER FORM
/*------------------------------------------*/
.footer-form .form-control {
  border-radius: 6px 0 0 6px;
  transition: all 400ms ease-in-out;
}
.bg-lightgrey .footer-form .form-control {
  background-color: #f7f7f7;
  border: 2px solid #bbb;
  border-right: none;
}
.bg-dark .footer-form .form-control {
  background-color: rgba(220, 220, 220, 0.2);
  color: #fff;
  border: 2px solid rgba(220, 220, 220, 0.2);
  border-right: none;
}
.bg-deepdark .footer-form .form-control {
  background-color: rgba(50, 50, 50, 0.7);
  color: #fff;
  border: 2px solid rgba(50, 50, 50, 0.7);
  border-right: none;
}
/*------------------------------------------*/
/*    Newsletter Form Input Focus
/*------------------------------------------*/
.footer-form .form-control:focus {
  border: 1px solid #888;
  border-right: none;
  outline: 0;
  box-shadow: none;
}
.bg-lightgrey .footer-form .form-control:focus {
  background-color: #fff;
  border: 2px solid #999;
  border-right: none;
}
.bg-dark .footer-form .form-control:focus {
  background-color: transparent;
  border: 2px solid #ccc;
  border-right: none;
}
.bg-deepdark .footer-form .form-control:focus {
  background-color: #1d1d1d;
  border: 2px solid #ccc;
  border-right: none;
}
/*------------------------------------------*/
/*    Newsletter Form Placeholder
/*------------------------------------------*/
.bg-dark .footer-form .form-control::-moz-placeholder {
  color: #ccc;
}
.bg-dark .footer-form .form-control:-ms-input-placeholder {
  color: #ccc;
}
.bg-dark .footer-form .form-control::-webkit-input-placeholder {
  color: #ccc;
}
.bg-deepdark .footer-form .form-control::-moz-placeholder {
  color: #999;
}
.bg-deepdark .footer-form .form-control:-ms-input-placeholder {
  color: #999;
}
.bg-deepdark .footer-form .form-control::-webkit-input-placeholder {
  color: #999;
}
/*------------------------------------------*/
/*    Newsletter Form Button
/*------------------------------------------*/
.footer-form .btn {
  padding: 0.7rem 1.45rem;
  border-radius: 0 6px 6px 0;
}
.footer-form .svg-inline--fa {
  font-size: 1.35rem;
  line-height: 1 !important;
}
/*------------------------------------------*/
/*    Newsletter Form Notification
/*------------------------------------------*/
.footer-form .form-notification {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}
/*------------------------------------------*/
/*   BOTTOM FOOTER
/*------------------------------------------*/
.bottom-footer {
  padding-top: 30px;
  padding-bottom: 50px;
}
.bg-dark .bottom-footer {
  padding-bottom: 70px;
}
/* ==========================================================================
  27. BOTTOM QUICK FORM
  =========================================================================== */
.bottom-form {
  background-color: #fff;
  min-width: 260px;
  max-width: 260px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.33);
  position: fixed;
  right: 6em;
  bottom: 0;
  z-index: 99999;
  border: none;
  text-shadow: none;
  box-shadow: none;
  outline: 0px none;
}
/*------------------------------------------*/
/*   QUICK FORM HEADER
/*------------------------------------------*/
.bottom-form-header {
  padding: 14px 20px;
  min-height: 45px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  border: none;
  text-shadow: none;
  box-shadow: none;
  outline: 0px none;
}
.bottom-form-header .svg-inline--fa {
  text-align: center;
  color: #fff;
  font-size: 23px;
  line-height: 1;
  display: inline-block;
  float: left;
  margin-top: 1px;
  margin-right: 8px;
}
.bottom-form-header p {
  color: #fff;
  font-size: 0.95rem;
  line-height: 23px !important;
  display: inline-block;
  margin-bottom: 0;
}
/*------------------------------------------*/
/*   QUICK FORM FORM
/*------------------------------------------*/
.bottom-form-holder {
  height: 311px;
  padding-bottom: 30px;
  border: 1px solid #eee;
  border-top: none;
}
.bottom-form-control {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  font-size: 0.9rem;
  line-height: 1;
  padding: 18px;
}
.quick-contact-form textarea {
  min-height: 60px;
  border-bottom: none;
}
.quick-contact-form .btn {
  font-size: 0.9rem;
  padding: 0.6rem 1.75rem;
  margin-right: 30px;
}
/*------------------------------------------*/
/*    Quick Form Message
/*------------------------------------------*/
.quick-contact-form label.error {
  color: #f6412d;
  font-size: 0.95rem;
  line-height: 1;
  margin-bottom: 20px;
  margin-left: 35px;
}
.quick-contact-form .contact-form-msg {
  position: absolute;
  bottom: 80px;
  left: 20px;
}
.quick-contact-form .contact-form-msg .error,
.quick-contact-form .contact-form-msg .loading {
  color: #45afff;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 300;
}
.quick-contact-form .contact-form-msg .error {
  color: #f6412d;
}
/* ==========================================================================
  28 INNER PAGE HERO
  =========================================================================== */
.page-hero-section {
  padding-top: 200px;
  padding-bottom: 140px;
}
#about-hero {
  background-image: url("about-hero.522d5407.jpg");
}
#services-hero {
  background-image: url("services-hero.522d5407.jpg");
}
#team-hero {
  background-image: url("team-hero.522d5407.jpg");
}
#pricing-hero {
  background-image: url("pricing-hero.522d5407.jpg");
}
#blog-listing-hero {
  background-image: url("blog-listing-hero.522d5407.jpg");
}
/*------------------------------------------*/
/*   INNER PAGE HERO TYPOGRAPHY
/*------------------------------------------*/
.page-hero-section h2 {
  font-size: 4.5rem;
  letter-spacing: -0.5px;
}
.page-hero-section p {
  font-weight: 400;
  padding: 0 12%;
  margin-top: 15px;
  margin-bottom: 0;
}
/* ==========================================================================
  29.  SCROLL TO TOP
  =========================================================================== */
#scrollUp {
  display: none;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-image: url("back-to-top.12145f49.png");
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(35, 35, 35, 0.5);
  border-radius: 100%;
  transition: all 250ms linear;
}
#scrollUp:hover {
  background-color: #666;
}
nav a#pull {
  display: none;
}
/*
  Template Name: "Pergo - A Multipurpose Landing Pages Pack for Startups, Creatives and Freelancers
  Theme URL: https://themeforest.net/user/dsathemes
  Description: "Pergo - A Multipurpose Landing Pages Pack for Startups, Creatives and Freelancers
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.1.0
  Website: www.dsathemes.com
  Tags: Responsive, HTML5 template, DSAThemes, One Page, Landing, Startup, Business, Company, Corporate, Creative, Freelancers
*/
/* ------------------------------------------ */
/*   TABLE OF CONTENTS
/* ------------------------------------------ */
/*   01 - GENERAL & BASIC STYLES   */
/*   02 - TYPOGRAPHY               */
/*   03 - PRELOAD SPINNER          */
/*   04 - HEADER & NAVIGATION      */
/*   05 - HERO                     */
/*   06 - ABOUT                    */
/*   07 - SERVICES                 */
/*   08 - CONTENT                  */
/*   09 - BANNER                   */
/*   10 - VIDEO                    */
/*   11 - PORTFOLIO                */
/*   12 - TEAM                     */
/*   13.  SEARCH                   */
/*   14.  SCHEDULE                 */
/*   15.  STATISTIC                */
/*   16.  PRICING TABLES           */
/*   17.  MORE ITEMS               */
/*   18.  TESTIMONIALS             */
/*   19.  BRANDS                   */
/*   20.  FAQs                     */
/*   21.  CALL TO ACTION           */
/*   22.  BLOG                     */
/*   23.  NEWSLETTER               */
/*   24.  BOOKING FORM             */
/*   25.  CONTACTS                 */
/*   26.  FOOTER                   */
/*   27.  BOTTOM QUICK FORM        */
/*   28   INNER PAGE HERO          */
/*   29.  SCROLL TO TOP            */
/*
  Template Name: Pergo - A Multipurpose Landing Pages Pack for Startups, Creatives and Freelancers
  Theme URL: https://themeforest.net/user/dsathemes
  Description: Pergo - A Multipurpose Landing Pages Pack for Startups, Creatives and Freelancers
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.0.0
  Website: www.dsathemes.com
  Tags: Responsive, HTML5 template, DSAThemes, One Page, Landing, Startup, Business, Company, Corporate, Creative, Freelancers
*/
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 1921px) {
  /* HERO */
  .hero-section .hero-slider {
    height: 860px;
  }

  .hero-14-img {
    top: 5%;
    right: -8%;
    width: 50%;
  }

  .content-right-img {
    top: 2%;
    right: -3%;
    width: 50%;
  }

  .content-left-img {
    top: 2%;
    left: -3%;
    width: 50%;
  }

  /* CONTACTS */
  #contacts-1.bg-clouds {
    padding-bottom: 240px;
  }

  #contacts-2.bg-clouds {
    padding-bottom: 170px;
  }

  #contacts-3.bg-clouds {
    padding-bottom: 160px;
  }

  /* INNER PAGE */
  .page-hero-section {
    padding-top: 230px;
    padding-bottom: 220px;
  }

  #contacts-map #gmap {
    height: 585px;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 992px) and (max-width: 1199.99px) {
  html {
    font-size: 15px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  .ind-5,
.ind-10,
.ind-15,
.ind-20,
.ind-25 {
    padding-right: 0;
    padding-left: 0;
  }

  .ind-30,
.ind-35,
.ind-40 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .ind-45,
.ind-50 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .ind-60 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .p-top-100,
.division.p-top-100 {
    padding-top: 80px;
  }

  .p-bottom-100 {
    padding-bottom: 80px;
  }

  .p-left-0,
.p-left-5,
.p-left-10,
.p-left-15 {
    padding-left: 0;
  }

  .p-left-20,
.p-left-25,
.p-left-30,
.p-left-35 {
    padding-left: 10px;
  }

  .p-left-40,
.p-left-45,
.p-left-50 {
    padding-left: 20px;
  }

  .p-left-50,
.p-left-60,
.p-left-70,
.p-left-80,
.p-left-90,
.p-left-100 {
    padding-left: 30px;
  }

  .p-right-0,
.p-right-5,
.p-right-10,
.p-right-15 {
    padding-right: 0;
  }

  .p-right-20,
.p-right-25,
.p-right-30,
.p-right-35 {
    padding-right: 10px;
  }

  .p-right-40,
.p-right-45,
.p-right-50 {
    padding-right: 20px;
  }

  .p-right-50,
.p-right-60,
.p-right-70,
.p-right-80,
.p-right-90,
.p-right-100 {
    padding-left: 30px;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.066rem;
  }

  /* 16px */
  h5.h5-sm {
    font-size: 1.111rem;
  }

  /* 16.665px */
  h5.h5-md {
    font-size: 1.14rem;
  }

  /* 17.1px */
  h5.h5-lg {
    font-size: 1.222rem;
  }

  /* 18.33px */
  h5.h5-xl {
    font-size: 1.333rem;
  }

  /* 19.95px */
  /* Header H4 */
  h4.h4-xs {
    font-size: 1.44rem;
  }

  /* 21.6px */
  h4.h4-sm {
    font-size: 1.55rem;
  }

  /* 23.25px */
  h4.h4-md {
    font-size: 1.66rem;
  }

  /* 24.9px */
  h4.h4-lg {
    font-size: 1.77rem;
  }

  /* 26.55px */
  h4.h4-xl {
    font-size: 1.88rem;
  }

  /* 28.2px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 2.11rem;
  }

  /* 31.65px */
  h3.h3-sm {
    font-size: 2.22rem;
  }

  /* 33.3px */
  h3.h3-md {
    font-size: 2.33rem;
  }

  /* 34.95px */
  h3.h3-lg {
    font-size: 2.44rem;
  }

  /* 36.6px */
  h3.h3-xl {
    font-size: 2.66rem;
  }

  /* 39.9px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 2.88rem;
  }

  /* 43.2px */
  h2.h2-sm {
    font-size: 3rem;
  }

  /* 45px */
  h2.h2-md {
    font-size: 3.11rem;
  }

  /* 46.65px */
  h2.h2-lg {
    font-size: 3.33rem;
  }

  /* 49.95px */
  h2.h2-xl {
    font-size: 3.55rem;
  }

  /* 53.25px */
  /* Paragraphs */
  p.p-sm {
    font-size: 0.95rem;
  }

  /* 14.25px */
  p {
    font-size: 1rem;
  }

  /* 15px */
  p.p-md {
    font-size: 1.066rem;
  }

  /* 16px */
  p.p-lg {
    font-size: 1.133rem;
  }

  /* 17px */
  p.p-xl {
    font-size: 1.266rem;
  }

  /* 19px */
  /* Buttons */
  .btn.btn-sm {
    font-size: 0.9rem;
    padding: 0.8rem 1.75rem;
  }

  .btn {
    font-size: 0.95rem;
    padding: 0.875rem 1.9rem;
  }

  .btn.btn-md {
    font-size: 1rem;
    padding: 0.95rem 2.25rem;
  }

  .btn.btn-lg {
    font-size: 1.05rem;
    padding: 1rem 2.35rem;
  }

  .btn-arrow:hover span,
.btn-arrow:focus span {
    transform: translate3d(-8px, 0, 0);
  }

  .btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
    opacity: 1;
    right: -17px;
  }

  /* Video Popup Link */
  .modal-video {
    line-height: 2rem;
  }

  .modal-video a span {
    font-size: 2rem;
    line-height: 2rem;
    margin-right: 3px;
  }

  .modal-video a {
    line-height: 2rem;
  }

  /* Video Icon */
  .video-preview .video-btn-xs {
    margin-top: -35px;
    margin-left: -35px;
  }

  .video-preview .video-btn-sm {
    margin-top: -40px;
    margin-left: -40px;
  }

  .video-preview .video-btn-md {
    margin-top: -45px;
    margin-left: -45px;
  }

  .video-preview .video-btn-lg {
    margin-top: -55px;
    margin-left: -55px;
  }

  .video-btn-xs,
.video-btn .video-btn-xs {
    width: 100px;
    height: 100px;
  }

  .video-btn-sm,
.video-btn .video-btn-sm {
    width: 110px;
    height: 110px;
  }

  .video-btn-md,
.video-btn .video-btn-md {
    width: 120px;
    height: 120px;
  }

  .video-btn-lg,
.video-btn .video-btn-lg {
    width: 140px;
    height: 140px;
  }

  .video-btn-xs .svg-inline--fa {
    width: 70px;
    height: 70px;
  }

  .video-btn-sm .svg-inline--fa {
    width: 80px;
    height: 80px;
  }

  .video-btn-md .svg-inline--fa {
    width: 90px;
    height: 90px;
  }

  .video-btn-lg .svg-inline--fa {
    width: 110px;
    height: 110px;
  }

  .ico-bkg {
    top: 35px;
    left: 35px;
  }

  .video-btn-xs .ico-bkg {
    top: 30px;
    width: 34px;
    height: 40px;
  }

  .video-btn-sm .ico-bkg {
    top: 30px;
    width: 40px;
    height: 45px;
  }

  .video-btn-md .ico-bkg {
    width: 50px;
    height: 50px;
  }

  .video-btn-lg .ico-bkg {
    top: 40px;
    width: 65px;
    height: 60px;
  }

  /* Box Icons */
  .box-icon [class^=flaticon-]:before,
.box-icon [class*=" flaticon-"]:before,
.box-icon [class^=flaticon-]:after,
.box-icon [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-sm [class^=flaticon-]:before,
.box-icon-sm [class*=" flaticon-"]:before,
.box-icon-sm [class^=flaticon-]:after,
.box-icon-sm [class*=" flaticon-"]:after {
    font-size: 3.25rem;
    line-height: 3.25rem !important;
  }

  .box-icon-md [class^=flaticon-]:before,
.box-icon-md [class*=" flaticon-"]:before,
.box-icon-md [class^=flaticon-]:after,
.box-icon-md [class*=" flaticon-"]:after {
    font-size: 4rem;
    line-height: 4rem !important;
  }

  .box-icon-lg [class^=flaticon-]:before,
.box-icon-lg [class*=" flaticon-"]:before,
.box-icon-lg [class^=flaticon-]:after,
.box-icon-lg [class*=" flaticon-"]:after {
    font-size: 4.5rem;
    line-height: 4.5rem !important;
  }

  /* Technologies */
  .technologies {
    margin-top: 30px;
  }

  .technologies p {
    font-size: 0.85rem;
  }

  .technologies .svg-inline--fa {
    font-size: 2.5rem;
    margin-right: 10px;
  }

  /* Store Badges */
  a.store {
    margin-right: 10px;
  }

  .store img.appstore {
    width: 141px;
    height: 44px;
  }

  .store img.googleplay {
    width: 150px;
    height: 44px;
  }

  .store img.amazon {
    width: 139px;
    height: 44px;
  }

  .store img.windows {
    width: 144px;
    height: 44px;
  }

  /* Countdown */
  #clock .cbox {
    padding: 0 15px;
  }

  #clock span.cbox-digit {
    font-size: 3.75rem;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1.2rem;
    padding: 0 10%;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
   /*------------------------------------------*/
  .navbar-brand {
    margin-right: 3rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1rem;
    padding: 0.65rem 1.25rem;
  }

  .header-socials {
    margin-top: 3px;
    margin-left: 0;
  }

  .header-socials a {
    width: 36px;
    height: 36px;
    font-size: 0.95rem;
    line-height: 32px !important;
    margin-left: 2px;
  }

  .navbar .btn {
    font-size: 0.95rem;
    padding: 0.785rem 2rem;
    margin-top: 0;
    margin-left: 10px;
  }

  .navbar-text.phone-number {
    font-size: 1.05rem;
    margin-left: 5px;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  #hero-1 {
    padding-top: 200px;
    padding-bottom: 170px;
  }

  #hero-3 {
    padding-top: 200px;
    padding-bottom: 150px;
  }

  #hero-4 {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  #hero-5 {
    padding-top: 160px;
    padding-bottom: 80px;
  }

  #hero-6-content {
    padding-top: 160px;
    padding-bottom: 380px;
  }

  #hero-8 {
    padding-top: 150px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 220px;
    padding-bottom: 180px;
  }

  #hero-11-txt {
    padding-top: 170px;
    padding-bottom: 430px;
  }

  #hero-12 {
    padding-top: 210px;
  }

  #hero-13-txt {
    padding-top: 210px;
    padding-bottom: 80px;
  }

  #hero-14 {
    padding-top: 230px;
    padding-bottom: 100px;
  }

  #hero-15-txt {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  #hero-16 {
    padding-top: 120px;
  }

  #hero-17 {
    padding-top: 160px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 160px;
  }

  #hero-19-txt {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  #hero-20 {
    padding-top: 200px;
    padding-bottom: 110px;
  }

  #hero-21 {
    padding-top: 160px;
    padding-bottom: 100px;
  }

  #hero-22 {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  #hero-23 {
    padding-top: 200px;
    padding-bottom: 140px;
  }

  #hero-24-content {
    padding-top: 160px;
    padding-bottom: 180px;
  }

  #hero-25 {
    padding-top: 160px;
    padding-bottom: 100px;
  }

  #hero-26 {
    padding-top: 200px;
    padding-bottom: 130px;
  }

  #hero-27 {
    padding-top: 160px;
    padding-bottom: 100px;
  }

  #hero-28 {
    padding-top: 200px;
    padding-bottom: 40px;
  }

  #hero-29 {
    padding-top: 240px;
    padding-bottom: 120px;
  }

  #hero-30 {
    padding-top: 160px;
    padding-bottom: 80px;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 720px;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 90%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 20px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 25px;
  }

  #hero-1.hero-section h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin-bottom: 35px;
  }

  .slides h2 {
    font-size: 4.25rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-9 .slides h2 {
    font-size: 4.75rem;
  }

  #hero-4.hero-section h2 {
    font-size: 3.75rem;
  }

  #hero-7.hero-section h2 {
    font-size: 4.75rem;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-10.hero-section h2 {
    font-size: 5rem;
  }

  #hero-16 h2 {
    font-size: 5rem;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  #hero-18 h3.h3-xl {
    margin-bottom: 35px;
  }

  #hero-19 .hero-txt h2 {
    font-size: 4.5rem;
    margin-bottom: 25px;
  }

  #hero-20 h4 {
    padding: 4px 15px;
  }

  #hero-20 h2 {
    font-size: 5rem;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 3.75rem;
    margin-bottom: 25px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 3.75rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 3.75rem;
    margin-bottom: 25px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 9rem;
    letter-spacing: -4px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 2.35rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 4.75rem;
    margin-bottom: 15px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-price h2 {
    font-size: 5em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 4rem;
  }

  #hero-29 .hero-txt h2 {
    font-size: 4.75em;
    margin-bottom: 25px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 3em;
    margin-top: 50px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0 10%;
    margin-bottom: 25px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1.25rem;
    margin-bottom: 35px;
  }

  .hero-inner-txt p {
    margin-bottom: 35px;
  }

  #hero-14 .hero-txt p {
    padding-right: 10%;
  }

  #hero-16 .hero-txt p {
    padding-left: 5%;
  }

  #hero-18 .hero-txt p {
    padding: 0 5%;
  }

  #hero-19 .hero-txt p {
    padding-right: 15%;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding-right: 10%;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin-top: -420px;
  }

  /* Hero Statistic */
  .hero-statistic {
    padding: 40px 20px 10px 20px;
  }

  .hero-number {
    font-size: 2rem;
    margin-bottom: 5px;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin-top: -280px;
    padding: 45px 30px;
  }

  .discount-banner h2.h2-xl {
    font-size: 5rem;
  }

  /* Hero Register Form */
  .hero-form form {
    margin-right: 15px;
  }

  #hero-24 .hero-form form {
    padding: 50px 40px 50px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 0 15%;
  }

  .hero-section .newsletter-form .form-control {
    height: 50px;
    font-size: 1.1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 50px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -330px;
  }

  .hero-14-img {
    top: 0;
    right: -45%;
    width: 85%;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -100px;
  }

  #hero-28-boxes {
    margin-top: 80px;
  }

  .hbox-1 {
    padding: 45px 30px;
  }

  .hbox-1 h5 {
    margin-bottom: 15px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* INNER PAGE */
  .page-hero-section {
    padding-top: 170px;
    padding-bottom: 90px;
  }

  .page-hero-section h2 {
    font-size: 3.5rem;
    letter-spacing: -0.5px;
  }

  .page-hero-section p {
    padding: 0 12%;
    margin-top: 8px;
  }

  /* ABOUT */
  .about-txt h3.h3-sm {
    margin-bottom: 25px;
  }

  .about-form {
    margin-top: -200px;
  }

  .about-form form {
    padding: 55px 30px 40px;
  }

  /* SERVICES */
  #services-1.wide-20 {
    padding-bottom: 40px;
  }

  .services-txt h3.h3-sm {
    margin-bottom: 25px;
  }

  .sbox-1 {
    margin-bottom: 40px;
  }

  .sbox-2 {
    padding: 0 10px;
  }

  .sbox-4 {
    margin-bottom: 35px;
  }

  .g-sboxes {
    padding-right: 30px;
  }

  .sbox-1-txt,
.sbox-3-txt {
    padding-left: 20px;
  }

  .sbox-4-txt {
    padding-left: 20px;
  }

  .sbox-2 h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  /* CONTENT */
  #content-3,
#content-4 {
    padding-top: 90px;
    padding-bottom: 50px;
  }

  #content-7-title {
    padding-top: 80px;
  }

  #content-9 .content-txt {
    padding: 0 25px 0 60px;
  }

  #content-10 .content-txt {
    padding: 0 60px 0 25px;
  }

  .content-txt h2.h2-huge {
    font-size: 4rem;
    margin-bottom: 25px;
  }

  .content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
    margin-bottom: 25px;
  }

  .content-txt h5.h5-sm {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  #content-10 p.p-lg.m-bottom-25 {
    margin-bottom: 15px;
  }

  .content-btn {
    margin-top: 30px;
  }

  ul.content-list li {
    margin-bottom: 6px;
  }

  .skills.m-top-40 {
    margin-top: 35px;
  }

  .content-right-img {
    top: 0;
    right: -42%;
    width: 85%;
  }

  .content-left-img {
    top: 0;
    left: -42%;
    width: 85%;
  }

  /* BANNER */
  #banner-1,
#banner-4 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #banner-2 {
    padding-top: 80px;
  }

  #banner-2 .banner-txt h2 {
    margin-bottom: 25px;
  }

  #banner-1 .banner-txt h2,
#banner-3 .banner-txt h3 {
    margin-bottom: 20px;
  }

  /* VIDEO */
  #video-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #video-2 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  #video-3 p {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0 5%;
  }

  /* PORTFOLIO */
  .project-description h5.h5-sm {
    margin-top: 2px;
  }

  .project-description span {
    font-size: 0.95rem;
    font-weight: 500;
  }

  /* STATISTIC */
  #statistic-2 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .small-statistic {
    margin-top: 20px;
  }

  .statistic-number {
    font-size: 3.65rem;
    letter-spacing: 2px;
    margin-bottom: 14px;
  }

  .small-statistic .statistic-number {
    font-size: 4.25rem;
  }

  /* PPRICING */
  #pills-tab {
    margin: 20px auto 30px;
  }

  .nav-pills .nav-link {
    font-size: 0.95rem;
    padding: 8px 26px;
  }

  .pricing-table span.price {
    font-size: 62px;
    line-height: 1;
  }

  #pricing-3 .price span {
    font-size: 58px;
  }

  .pricing-table sup {
    font-size: 45px;
    top: -5px;
    right: 2px;
  }

  #pricing-3 .pricing-table .price sup {
    font-size: 36px;
    margin-right: 3px;
  }

  p.validity {
    font-size: 1.1rem;
    margin-top: 5px;
  }

  #pricing-3 .price span.price-vat {
    font-size: 0.925rem;
    padding: 0;
  }

  .pricing-table ul.features li {
    font-size: 1rem;
    padding: 10px 0;
  }

  .pricing-txt {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .pricing-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 25px;
  }

  /* TESTIMONIALS */
  .review-1 {
    padding: 0 10px;
    margin-bottom: 30px;
  }

  .review-2 {
    margin-bottom: 60px;
  }

  .review-1 .review-txt {
    padding: 40px 30px 50px;
  }

  .review-1 .testimonial-avatar img,
#reviews-2 img {
    width: 70px;
    height: 70px;
  }

  .review-1 p {
    font-size: 1rem;
  }

  #reviews-2 .review-txt p {
    font-size: 1.45rem;
    padding: 20px 10% 20px;
  }

  /* Schedule */
  .nav-tabs {
    margin-bottom: 50px;
  }

  .nav-tabs .nav-link {
    font-size: 1.05rem;
    padding: 0.75rem 0;
  }

  /* Search */
  #search-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .domain-names {
    margin-top: 25px;
  }

  .dn-box {
    padding: 15px 10px;
  }

  .search-form .form-control {
    height: 54px;
  }

  .search-form .btn {
    height: 54px;
    font-size: 1.05rem;
  }

  /* BRANDS */
  #brands-1 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  #brands-2 .brands-title {
    margin-bottom: 40px;
  }

  .brands-title h5 {
    margin-bottom: 25px;
  }

  /* FAQs */
  .questions-holder.ind-30 {
    padding: 0;
  }

  .question {
    margin-bottom: 25px;
  }

  .question h5 {
    margin-bottom: 8px;
  }

  .faqs-btn {
    margin-top: 22px;
  }

  /* CALL TO ACTION */
  #cta-1 {
    padding-top: 100px;
    padding-bottom: 110px;
  }

  #cta-4 {
    padding-top: 80px;
  }

  #cta-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .banner-logo img {
    width: 110px;
    height: 110px;
  }

  .cta-txt h2.h2-lg {
    margin-bottom: 20px;
  }

  #cta-1 .cta-txt p {
    padding: 0 5%;
  }

  #cta-1 .cta-btn {
    margin-top: 30px;
  }

  #cta-2 .cta-btn {
    margin-top: 25px;
  }

  /* NEWSLETTER */
  #newsletter-1 .form-control {
    height: 54px;
    font-size: 1.2rem;
  }

  #newsletter-1 .newsletter-form .btn {
    height: 54px;
  }

  /* BLOG */
  #single-blog-page {
    margin-top: 65px;
  }

  #search-field .btn {
    padding: 18px;
  }

  /* CONTACTS */
  #contacts-map {
    margin-top: 65px;
  }

  #contacts-1.bg-clouds {
    padding-bottom: 210px;
  }

  #contacts-2.bg-clouds {
    padding-bottom: 140px;
  }

  #contacts-3.bg-clouds {
    padding-bottom: 100px;
  }

  .contact-form .form-control {
    height: 48px;
    margin-bottom: 20px;
  }

  #contacts-3 .contact-box {
    margin-bottom: 30px;
  }

  .contact-box .svg-inline--fa {
    font-size: 3.25rem;
  }

  .contact-box h5 {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  span.support-number {
    font-size: 1.75rem;
  }

  #gmap {
    height: 340px;
  }

  #contacts-2 #gmap {
    height: 340px;
  }

  #contacts-map #gmap {
    height: 390px;
  }

  /* FOOTER */
  .footer.bg-dark {
    padding-top: 80px;
  }

  .bg-dark .bottom-footer {
    padding-bottom: 50px;
  }

  .foo-links {
    margin: 15px auto 0;
  }

  .foo-links a {
    width: 38px;
    height: 38px;
    font-size: 1.05rem;
    line-height: 34px !important;
    margin-right: 3px;
  }

  .footer-form .form-control {
    font-size: 0.95rem;
    font-weight: 300;
  }

  .footer-form .svg-inline--fa {
    font-size: 1.25rem;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 768px) and (max-width: 991.99px) {
  html {
    font-size: 15px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  .ind-5,
.ind-10,
.ind-15,
.ind-20,
.ind-25,
.ind-30,
.ind-35,
.ind-40,
.ind-45,
.ind-50,
.ind-60,
.ind-70,
.ind-80,
.ind-90,
.ind-100 {
    padding: 0px;
  }

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .p-top-100,
.division.p-top-100 {
    padding-top: 80px;
  }

  .p-bottom-100 {
    padding-bottom: 80px;
  }

  .p-left-0,
.p-left-5,
.p-left-10,
.p-left-15,
.p-left-20,
.p-left-25,
.p-left-30,
.p-left-35,
.p-left-40,
.p-left-45,
.p-left-50,
.p-left-50,
.p-left-60,
.p-left-70,
.p-left-80,
.p-left-90,
.p-left-100 {
    padding-left: 0;
  }

  .p-right-0,
.p-right-5,
.p-right-10,
.p-right-15,
.p-right-20,
.p-right-25,
.p-right-30,
.p-right-35,
.p-right-40,
.p-right-45,
.p-right-50,
.p-right-50,
.p-right-60,
.p-right-70,
.p-right-80,
.p-right-90,
.p-right-100 {
    padding-right: 0;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.066rem;
  }

  /* 16px */
  h5.h5-sm {
    font-size: 1.111rem;
  }

  /* 16.665px */
  h5.h5-md {
    font-size: 1.14rem;
  }

  /* 17.1px */
  h5.h5-lg {
    font-size: 1.15rem;
  }

  /* 17.25px */
  h5.h5-xl {
    font-size: 1.2rem;
  }

  /* 18px */
  /* Header H4 */
  h4.h4-xs {
    font-size: 1.222rem;
  }

  /* 18.33px */
  h4.h4-sm {
    font-size: 1.3rem;
  }

  /* 19.5px */
  h4.h4-md {
    font-size: 1.38rem;
  }

  /* 20.7px */
  h4.h4-lg {
    font-size: 1.475rem;
  }

  /* 22.125px */
  h4.h4-xl {
    font-size: 1.55rem;
  }

  /* 23.25px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 1.75rem;
  }

  /* 26.25px */
  h3.h3-sm {
    font-size: 1.85rem;
  }

  /* 27.75px */
  h3.h3-md {
    font-size: 1.95rem;
  }

  /* 29.25px */
  h3.h3-lg {
    font-size: 2.05rem;
  }

  /* 30.75px */
  h3.h3-xl {
    font-size: 2.21rem;
  }

  /* 33.15px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 2.26rem;
  }

  /* 34px */
  h2.h2-sm {
    font-size: 2.33rem;
  }

  /* 35px */
  h2.h2-md {
    font-size: 2.4rem;
  }

  /* 36px */
  h2.h2-lg {
    font-size: 2.4rem;
  }

  /* 36px */
  h2.h2-xl {
    font-size: 2.5rem;
  }

  /* 37.5px */
  /* Paragraphs */
  p.p-sm {
    font-size: 0.95rem;
  }

  /* 14.25px */
  p {
    font-size: 1rem;
  }

  /* 15px */
  p.p-md {
    font-size: 1rem;
  }

  /* 15px */
  p.p-lg {
    font-size: 1.05rem;
  }

  /* 15.75px */
  p.p-xl {
    font-size: 1.1rem;
  }

  /* 16.5px */
  /* Buttons */
  .btn.btn-sm {
    font-size: 0.9rem;
    padding: 0.8rem 1.75rem;
  }

  .btn {
    font-size: 0.95rem;
    padding: 0.85rem 1.9rem;
  }

  .btn.btn-md {
    font-size: 1rem;
    padding: 0.875rem 2rem;
  }

  .btn.btn-lg {
    font-size: 1rem;
    padding: 0.925rem 2.15rem;
  }

  .btn-arrow:hover span,
.btn-arrow:focus span {
    transform: translate3d(-8px, 0, 0);
  }

  .btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
    opacity: 1;
    right: -17px;
  }

  /* Video Popup Link */
  .modal-video {
    line-height: 1.75rem;
  }

  .modal-video a span {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-right: 3px;
  }

  .modal-video a {
    font-size: 1.05rem;
    line-height: 1.75rem;
  }

  /* Video Icon */
  .video-preview .video-btn-xs {
    margin-top: -33px;
    margin-left: -33px;
  }

  .video-preview .video-btn-sm {
    margin-top: -38px;
    margin-left: -38px;
  }

  .video-preview .video-btn-md {
    margin-top: -43px;
    margin-left: -43px;
  }

  .video-preview .video-btn-lg {
    margin-top: -45px;
    margin-left: -45px;
  }

  .video-btn-xs,
.video-btn .video-btn-xs {
    width: 96px;
    height: 96px;
  }

  .video-btn-sm,
.video-btn .video-btn-sm {
    width: 106px;
    height: 106px;
  }

  .video-btn-md,
.video-btn .video-btn-md {
    width: 116px;
    height: 116px;
  }

  .video-btn-lg,
.video-btn .video-btn-lg {
    width: 120px;
    height: 120px;
  }

  .video-btn-xs .svg-inline--fa {
    width: 66px;
    height: 66px;
  }

  .video-btn-sm .svg-inline--fa {
    width: 76px;
    height: 76px;
  }

  .video-btn-md .svg-inline--fa {
    width: 86px;
    height: 86px;
  }

  .video-btn-lg .svg-inline--fa {
    width: 90px;
    height: 90px;
  }

  .ico-bkg {
    top: 35px;
    left: 35px;
  }

  .video-btn-xs .ico-bkg {
    top: 30px;
    width: 34px;
    height: 36px;
  }

  .video-btn-sm .ico-bkg {
    top: 30px;
    width: 40px;
    height: 45px;
  }

  .video-btn-md .ico-bkg {
    width: 50px;
    height: 50px;
  }

  .video-btn-lg .ico-bkg {
    width: 50px;
    height: 50px;
  }

  /* Box Icons */
  .box-icon [class^=flaticon-]:before,
.box-icon [class*=" flaticon-"]:before,
.box-icon [class^=flaticon-]:after,
.box-icon [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-sm [class^=flaticon-]:before,
.box-icon-sm [class*=" flaticon-"]:before,
.box-icon-sm [class^=flaticon-]:after,
.box-icon-sm [class*=" flaticon-"]:after {
    font-size: 3.25rem;
    line-height: 3.25rem !important;
  }

  .box-icon-md [class^=flaticon-]:before,
.box-icon-md [class*=" flaticon-"]:before,
.box-icon-md [class^=flaticon-]:after,
.box-icon-md [class*=" flaticon-"]:after {
    font-size: 4rem;
    line-height: 4rem !important;
  }

  .box-icon-lg [class^=flaticon-]:before,
.box-icon-lg [class*=" flaticon-"]:before,
.box-icon-lg [class^=flaticon-]:after,
.box-icon-lg [class*=" flaticon-"]:after {
    font-size: 4.5rem;
    line-height: 4.5rem !important;
  }

  /* Technologies */
  .technologies {
    margin-top: 25px;
  }

  .technologies p {
    font-size: 0.85rem;
  }

  .technologies .svg-inline--fa {
    font-size: 2.4rem;
    margin-right: 8px;
  }

  /* Store Badges */
  a.store {
    margin-right: 10px;
  }

  .store img.appstore {
    width: 141px;
    height: 44px;
  }

  .store img.googleplay {
    width: 150px;
    height: 44px;
  }

  .store img.amazon {
    width: 139px;
    height: 44px;
  }

  .store img.windows {
    width: 144px;
    height: 44px;
  }

  /* App Devices Icons */
  .app-devices {
    padding: 5px 0 0 5px;
  }

  .app-devices .svg-inline--fa {
    font-size: 2.15rem;
  }

  .app-devices .svg-inline--fa.f-phone {
    margin-right: 1rem;
  }

  .app-devices-desc p {
    font-size: 0.95rem;
    padding-right: 5%;
  }

  /* Countdown */
  #clock .cbox {
    padding: 0 15px;
  }

  #clock span.cbox-digit {
    font-size: 3.15rem;
    letter-spacing: 0;
  }

  /* Section ID */
  span.section-id {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 25px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1.1rem;
    padding: 0 8%;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
   /*------------------------------------------*/
  .bg-light {
    background-color: #fff !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .navbar {
    text-align: center;
  }

  .dropdown-menu {
    text-align: center;
    padding: 0;
    margin-top: 0;
    border-top: none;
    border-radius: 0;
  }

  .dropdown-divider {
    margin: 5px 0;
  }

  .dropdown-item {
    padding: 10px 0;
  }

  .navbar.bg-tra {
    padding: 0.75rem 1.1rem;
    background-color: #fff !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .navbar.scroll.bg-tra {
    padding: 0.75rem 1.1rem;
  }

  .logo-white,
.navbar-light.bg-tra .logo-white,
.navbar-light.bg-light .logo-white,
.navbar-dark.bg-tra .logo-white,
.navbar-dark.bg-dark .logo-white {
    display: none;
  }

  .navbar-dark.bg-tra .logo-black,
.navbar-light.bg-tra .logo-black,
.logo-black,
.navbar-light.bg-light .logo-black {
    display: block;
  }

  .bg-tra.navbar-dark .nav-link {
    color: #333;
  }

  .bg-tra.navbar-dark .navbar-nav .active > .nav-link {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-dark .nav-link:focus,
.navbar-dark .nav-link:hover {
    color: #333;
    background-color: rgba(200, 200, 200, 0.35);
  }

  .navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #333;
  }

  .navbar-nav {
    padding-top: 15px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.1rem;
    padding: 10px 0;
    margin-left: 0;
    border-radius: 0;
  }

  .navbar .btn {
    margin-top: 8px;
    margin-left: 0;
  }

  .navbar .btn-tra-white {
    color: #444;
    border-color: #444;
  }

  .navbar .btn-semiwhite {
    color: #444;
    background-color: transparent;
    border-color: #444;
  }

  .navbar .btn.tra-hover:hover,
.navbar .btn.btn-purple.tra-hover:hover,
.navbar .btn.btn-lightgreen.tra-hover:hover,
.navbar .btn.btn-semiwhite.tra-hover:hover {
    color: #444;
    background-color: transparent;
    border-color: #444;
  }

  .navbar-text.phone-number {
    font-size: 1.1rem;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .header-socials {
    margin: 4px auto 0;
  }

  .header-socials a {
    margin-left: 2px;
  }

  .header-socials a,
.navbar.scroll .header-socials a {
    color: #fff;
  }

  .header-socials a.ico-facebook,
.navbar.scroll .header-socials a.ico-facebook {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .header-socials a.ico-twitter,
.navbar.scroll .header-socials a.ico-twitter {
    background-color: #00a9ed;
    border-color: #00a9ed;
  }

  .header-socials a.ico-behance,
.navbar.scroll .header-socials a.ico-behance {
    background-color: #2473f6;
    border-color: #2473f6;
  }

  .header-socials a.ico-google-plus,
.navbar.scroll .header-socials a.ico-google-plus {
    background-color: #cd1111;
    border-color: #cd1111;
  }

  .header-socials a.ico-linkedin,
.navbar.scroll .header-socials a.ico-linkedin {
    background-color: #015886;
    border-color: #015886;
  }

  .header-socials a.ico-dribbble,
.navbar.scroll .header-socials a.ico-dribbble {
    background-color: #d92d84;
    border-color: #d92d84;
  }

  .header-socials a.ico-instagram,
.navbar.scroll .header-socials a.ico-instagram {
    background-color: #beb3a8;
    border-color: #beb3a8;
  }

  .header-socials a.ico-pinterest,
.navbar.scroll .header-socials a.ico-pinterest {
    background-color: #ac281a;
    border-color: #ac281a;
  }

  .header-socials a.ico-youtube,
.navbar.scroll .header-socials a.ico-youtube {
    background-color: #cd1b20;
    border-color: #cd1b20;
  }

  .header-socials a.ico-tumblr,
.navbar.scroll .header-socials a.ico-tumblr {
    background-color: #3a5976;
    border-color: #3a5976;
  }

  .header-socials a.ico-vk,
.navbar.scroll .header-socials a.ico-vk {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .header-socials a:hover {
    color: #fff;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  .hero-section {
    margin-top: 58px;
  }

  #hero-1 {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-4 {
    margin-top: 58px;
    padding-top: 120px;
    padding-bottom: 100px;
  }

  #hero-5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-6-content {
    padding-top: 80px;
    padding-bottom: 300px;
  }

  #hero-8 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  #hero-11-txt {
    padding-top: 90px;
    padding-bottom: 330px;
  }

  #hero-12 {
    padding-top: 110px;
  }

  #hero-13-txt {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  #hero-14 {
    padding-top: 120px;
    padding-bottom: 80px;
  }

  #hero-15-txt {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-16 {
    padding-top: 80px;
  }

  #hero-17 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 90px;
  }

  #hero-19-txt {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-20 {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  #hero-21 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-22 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-23 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-24-content {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  #hero-25 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-26 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-27 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-28 {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  #hero-29 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-30 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 530px;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 95%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 20px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 16px;
  }

  #hero-1.hero-section h2 {
    font-size: 2.75rem;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
  }

  .slides h2 {
    font-size: 3.5rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-9 .slides h2 {
    font-size: 4rem;
  }

  #hero-4.hero-section h2 {
    font-size: 3rem;
  }

  #hero-7.hero-section h2 {
    font-size: 4rem;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-10.hero-section h2 {
    font-size: 4rem;
  }

  #hero-16 h2 {
    font-size: 4rem;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  #hero-18 h3.h3-xl {
    margin-bottom: 32px;
  }

  #hero-19 .hero-txt h2 {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }

  #hero-20 h4 {
    padding: 4px 15px;
  }

  #hero-20 h2 {
    font-size: 4rem;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 2.75rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 8rem;
    letter-spacing: -3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 2rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 3.5rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.35rem;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h2 {
    font-size: 3.5em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 3.25em;
  }

  #hero-29 .hero-txt h2 {
    font-size: 3.5rem;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 2.25rem;
    margin-top: 45px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0 5%;
    margin-bottom: 25px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }

  .hero-inner-txt p {
    margin-bottom: 25px;
  }

  #hero-1 .hero-txt p {
    padding: 0;
  }

  #hero-2 .hero-txt p,
#hero-3 .hero-txt p,
#hero-6 .hero-txt p,
#hero-9 .hero-txt p,
#hero-11 .hero-txt p,
#hero-15 .hero-txt p {
    padding: 0 5%;
  }

  #hero-4 .hero-txt p {
    padding-right: 5%;
  }

  #hero-5 .hero-txt p {
    padding-right: 8%;
  }

  #hero-14 .hero-txt p {
    padding-right: 5%;
  }

  #hero-16 .hero-txt p {
    padding-left: 0;
  }

  #hero-18 .hero-txt p {
    padding: 0 2%;
  }

  #hero-19 .hero-txt p {
    padding-right: 0;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding-right: 5%;
  }

  #hero-23 .hero-txt p {
    padding: 0 5%;
  }

  #hero-24 .hero-txt p {
    margin-top: 20px;
    padding-right: 5%;
  }

  /* Hero Buttons */
  .hero-btns {
    margin-top: 30px;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin-top: -320px;
  }

  .hero-inner-txt {
    padding: 0 50px 0 0px;
  }

  .hero-inner-txt .section-id {
    display: none;
  }

  /* Hero Statistic */
  .hero-statistic {
    margin: -70px 30px 0;
    padding: 35px 0 0 0;
  }

  .hero-number {
    font-size: 1.75rem;
    margin-bottom: 5px;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin-top: -280px;
    padding: 45px 30px;
  }

  .discount-banner h2.h2-xl {
    font-size: 4.5rem;
  }

  /* Hero Links */
  .hero-links span {
    font-size: 0.95rem;
    margin-right: 20px;
  }

  /* Hero Register Form */
  .hero-form form {
    padding: 40px 30px 40px;
    margin-right: 0;
  }

  #hero-24 .hero-form form {
    padding: 40px 20px 40px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 0 10%;
  }

  .hero-section .newsletter-form .form-control {
    height: 46px;
    font-size: 1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 46px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -250px;
  }

  .hero-14-img {
    top: 2%;
    right: -45%;
    width: 85%;
  }

  #hero-18 .hero-img {
    margin-top: 50px;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -80px;
  }

  #hero-28-boxes {
    margin-top: 60px;
  }

  .hbox-1 {
    padding: 35px 20px;
  }

  .hbox-2 {
    padding-right: 0;
  }

  .hbox-1 h5 {
    margin-bottom: 12px;
  }

  .hbox-2 h5 {
    margin-bottom: 10px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* INNER PAGE */
  .page-hero-section {
    margin-top: 58px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .page-hero-section h2 {
    font-size: 3rem;
    letter-spacing: -0.5px;
  }

  .page-hero-section p {
    padding: 0 2%;
    margin-top: 5px;
  }

  /* ABOUT */
  .about-txt h3.h3-sm {
    margin-bottom: 20px;
  }

  #a4-1.abox-4 {
    padding: 35px 10px;
  }

  .abox-4 h5,
#a4-1.abox-4 h5 {
    margin-bottom: 10px;
  }

  #a4-1.abox-4 h5 {
    margin-bottom: 10px;
  }

  #a4-1 .btn {
    margin-top: 5px;
  }

  .about-btn {
    margin-top: 25px;
  }

  .about-form {
    margin-top: -160px;
  }

  .about-form form {
    padding: 40px 30px 25px;
  }

  /* SERVICES */
  #services-1.wide-40 {
    padding-bottom: 60px;
  }

  .services-txt h3.h3-sm {
    margin-bottom: 20px;
  }

  .sbox-1 {
    margin-bottom: 40px;
  }

  .sbox-2 {
    padding: 0 10px;
  }

  .sbox-4 {
    margin-bottom: 30px;
  }

  .g-sboxes {
    padding-right: 30px;
  }

  .sbox-1-txt,
.sbox-3-txt {
    padding-left: 20px;
  }

  .sbox-4-txt {
    padding-left: 20px;
  }

  .sbox-2 h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  /* CONTENT */
  #content-5,
#content-6 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #content-7-title {
    padding-top: 80px;
  }

  #content-3 .content-txt {
    padding-left: 30px;
  }

  #content-4 .content-txt {
    padding-right: 30px;
  }

  #content-7-title {
    padding-bottom: 220px;
  }

  #content-7-boxes {
    margin-top: -240px;
    padding-bottom: 40px;
  }

  #content-9 .content-txt {
    padding: 40px 10px 40px 35px;
  }

  #content-10 .content-txt {
    padding: 40px 35px 40px 10px;
  }

  .content-txt h2.h2-huge {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
    margin-bottom: 20px;
  }

  .content-section .inner-block .content-txt h3.h3-xs {
    margin-bottom: 15px;
  }

  .content-txt h5.h5-sm {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .content-section .inner-block .content-txt h5.h5-sm {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .content-section .inner-block ul.content-list {
    margin-top: 15px;
  }

  .content-section p.p-lg.m-bottom-25 {
    margin-bottom: 10px;
  }

  .content-btn {
    margin-top: 30px;
  }

  ul.content-list li {
    margin-bottom: 6px;
  }

  .skills.m-top-40 {
    margin-top: 35px;
  }

  #content-7-boxes .col-md-4 {
    padding: 0 8px;
  }

  .cbox-txt {
    padding: 25px 20px 20px;
  }

  .cbox-txt h5 {
    margin-bottom: 10px;
  }

  .content-right-img {
    top: 0;
    right: -50%;
    width: 92%;
  }

  .content-left-img {
    top: 0;
    left: -50%;
    width: 92%;
  }

  #content-9 .content-img {
    padding-right: 35px;
  }

  #content-10 .content-img {
    padding-left: 35px;
  }

  .inner-block img {
    border-radius: 0;
  }

  #content-10 .inner-block img {
    border-radius: 0;
  }

  /* BANNER */
  #banner-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #banner-2 {
    padding-top: 80px;
  }

  #banner-4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #banner-3 .banner-txt {
    margin-bottom: 40px;
  }

  #banner-2 .banner-txt h2 {
    margin-bottom: 20px;
  }

  #banner-1 .banner-txt h2,
#banner-3 .banner-txt h3,
#banner-4 .banner-txt h2 {
    margin-bottom: 20px;
  }

  #banner-2 .banner-txt h2 span {
    letter-spacing: -1.5px;
  }

  #banner-1 .banner-txt p {
    padding-right: 12%;
  }

  #banner-3 .banner-txt p {
    padding: 0;
  }

  .banner-btn {
    margin-top: 30px;
  }

  /* VIDEO */
  #video-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #video-2 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  #video-3 p {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0;
  }

  /* PORTFOLIO */
  #single-project {
    margin-top: 55px;
  }

  .project-txt h3.h3-xs {
    margin-top: 0;
  }

  .masonry-wrap .portfolio-item,
#portfolio-3 .masonry-wrap .portfolio-item {
    width: 50%;
  }

  .btn-toolbar {
    margin: 0 auto 50px;
  }

  .portfolio-filter button {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 7px;
    padding: 0.7rem 1.5rem;
  }

  .project-description h5.h5-sm {
    margin-top: 2px;
  }

  .project-description span {
    font-size: 0.95rem;
    font-weight: 500;
  }

  /* STATISTIC */
  #statistic-1 {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .statistic-title p {
    padding: 0;
  }

  .small-statistic {
    margin-top: 25px;
  }

  .statistic-number {
    font-size: 3rem;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  .statistic-block h5 {
    margin-bottom: 7px;
  }

  .small-statistic .statistic-number {
    font-size: 3.5rem;
    margin-bottom: 10px;
  }

  .small-statistic .col-md-4 .statistic-number {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  .small-statistic h5.h5-sm {
    font-size: 1.05rem;
  }

  /* PPRICING */
  #pills-tab {
    margin: 20px auto 30px;
  }

  .nav-pills .nav-link {
    font-size: 0.95rem;
    padding: 8px 26px;
  }

  .pricing-section .col-md-4,
.pricing-page-section .col-md-4 {
    padding: 0 5px;
  }

  .pricing-table,
#pricing-3 .pricing-table {
    padding: 40px 20px;
  }

  .pricing-plan {
    padding-bottom: 10px;
  }

  .pricing-table span.price {
    font-size: 56px;
  }

  #pricing-3 .price span {
    font-size: 50px;
  }

  .pricing-table sup {
    font-size: 35px;
    top: -5px;
    right: 2px;
  }

  #pricing-3 .pricing-table .price sup {
    font-size: 30px;
    top: -10px;
    margin-right: 0;
  }

  p.validity {
    font-size: 1.05rem;
    margin-top: 5px;
  }

  #pricing-3 .price span.price-vat {
    font-size: 0.925rem;
    padding: 0;
  }

  #pricing-3 .price h5 {
    margin-bottom: 20px;
  }

  .pricing-table ul.features {
    padding: 20px 0 25px;
  }

  .pricing-table ul.features li {
    font-size: 1rem;
    padding: 9px 0;
  }

  .pricing-table .btn {
    font-size: 0.85rem;
    padding: 0.8rem 1.75rem;
  }

  .pricing-txt {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .pricing-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 25px;
  }

  .pricing-text p {
    font-size: 14px;
    line-height: 22px;
  }

  .pricing-notice .col-md-10.m-top-30 {
    margin-top: 0;
  }

  .pricing-notice .row.m-top-25.m-bottom-50 {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  /* SCHEDULE */
  .nav-tabs {
    margin-bottom: 40px;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem 0;
  }

  .schedule-event {
    margin-bottom: 5px;
  }

  .event-time span {
    font-size: 1rem;
    padding-left: 10px;
  }

  /* SEARCH */
  #search-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .domain-names {
    margin-top: 25px;
  }

  .dn-box {
    padding: 15px 10px;
  }

  #hero-28 .search-form {
    margin: 0 5%;
  }

  .search-form .form-control {
    height: 54px;
  }

  .search-form .btn {
    height: 54px;
    font-size: 1.05rem;
  }

  /* TESTIMONIALS */
  #reviews-2 .quote-icon {
    margin: 0 auto -65px;
  }

  .review-1 {
    padding: 0 80px;
    margin-bottom: 30px;
  }

  .review-2 {
    margin-bottom: 60px;
  }

  .review-1 .review-txt {
    padding: 40px 25px 50px;
  }

  .review-1 .testimonial-avatar img,
#reviews-2 img {
    width: 70px;
    height: 70px;
  }

  .review-1 p {
    font-size: 1rem;
  }

  #reviews-2 .review-txt p {
    font-size: 1.25rem;
    padding: 0px 5% 10px;
  }

  #reviews-2 .review-author p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  #reviews-2 .review-author span {
    font-size: 0.8rem;
  }

  /* BRANDS */
  #brands-1 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  #brands-2 .brands-title {
    margin-bottom: 40px;
  }

  .brands-title h5 {
    margin-bottom: 25px;
  }

  .brands-title p {
    padding: 0;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    padding: 0 8px;
  }

  #brands-2 .brand-logo {
    padding: 0 20px;
  }

  .brand-logo img {
    padding: 0;
  }

  /* FAQs */
  .questions-holder.ind-30 {
    padding: 0;
  }

  .question {
    margin-bottom: 25px;
  }

  .question h5 {
    margin-bottom: 5px;
  }

  .question p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .more-questions-btn {
    margin-top: 25px;
  }

  .faqs-btn {
    margin-top: 25px;
  }

  /* CALL TO ACTION */
  #cta-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #cta-4 {
    padding-top: 80px;
  }

  #cta-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .banner-logo img {
    width: 80px;
    height: 80px;
  }

  #cta-2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  #cta-3 {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .cta-txt h2.h2-lg {
    letter-spacing: 0px;
    margin-bottom: 20px;
  }

  .cta-txt h2.h2-md,
.cta-txt h2.h2-xs {
    margin-bottom: 15px;
  }

  #cta-1 .cta-txt p {
    padding: 0 8%;
  }

  #cta-2 .cta-txt p {
    padding: 0 5%;
  }

  #cta-4 .cta-txt p {
    padding-right: 0;
    margin-top: 10px;
  }

  #cta-1 .cta-btn,
#cta-2 .cta-btn {
    margin-top: 25px;
  }

  #cta-4 .cta-btn {
    margin-top: 25px;
  }

  /* NEWSLETTER */
  #newsletter-1 {
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }

  #newsletter-1 .newsletter-txt {
    margin-bottom: 20px;
  }

  #newsletter-1 .form-control {
    height: 54px;
    font-size: 1.2rem;
  }

  #newsletter-1 .newsletter-form .btn {
    height: 54px;
  }

  /* BLOG */
  #single-blog-page {
    margin-top: 55px;
  }

  #search-field .btn {
    padding: 18px;
  }

  .sidebar-div.m-bottom-40 {
    margin-bottom: 30px;
  }

  .sidebar-div.m-bottom-50 {
    margin-bottom: 40px;
  }

  #sidebar-right h5.h5-sm {
    margin-bottom: 20px;
  }

  .badge {
    font-size: 0.65rem;
    margin-bottom: 6px;
    padding: 3px 8px;
  }

  a.share-ico {
    width: 70px;
    height: 30px;
    font-size: 15px;
    line-height: 30px !important;
    margin-right: 2px;
  }

  .single-post-comments hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .comment-form .form-control {
    height: 42px;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .comment-form .btn {
    font-size: 1rem;
  }

  .blog-page-pagination.m-top-80 {
    margin-top: 50px;
  }

  /* BOOKING */
  #booking-form .form-control {
    height: 50px;
  }

  #booking-form .btn {
    height: 50px;
  }

  /* CONTACTS */
  #contacts-1.bg-clouds {
    padding-bottom: 210px;
  }

  #contacts-2.bg-clouds {
    padding-bottom: 140px;
  }

  #contacts-3.bg-clouds {
    padding-bottom: 80px;
  }

  .contact-form .form-control {
    height: 48px;
    margin-bottom: 20px;
  }

  #contacts-2 .contact-box {
    margin-bottom: 20px;
  }

  #contacts-3 .contact-box {
    margin-bottom: 30px;
  }

  .contact-box .svg-inline--fa {
    font-size: 3rem;
  }

  .contact-box p {
    font-size: 1rem;
  }

  .contact-box h5 {
    margin-top: 15px;
    margin-bottom: 8px;
  }

  span.support-number {
    font-size: 1.5rem;
  }

  #gmap {
    height: 320px;
    margin-top: 20px;
  }

  #contacts-2 #gmap {
    height: 280px;
    margin-bottom: 30px;
  }

  #contacts-map {
    margin-top: 40px;
  }

  #contacts-map #gmap {
    height: 330px;
  }

  /* FOOTER */
  .footer.bg-dark {
    padding-top: 80px;
  }

  .bg-dark .bottom-footer {
    padding-bottom: 50px;
  }

  .footer-info {
    padding-right: 20%;
  }

  .footer h4,
.footer h5 {
    margin-bottom: 15px;
  }

  .footer-links li {
    margin: 0 0 8px 0;
  }

  .foo-links {
    margin: 8px auto 0;
  }

  .foo-links a {
    width: 38px;
    height: 38px;
    font-size: 1.05rem;
    line-height: 34px !important;
    margin-right: 3px;
  }

  .footer-form .form-control {
    font-size: 0.95rem;
    font-weight: 300;
  }

  .footer-form .svg-inline--fa {
    font-size: 1.25rem;
  }

  /* QUICK FORM */
  .bottom-form {
    min-width: 240px;
    max-width: 240px;
  }

  .bottom-form-header {
    padding: 11px 20px;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (max-width: 768px) {
  .animated {
    visibility: visible;
  }

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  html {
    font-size: 15px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .p-top-100,
.division.p-top-100 {
    padding-top: 80px;
  }

  .p-bottom-100 {
    padding-bottom: 80px;
  }

  .p-bottom-60 {
    padding-bottom: 40px;
  }

  .ind-5,
.ind-10,
.ind-15,
.ind-20,
.ind-25,
.ind-30,
.ind-35,
.ind-40,
.ind-45,
.ind-50,
.ind-60,
.ind-70,
.ind-80,
.ind-90,
.ind-100 {
    padding: 0px;
  }

  .p-left-0,
.p-left-5,
.p-left-10,
.p-left-15,
.p-left-20,
.p-left-25,
.p-left-30,
.p-left-35,
.p-left-40,
.p-left-45,
.p-left-50,
.p-left-50,
.p-left-60,
.p-left-70,
.p-left-80,
.p-left-90,
.p-left-100 {
    padding-left: 0;
  }

  .p-right-0,
.p-right-5,
.p-right-10,
.p-right-15,
.p-right-20,
.p-right-25,
.p-right-30,
.p-right-35,
.p-right-40,
.p-right-45,
.p-right-50,
.p-right-50,
.p-right-60,
.p-right-70,
.p-right-80,
.p-right-90,
.p-right-100 {
    padding-right: 0;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
   /*------------------------------------------*/
  .navbar-brand {
    margin-right: 0.5rem;
  }

  .bg-light {
    background-color: #fff !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .navbar {
    text-align: center;
  }

  .dropdown-menu {
    text-align: center;
    padding: 0;
    margin-top: 0;
    border-top: none;
    border-radius: 0;
  }

  .dropdown-divider {
    margin: 5px 0;
  }

  .dropdown-item {
    padding: 10px 0;
  }

  .navbar.bg-tra {
    padding: 0.75rem 1.1rem;
    background-color: #fff !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .navbar.scroll.bg-tra {
    padding: 0.75rem 1.1rem;
  }

  .logo-white,
.navbar-light.bg-tra .logo-white,
.navbar-light.bg-light .logo-white,
.navbar-dark.bg-tra .logo-white,
.navbar-dark.bg-dark .logo-white {
    display: none;
  }

  .navbar-dark.bg-tra .logo-black,
.navbar-light.bg-tra .logo-black,
.logo-black,
.navbar-light.bg-light .logo-black {
    display: block;
  }

  .bg-tra.navbar-dark .nav-link {
    color: #333;
  }

  .bg-tra.navbar-dark .navbar-nav .active > .nav-link {
    color: rgba(0, 0, 0, 0.9);
  }

  .navbar-dark .nav-link:focus,
.navbar-dark .nav-link:hover {
    color: #333;
    background-color: rgba(200, 200, 200, 0.35);
  }

  .navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #333;
  }

  .navbar-nav {
    padding-top: 15px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.1rem;
    padding: 10px 0;
    margin-left: 0;
    border-radius: 0;
  }

  .navbar .btn {
    margin-top: 8px;
    margin-left: 0;
  }

  .navbar .btn-tra-white {
    color: #444;
    border-color: #444;
  }

  .navbar .btn-semiwhite {
    color: #444;
    background-color: transparent;
    border-color: #444;
  }

  .navbar .btn.tra-hover:hover,
.navbar .btn.btn-purple.tra-hover:hover,
.navbar .btn.btn-lightgreen.tra-hover:hover,
.navbar .btn.btn-semiwhite.tra-hover:hover {
    color: #444;
    background-color: transparent;
    border-color: #444;
  }

  .navbar-text.phone-number {
    font-size: 1.1rem;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .header-socials {
    margin: 4px auto 0;
  }

  .header-socials a {
    margin-left: 2px;
  }

  .header-socials a,
.navbar.scroll .header-socials a {
    color: #fff;
  }

  .header-socials a.ico-facebook,
.navbar.scroll .header-socials a.ico-facebook {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .header-socials a.ico-twitter,
.navbar.scroll .header-socials a.ico-twitter {
    background-color: #00a9ed;
    border-color: #00a9ed;
  }

  .header-socials a.ico-behance,
.navbar.scroll .header-socials a.ico-behance {
    background-color: #2473f6;
    border-color: #2473f6;
  }

  .header-socials a.ico-google-plus,
.navbar.scroll .header-socials a.ico-google-plus {
    background-color: #cd1111;
    border-color: #cd1111;
  }

  .header-socials a.ico-linkedin,
.navbar.scroll .header-socials a.ico-linkedin {
    background-color: #015886;
    border-color: #015886;
  }

  .header-socials a.ico-dribbble,
.navbar.scroll .header-socials a.ico-dribbble {
    background-color: #d92d84;
    border-color: #d92d84;
  }

  .header-socials a.ico-instagram,
.navbar.scroll .header-socials a.ico-instagram {
    background-color: #beb3a8;
    border-color: #beb3a8;
  }

  .header-socials a.ico-pinterest,
.navbar.scroll .header-socials a.ico-pinterest {
    background-color: #ac281a;
    border-color: #ac281a;
  }

  .header-socials a.ico-youtube,
.navbar.scroll .header-socials a.ico-youtube {
    background-color: #cd1b20;
    border-color: #cd1b20;
  }

  .header-socials a.ico-tumblr,
.navbar.scroll .header-socials a.ico-tumblr {
    background-color: #3a5976;
    border-color: #3a5976;
  }

  .header-socials a.ico-vk,
.navbar.scroll .header-socials a.ico-vk {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .header-socials a:hover {
    color: #fff;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  .hero-section {
    margin-top: 58px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* INNER PAGE */
  .page-hero-section {
    margin-top: 58px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .page-hero-section p {
    padding: 0 2%;
    margin-top: 5px;
  }

  /* SERVICES */
  #services-1.wide-40 {
    padding-bottom: 60px;
  }

  .sbox-1 {
    margin-bottom: 40px;
  }

  .sbox-4 {
    margin-bottom: 30px;
  }

  .sbox-2 h5 {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  /* CONTENT */
  #content-5,
#content-6 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #content-7-title {
    padding-top: 80px;
  }

  #content-9 .section-title,
#content-10 .section-title {
    margin-bottom: 45px;
  }

  .content-section .inner-block ul.content-list {
    margin-top: 15px;
  }

  .content-section p.p-lg.m-bottom-25 {
    margin-bottom: 10px;
  }

  ul.content-list li {
    margin-bottom: 6px;
  }

  .inner-block img {
    border-radius: 0;
  }

  #content-10 .inner-block img {
    border-radius: 0;
  }

  /* BANNER */
  #banner-2 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  /* PORTFOLIO */
  .project-img {
    margin-bottom: 40px;
  }

  #single-project {
    margin-top: 55px;
  }

  .project-txt h3.h3-xs {
    margin-top: 0;
  }

  .project-description h5.h5-sm {
    margin-top: 2px;
  }

  .project-description span {
    font-size: 0.95rem;
    font-weight: 500;
  }

  /* STATISTIC */
  #statistic-1 {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .small-statistic {
    margin-top: 25px;
  }

  /* TESTIMONIALS */
  .review-1 {
    margin-bottom: 30px;
  }

  .review-2 {
    margin-bottom: 60px;
  }

  /* CALL TO ACTION */
  #cta-2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  #cta-3 {
    padding-top: 70px;
    padding-bottom: 30px;
  }

  #cta-2 .cta-btn {
    margin-top: 25px;
  }

  /* NEWSLETTER */
  #newsletter-1 {
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;
  }

  #newsletter-1 .newsletter-txt {
    margin-bottom: 20px;
  }

  /* BLOG */
  #blog-page.wide-80 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  #sidebar-right {
    margin-top: 50px;
  }

  #single-blog-page {
    margin-top: 55px;
  }

  #search-field .btn {
    padding: 18px;
  }

  .sidebar-div.m-bottom-40 {
    margin-bottom: 30px;
  }

  .sidebar-div.m-bottom-50 {
    margin-bottom: 40px;
  }

  #sidebar-right h5.h5-sm {
    margin-bottom: 20px;
  }

  .blog-page-pagination.m-top-80 {
    margin-top: 50px;
  }

  /* CONTACTS */
  #contacts-map {
    margin-top: 40px;
  }

  /* FOOTER */
  .footer.bg-dark {
    padding-top: 80px;
  }

  .bg-dark .bottom-footer {
    padding-bottom: 50px;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 576px) and (max-width: 767.99px) {
  .animated {
    visibility: visible;
  }

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  html {
    font-size: 15px;
  }

  .double-section.wide-50 {
    padding-bottom: 0;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  /* Header H5 */
  h5.h5-xs {
    font-size: 1.066rem;
  }

  /* 16px */
  h5.h5-sm {
    font-size: 1.111rem;
  }

  /* 16.665px */
  h5.h5-md {
    font-size: 1.14rem;
  }

  /* 17.1px */
  h5.h5-lg {
    font-size: 1.15rem;
  }

  /* 17.25px */
  h5.h5-xl {
    font-size: 1.2rem;
  }

  /* 18px */
  h4.h4-xs {
    font-size: 1.23rem;
  }

  /* 18.45px */
  h4.h4-sm {
    font-size: 1.3rem;
  }

  /* 19.5px */
  h4.h4-md {
    font-size: 1.3rem;
  }

  /* 19.5px */
  h4.h4-lg {
    font-size: 1.366rem;
  }

  /* 20.5px */
  h4.h4-xl {
    font-size: 1.4rem;
  }

  /* 21px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 1.45rem;
  }

  /* 21.75px */
  h3.h3-sm {
    font-size: 1.54rem;
  }

  /* 23.1px */
  h3.h3-md {
    font-size: 1.625rem;
  }

  /* 24.375px */
  h3.h3-lg {
    font-size: 1.7rem;
  }

  /* 25.5px */
  h3.h3-xl {
    font-size: 1.85rem;
  }

  /* 27.75px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 1.9rem;
  }

  /* 28.5px */
  h2.h2-sm {
    font-size: 1.95rem;
  }

  /* 29.25px */
  h2.h2-md {
    font-size: 2rem;
  }

  /* 30px */
  h2.h2-lg {
    font-size: 2.1rem;
  }

  /* 31.5px */
  h2.h2-xl {
    font-size: 2.2rem;
  }

  /* 36.75px */
  /* Paragraphs */
  p.p-sm {
    font-size: 0.95rem;
  }

  /* 14.25px */
  p {
    font-size: 1rem;
  }

  /* 15px */
  p.p-md {
    font-size: 1rem;
  }

  /* 15px */
  p.p-lg {
    font-size: 1rem;
  }

  /* 15 */
  p.p-xl {
    font-size: 1.05rem;
  }

  /* 15.75px */
  /* Buttons */
  .btn.btn-sm {
    font-size: 0.875rem;
    padding: 0.7rem 1.6rem;
  }

  .btn {
    font-size: 0.95rem;
    padding: 0.8rem 1.75rem;
  }

  .btn.btn-md {
    font-size: 0.975rem;
    padding: 0.85rem 1.9rem;
  }

  .btn.btn-lg {
    font-size: 1rem;
    padding: 0.875rem 2rem;
  }

  .btn-arrow:hover span,
.btn-arrow:focus span {
    transform: translate3d(-7px, 0, 0);
  }

  .btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
    opacity: 1;
    right: -15px;
  }

  /* Video Popup Link */
  .modal-video {
    line-height: 1.75rem;
  }

  .modal-video a span {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-right: 3px;
  }

  .modal-video a {
    font-size: 1.05rem;
    line-height: 1.75rem;
  }

  /* Video Icon */
  .video-btn-xs,
.video-btn-sm,
.video-btn-md,
.video-btn-lg {
    padding: 10px;
  }

  .video-preview .video-btn-xs {
    margin-top: -30px;
    margin-left: -30px;
  }

  .video-preview .video-btn-sm {
    margin-top: -35px;
    margin-left: -35px;
  }

  .video-preview .video-btn-md {
    margin-top: -40px;
    margin-left: -40px;
  }

  .video-preview .video-btn-lg {
    margin-top: -40px;
    margin-left: -40px;
  }

  .video-btn-xs,
.video-btn .video-btn-xs {
    width: 80px;
    height: 80px;
  }

  .video-btn-sm,
.video-btn .video-btn-sm {
    width: 90px;
    height: 90px;
  }

  .video-btn-md,
.video-btn .video-btn-md {
    width: 100px;
    height: 100px;
  }

  .video-btn-lg,
.video-btn .video-btn-lg {
    width: 100px;
    height: 100px;
  }

  .video-btn-xs .svg-inline--fa {
    width: 60px;
    height: 60px;
  }

  .video-btn-sm .svg-inline--fa {
    width: 70px;
    height: 70px;
  }

  .video-btn-md .svg-inline--fa {
    width: 80px;
    height: 80px;
  }

  .video-btn-lg .svg-inline--fa {
    width: 80px;
    height: 80px;
  }

  .ico-bkg {
    top: 30px;
    left: 30px;
  }

  .video-btn-xs .ico-bkg {
    top: 25px;
    width: 30px;
    height: 30px;
  }

  .video-btn-sm .ico-bkg {
    top: 25px;
    left: 30px;
    width: 33px;
    height: 45px;
  }

  .video-btn-md .ico-bkg {
    width: 40px;
    height: 40px;
  }

  .video-btn-lg .ico-bkg {
    width: 40px;
    height: 45px;
  }

  /* Box Icons */
  .box-icon [class^=flaticon-]:before,
.box-icon [class*=" flaticon-"]:before,
.box-icon [class^=flaticon-]:after,
.box-icon [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-sm [class^=flaticon-]:before,
.box-icon-sm [class*=" flaticon-"]:before,
.box-icon-sm [class^=flaticon-]:after,
.box-icon-sm [class*=" flaticon-"]:after {
    font-size: 3.5rem;
    line-height: 3.5rem !important;
  }

  .box-icon-md [class^=flaticon-]:before,
.box-icon-md [class*=" flaticon-"]:before,
.box-icon-md [class^=flaticon-]:after,
.box-icon-md [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-lg [class^=flaticon-]:before,
.box-icon-lg [class*=" flaticon-"]:before,
.box-icon-lg [class^=flaticon-]:after,
.box-icon-lg [class*=" flaticon-"]:after {
    font-size: 4.25rem;
    line-height: 4.25rem !important;
  }

  /* Technologies */
  .technologies {
    margin-top: 25px;
  }

  .technologies p {
    font-size: 0.85rem;
  }

  .technologies .svg-inline--fa {
    font-size: 2.4rem;
    margin-right: 8px;
  }

  /* Store Badges */
  a.store {
    margin-right: 10px;
  }

  .store img.appstore {
    width: 141px;
    height: 44px;
  }

  .store img.googleplay {
    width: 150px;
    height: 44px;
  }

  .store img.amazon {
    width: 139px;
    height: 44px;
  }

  .store img.windows {
    width: 144px;
    height: 44px;
  }

  /* App Devices Icons */
  .app-devices {
    padding: 5px 0 0 5px;
  }

  .app-devices .svg-inline--fa {
    font-size: 2.15rem;
  }

  .app-devices .svg-inline--fa.f-phone {
    margin-right: 1rem;
  }

  .app-devices-desc p {
    font-size: 0.95rem;
    padding-right: 5%;
  }

  /* Countdown */
  #clock .cbox {
    padding: 0 5px;
  }

  #clock span.cbox-digit {
    font-size: 2.85rem;
    letter-spacing: 0;
  }

  /* Section ID */
  span.section-id {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1.05rem;
    padding: 0;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  #hero-1 {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-4 {
    margin-top: 58px;
    padding-top: 120px;
    padding-bottom: 100px;
  }

  #hero-5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-6-content {
    padding-top: 80px;
    padding-bottom: 250px;
  }

  #hero-8 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  #hero-11-txt {
    padding-top: 80px;
    padding-bottom: 530px;
  }

  #hero-12 {
    padding-top: 90px;
  }

  #hero-13-txt {
    padding-top: 80px;
    padding-bottom: 110px;
  }

  #hero-14 {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  #hero-15-txt {
    padding-top: 90px;
    padding-bottom: 140px;
  }

  #hero-16 {
    padding-top: 80px;
  }

  #hero-17 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 90px;
  }

  #hero-19-txt {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-20 {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  #hero-21 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-22 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-23 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-24-content {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  #hero-25 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-26 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-27 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-28 {
    padding-top: 90px;
    padding-bottom: 50px;
  }

  #hero-29 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-30 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  #hero-5 .hero-txt,
#hero-8 .hero-txt,
#hero-13-txt .hero-txt,
#hero-14 .hero-txt,
#hero-16 .hero-txt,
#hero-17 .hero-txt,
#hero-19 .hero-txt,
#hero-21 .hero-txt,
#hero-22 .hero-txt,
#hero-24 .hero-txt,
#hero-25 .hero-txt,
#hero-27 .hero-txt,
#hero-29 .hero-txt {
    text-align: center;
  }

  #hero-25 .hero-txt,
#hero-26 .hero-txt {
    margin-bottom: 40px;
  }

  .hero-price.text-right {
    text-align: left !important;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 500px;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 98%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 15px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  #hero-1.hero-section h2 {
    font-size: 2.25rem;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
  }

  .slides h2 {
    font-size: 2.75rem;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }

  #hero-9 .slides h2 {
    font-size: 3.25rem;
  }

  #hero-4.hero-section h2 {
    font-size: 2.5rem;
  }

  #hero-7.hero-section h2 {
    font-size: 3.25rem;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-10.hero-section h2 {
    font-size: 3.25rem;
  }

  #hero-16 h2 {
    font-size: 3.25rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-18 h3.h3-xl {
    margin-bottom: 28px;
    padding: 0 5%;
  }

  #hero-19 .hero-txt h2 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  #hero-20 h4 {
    padding: 4px 15px;
  }

  #hero-20 h2 {
    font-size: 3.5rem;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 2.75rem;
    margin-bottom: 20px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 2.75rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 8rem;
    letter-spacing: -3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 1.85rem;
  }

  #hero-25 .hero-txt h2 {
    font-size: 2.75rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 3.5rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.35rem;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h2 {
    font-size: 3.5em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 2.75em;
  }

  #hero-29 .hero-txt h2 {
    font-size: 3rem;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 2rem;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0 10%;
    margin-bottom: 25px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1.05rem;
    margin-bottom: 25px;
  }

  .hero-inner-txt p {
    margin-bottom: 25px;
  }

  #hero-1 .hero-txt p,
#hero-15 .hero-txt p {
    padding: 0;
  }

  #hero-2 .hero-txt p,
#hero-6 .hero-txt p,
#hero-8 .hero-txt p,
#hero-11 .hero-txt p {
    padding: 0 8%;
  }

  #hero-3 .hero-txt p,
#hero-5 .hero-txt p,
#hero-9 .hero-txt p,
#hero-11 .hero-txt p,
#hero-18 .hero-txt p {
    padding: 0 5%;
  }

  #hero-4 .hero-txt p {
    padding-right: 12%;
  }

  #hero-14 .hero-txt p {
    padding-right: 5%;
  }

  #hero-16 .hero-txt p {
    padding: 0 8%;
    margin-bottom: 10px;
  }

  #hero-19 .hero-txt p,
#hero-21 .hero-txt p,
#hero-24 .hero-txt p,
#hero-29 .hero-txt p {
    padding: 0 8%;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding: 0 8%;
  }

  #hero-23 .hero-txt p {
    padding: 0 5%;
  }

  #hero-24 .hero-txt p {
    margin-top: 20px;
    padding: 0 8%;
  }

  /* Hero Buttons */
  .hero-btns {
    margin-top: 20px;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin: -510px 5px 0;
  }

  .hero-inner-txt {
    padding: 50px;
    text-align: left !important;
  }

  .hero-inner-img img {
    border-radius: 6px 6px 0 0;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin: -65px 15% 0;
    text-align: center;
    padding: 45px 5%;
  }

  .discount-banner h2.h2-xl {
    font-size: 5rem;
  }

  /* Hero Links */
  .hero-links {
    margin-top: 5px;
  }

  .hero-links span {
    font-size: 0.95rem;
    margin-right: 18px;
  }

  /* Hero Statistic */
  .hero-statistic {
    margin: -70px 0 0;
    padding: 35px 10px 0;
  }

  .hero-number {
    font-size: 1.65rem;
    margin-bottom: 5px;
  }

  /* Hero Register Form */
  .hero-form form {
    padding: 55px 40px 55px;
    margin: 40px 8% 0;
  }

  #hero-24 .hero-form form {
    padding: 55px 40px 55px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 0 10%;
  }

  .hero-section .newsletter-form .form-control {
    height: 46px;
    font-size: 1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 46px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -210px;
  }

  #hero-8 .hero-img {
    margin: 40px 15% 0;
  }

  .hero-14-img {
    position: relative !important;
    top: 0;
    right: 0;
    width: 95%;
    margin: 30px 10px 0;
  }

  #hero-16 .hero-img {
    margin: 0 10%;
  }

  #hero-17 .hero-img {
    margin: 30px 10% 0;
  }

  #hero-18 .hero-img {
    margin-top: 40px;
  }

  #hero-21 .hero-img {
    margin: 40px 10% 0;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -100px;
  }

  #hero-28-boxes {
    margin-top: 40px;
  }

  .hbox-1 {
    padding: 35px 50px;
    margin-top: 30px;
    text-align: center;
  }

  .hbox-2 {
    padding-right: 5%;
    margin-bottom: 30px;
  }

  .hbox-1 h5 {
    margin-bottom: 12px;
  }

  .hbox-2 h5 {
    margin-bottom: 10px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* INNER PAGE */
  .page-hero-section h2 {
    font-size: 2.25rem;
    letter-spacing: -0.5px;
  }

  /* ABOUT */
  #about-2 .about-txt {
    margin-bottom: 0;
  }

  .about-txt h3.h3-sm {
    margin-bottom: 15px;
  }

  .skills.m-top-30 {
    margin-top: 15px;
  }

  .abox-4,
#a4-1.abox-4 {
    padding: 40px 10px;
  }

  .abox-4 h5,
#a4-1.abox-4 h5 {
    margin-bottom: 15px;
  }

  #a4-1.abox-4 h5 {
    margin-bottom: 15px;
  }

  #a4-1 .btn {
    margin-top: 5px;
  }

  .about-form {
    margin: 0;
  }

  .about-form form {
    padding: 50px 40px 35px;
  }

  .layer-phone {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .about-btn {
    margin-top: 25px;
  }

  /* SERVICES */
  #services-3 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .g-sboxes {
    margin-bottom: 50px;
  }

  .sbox-2 {
    padding: 0 15%;
  }

  .sbox-3 {
    margin-bottom: 40px;
  }

  .sbox-6 {
    padding: 40px 40px;
  }

  .services-txt h3.h3-sm {
    margin-bottom: 15px;
  }

  .sbox-1-txt,
.sbox-3-txt {
    padding-left: 22px;
  }

  .sbox-4-txt {
    padding-left: 22px;
  }

  /* CONTENT */
  #content-5,
#content-6 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #content-4 {
    padding-bottom: 80px;
  }

  #content-7-title {
    padding-bottom: 140px;
  }

  #content-7-boxes {
    margin-top: -150px;
    padding-bottom: 40px;
  }

  #content-3 .content-txt {
    padding-left: 0;
    margin-bottom: 50px;
  }

  #content-4 .content-txt {
    padding-right: 0;
    margin-bottom: 50px;
  }

  #content-9 .content-txt,
#content-10 .content-txt {
    padding: 50px 40px;
  }

  .content-txt h2.h2-huge {
    font-size: 2.65rem;
    margin-bottom: 15px;
  }

  .content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
    margin-bottom: 15px;
  }

  .content-txt h5.h5-sm {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .cbox-txt {
    padding: 30px 30px 20px;
  }

  .cbox-txt h5 {
    margin-bottom: 10px;
  }

  .content-btn {
    margin-top: 20px;
  }

  .skills.m-top-40 {
    margin-top: 30px;
  }

  .content-3-img,
.content-4-img {
    background-position: center center;
    position: relative;
    left: 0;
    height: 350px;
    width: auto !important;
    margin-left: 30px;
    margin-right: 30px;
  }

  .content-right-img {
    position: relative !important;
    display: inline-block;
    top: 0;
    right: 0;
    width: 90%;
    margin: 30px 35px 0;
  }

  .content-left-img {
    position: relative !important;
    display: inline-block;
    top: 0;
    left: 0;
    width: 90%;
    margin: 0 35px 40px;
  }

  /* BANNER */
  #banner-1,
#banner-4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #banner-3 {
    padding-top: 80px;
  }

  #banner-1,
#banner-2 {
    text-align: center;
  }

  #banner-3 .banner-txt {
    margin-bottom: 40px;
  }

  #banner-1 .banner-txt h2,
#banner-3 .banner-txt h3,
#banner-4 .banner-txt h2 {
    margin-bottom: 15px;
  }

  #banner-3 .banner-txt h3 {
    margin-bottom: 15px;
  }

  #banner-2 .banner-txt h2 span {
    letter-spacing: -1.5px;
  }

  #banner-1 .banner-txt p,
#banner-2 .banner-txt p {
    padding: 0 5%;
  }

  #banner-3 .banner-txt p {
    padding: 0 5%;
  }

  .banner-btn {
    margin-top: 25px;
  }

  #banner-2 .banner-img {
    margin: 0 15%;
  }

  /* VIDEO */
  #video-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #video-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #video-1 .video-btn.m-top-25 {
    margin-top: 15px;
  }

  #video-3 p {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 0 5%;
  }

  /* PORTFOLIO */
  .btn-toolbar {
    display: block;
    margin-bottom: 50px;
  }

  .portfolio-filter button {
    width: 100%;
    display: block !important;
    padding: 0.75rem 0;
    float: none !important;
    font-size: 0.925rem;
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  .masonry-wrap .portfolio-item,
#portfolio-3 .masonry-wrap .portfolio-item {
    width: 100%;
  }

  /* STATISTIC */
  #statistic-2 p {
    padding: 0;
  }

  .statistic-number {
    font-size: 3.5rem;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  .small-statistic .statistic-number {
    font-size: 3.25rem;
    margin-bottom: 10px;
  }

  .statistic-block h5 {
    margin-bottom: 7px;
  }

  /* SCHEDULE */
  .nav-tabs {
    margin-bottom: 40px;
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem 0;
    background-color: rgba(220, 220, 220, 0.6);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .schedule-event {
    margin-bottom: 5px;
  }

  .event-time span {
    font-size: 1rem;
    padding-left: 10px;
  }

  .lrow {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }

  .schedule-buttons.m-top-20,
.schedule-buttons.m-top-30 {
    margin-top: 10px;
  }

  .agenda-tittle {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px 30px;
  }

  .agenda-tittle span {
    font-size: 1rem;
  }

  /* SEARCH */
  #search-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .domain-names {
    margin-top: 25px;
  }

  .dn-box {
    padding: 15px 10px;
  }

  .search-form .form-control {
    height: 54px;
  }

  .search-form .btn {
    height: 54px;
    font-size: 1.05rem;
  }

  /* PPRICING */
  #pills-tab {
    margin: 20px auto 30px;
  }

  .nav-pills .nav-link {
    font-size: 0.95rem;
    padding: 8px 26px;
  }

  .pricing-section .col-md-4,
.pricing-page-section .col-md-4 {
    padding: 0 12%;
  }

  .pricing-txt {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .pricing-notice .col-md-10.m-top-30 {
    margin-top: 10px;
  }

  .pricing-notice .row.m-top-25.m-bottom-50 {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  #pricing-3 .price span.price-vat {
    padding: 0;
  }

  /* TESTIMONIALS */
  #reviews-2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .review-1 {
    padding: 0 30px;
  }

  .review-1 .testimonial-avatar img,
#reviews-2 img {
    width: 70px;
    height: 70px;
  }

  .review-1 p {
    font-size: 1rem;
  }

  #reviews-2 .review-txt p {
    font-size: 1.25rem;
    padding: 0px 5% 10px;
  }

  #reviews-2 .review-author p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  #reviews-2 .review-author span {
    font-size: 0.8rem;
  }

  /* BRANDS */
  #brands-1 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  #brands-2 .brands-title {
    margin-bottom: 40px;
  }

  .brands-title h5 {
    margin-bottom: 25px;
  }

  .brands-title p {
    padding: 0;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    width: 33.333%;
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  #brands-2 .brand-logo {
    padding: 0 20px;
  }

  .brand-logo img {
    padding: 0;
  }

  /* FAQs */
  .questions-holder.ind-30 {
    padding: 0;
  }

  .question {
    margin-bottom: 25px;
  }

  .question h5 {
    margin-bottom: 5px;
  }

  .question p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .more-questions-btn {
    margin-top: 25px;
  }

  .faqs-btn {
    margin-top: 25px;
  }

  /* CALL TO ACTION */
  #cta-1 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  #cta-4 {
    padding-top: 0;
    text-align: center;
  }

  #cta-4 .bg-inner {
    padding-top: 80px;
  }

  #cta-5 {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }

  .banner-logo img {
    width: 80px;
    height: 80px;
  }

  #cta-3,
#cta-3 .col-lg-6.text-right {
    text-align: center !important;
  }

  #cta-3,
.cta-txt {
    margin-bottom: 15px;
  }

  .cta-txt h2.h2-lg {
    padding: 0 2%;
    letter-spacing: 0px;
    margin-bottom: 15px;
  }

  .cta-txt h2.h2-md,
.cta-txt h2.h2-xs {
    margin-bottom: 15px;
  }

  #cta-1 .cta-txt p {
    padding: 0 5%;
  }

  #cta-2 .cta-txt p {
    padding: 0 5%;
  }

  #cta-3 .cta-txt p {
    padding: 0 8%;
  }

  #cta-4 .cta-txt p {
    padding: 0 8%;
    margin-top: 10px;
  }

  #cta-4 .cta-btn {
    margin-top: 25px;
  }

  #cta-1 .cta-btn,
#cta-2 .cta-btn {
    margin-top: 25px;
  }

  #cta-5 .cta-btn {
    margin-top: 5px;
  }

  .cta-4-img {
    margin: 40px 15% 0;
  }

  /* BLOG */
  .all-posts-btn.m-top-30 {
    margin-top: 10px;
  }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-txt {
    margin-bottom: 20px;
  }

  #newsletter-1 .newsletter-form {
    margin: 0 10%;
  }

  #newsletter-1 .form-control {
    height: 54px;
    font-size: 1.05rem;
  }

  #newsletter-1 .newsletter-form .btn {
    height: 54px;
  }

  /* BOOKING */
  #booking-form .form-control {
    height: 50px;
  }

  #booking-form .btn {
    height: 50px;
  }

  /* CONTACTS */
  #contacts-4 {
    text-align: center;
  }

  #contacts-1.bg-clouds {
    padding-bottom: 180px;
  }

  #contacts-2.bg-clouds {
    padding-bottom: 100px;
  }

  #contacts-3.bg-clouds {
    padding-bottom: 100px;
  }

  #contacts-4 .contact-info {
    padding: 0 10%;
  }

  .contact-form .form-control {
    height: 48px;
    margin-bottom: 20px;
  }

  #contacts-2 .contact-box {
    margin-bottom: 20px;
  }

  #contacts-3 .contact-box {
    margin-bottom: 30px;
  }

  .contact-box .svg-inline--fa {
    font-size: 2.75rem;
  }

  .contact-box p {
    font-size: 1rem;
  }

  .contact-box h5 {
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .contacts-section h3.h3-lg {
    margin-bottom: 15px;
  }

  p.support-number-txt {
    margin-top: 20px;
  }

  span.support-number {
    font-size: 1.5rem;
  }

  #gmap {
    height: 320px;
    margin-top: 20px;
  }

  #contacts-2 #gmap {
    height: 280px;
    margin-bottom: 35px;
  }

  #contacts-map #gmap {
    height: 300px;
  }

  /* FOOTER */
  .footer-info {
    padding-right: 5%;
  }

  .footer h4,
.footer h5 {
    margin-bottom: 15px;
  }

  .footer-links li {
    margin: 0 0 8px 0;
  }

  .foo-links {
    margin: 8px auto 0;
  }

  .foo-links a {
    width: 38px;
    height: 38px;
    font-size: 1.05rem;
    line-height: 34px !important;
    margin-right: 3px;
  }

  .footer-form .form-control {
    font-size: 0.95rem;
    font-weight: 300;
  }

  .footer-form .svg-inline--fa {
    font-size: 1.25rem;
  }

  /* QUICK FORM */
  .bottom-form {
    min-width: 240px;
    max-width: 240px;
    right: 3.5em;
  }

  .bottom-form-header {
    padding: 11px 20px;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (max-width: 575px) {
  .animated {
    visibility: visible;
  }

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  html {
    font-size: 14px;
  }

  .double-section.wide-50 {
    padding-bottom: 0;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  /* Paragraphs */
  p.p-sm {
    font-size: 0.95rem;
  }

  /* 13.3px */
  p {
    font-size: 1rem;
  }

  /* 14px */
  p.p-md {
    font-size: 1rem;
  }

  /* 14px */
  p.p-lg {
    font-size: 1.035rem;
  }

  /* 14.5px */
  p.p-xl {
    font-size: 1.07rem;
  }

  /* 15px */
  /* Buttons */
  .btn.btn-sm {
    font-size: 0.875rem;
    padding: 0.7rem 1.6rem;
  }

  .btn {
    font-size: 0.95rem;
    padding: 0.8rem 1.75rem;
  }

  .btn.btn-md {
    font-size: 0.975rem;
    padding: 0.85rem 1.9rem;
  }

  .btn.btn-lg {
    font-size: 1rem;
    padding: 0.875rem 2rem;
  }

  .btn-arrow:hover span,
.btn-arrow:focus span {
    transform: translate3d(-7px, 0, 0);
  }

  .btn-arrow:hover .svg-inline--fa,
.btn-arrow:focus .svg-inline--fa {
    opacity: 1;
    right: -15px;
  }

  /* Video Popup Link */
  .modal-video {
    line-height: 1.75rem;
  }

  .modal-video a span {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-right: 3px;
  }

  .modal-video a {
    font-size: 1.05rem;
    line-height: 1.75rem;
  }

  /* Video Icon */
  .video-btn-xs,
.video-btn-sm,
.video-btn-md,
.video-btn-lg {
    padding: 10px;
  }

  .video-preview .video-btn-xs {
    margin-top: -30px;
    margin-left: -30px;
  }

  .video-preview .video-btn-sm {
    margin-top: -35px;
    margin-left: -35px;
  }

  .video-preview .video-btn-md {
    margin-top: -40px;
    margin-left: -40px;
  }

  .video-preview .video-btn-lg {
    margin-top: -40px;
    margin-left: -40px;
  }

  .video-btn-xs,
.video-btn .video-btn-xs {
    width: 80px;
    height: 80px;
  }

  .video-btn-sm,
.video-btn .video-btn-sm {
    width: 90px;
    height: 90px;
  }

  .video-btn-md,
.video-btn .video-btn-md {
    width: 100px;
    height: 100px;
  }

  .video-btn-lg,
.video-btn .video-btn-lg {
    width: 100px;
    height: 100px;
  }

  .video-btn-xs .svg-inline--fa {
    width: 60px;
    height: 60px;
  }

  .video-btn-sm .svg-inline--fa {
    width: 70px;
    height: 70px;
  }

  .video-btn-md .svg-inline--fa {
    width: 80px;
    height: 80px;
  }

  .video-btn-lg .svg-inline--fa {
    width: 80px;
    height: 80px;
  }

  .ico-bkg {
    top: 30px;
    left: 30px;
  }

  .video-btn-xs .ico-bkg {
    top: 25px;
    width: 30px;
    height: 30px;
  }

  .video-btn-sm .ico-bkg {
    top: 25px;
    left: 30px;
    width: 33px;
    height: 45px;
  }

  .video-btn-md .ico-bkg {
    width: 40px;
    height: 40px;
  }

  .video-btn-lg .ico-bkg {
    width: 40px;
    height: 45px;
  }

  /* Box Icons */
  .box-icon [class^=flaticon-]:before,
.box-icon [class*=" flaticon-"]:before,
.box-icon [class^=flaticon-]:after,
.box-icon [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-sm [class^=flaticon-]:before,
.box-icon-sm [class*=" flaticon-"]:before,
.box-icon-sm [class^=flaticon-]:after,
.box-icon-sm [class*=" flaticon-"]:after {
    font-size: 3.5rem;
    line-height: 3.5rem !important;
  }

  .box-icon-md [class^=flaticon-]:before,
.box-icon-md [class*=" flaticon-"]:before,
.box-icon-md [class^=flaticon-]:after,
.box-icon-md [class*=" flaticon-"]:after {
    font-size: 3.75rem;
    line-height: 3.75rem !important;
  }

  .box-icon-lg [class^=flaticon-]:before,
.box-icon-lg [class*=" flaticon-"]:before,
.box-icon-lg [class^=flaticon-]:after,
.box-icon-lg [class*=" flaticon-"]:after {
    font-size: 4.5rem;
    line-height: 4.5rem !important;
  }

  /* Technologies */
  .technologies {
    margin-top: 25px;
  }

  .technologies p {
    font-size: 0.85rem;
  }

  .technologies .svg-inline--fa {
    font-size: 2.4rem;
    margin-right: 8px;
  }

  /* Store Badges */
  a.store {
    margin-right: 8px;
  }

  .store img.appstore {
    width: 128px;
    height: 40px;
  }

  .store img.googleplay {
    width: 137px;
    height: 40px;
  }

  .store img.amazon {
    width: 126px;
    height: 44px;
  }

  .store img.windows {
    width: 131px;
    height: 40px;
  }

  /* App Devices Icons */
  .app-devices {
    padding: 5px 0 0 5px;
  }

  .app-devices .svg-inline--fa {
    font-size: 2.15rem;
  }

  .app-devices .svg-inline--fa.f-phone {
    margin-right: 1rem;
  }

  .app-devices-desc p {
    font-size: 0.95rem;
    padding-right: 5%;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
   /*------------------------------------------*/
  .navbar-brand {
    width: 50%;
    text-align: left;
  }

  .logo-black img {
    width: 82%;
    height: 82%;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  .hero-section {
    margin-top: 52px;
  }

  #hero-5 .hero-txt,
#hero-8 .hero-txt,
#hero-13-txt .hero-txt,
#hero-14 .hero-txt,
#hero-16 .hero-txt,
#hero-17 .hero-txt,
#hero-19 .hero-txt,
#hero-21 .hero-txt,
#hero-22 .hero-txt,
#hero-24 .hero-txt,
#hero-25 .hero-txt,
#hero-27 .hero-txt,
#hero-29 .hero-txt {
    text-align: center;
  }

  /* Hero Inner Content */
  .hero-inner-img img {
    border-radius: 6px 6px 0 0;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* INNER PAGE */
  .page-hero-section {
    margin-top: 58px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .page-hero-section h2 {
    font-size: 2.15rem;
    letter-spacing: -0.5px;
  }

  .page-hero-section p {
    padding: 0 5%;
    margin-top: 5px;
  }

  /* ABOUT */
  #about-2 .about-txt {
    margin-bottom: 0;
  }

  .about-txt h3.h3-sm {
    margin-bottom: 15px;
  }

  .about-btn {
    margin-top: 25px;
  }

  .abox-4,
#a4-1.abox-4 {
    padding: 40px 10px;
  }

  .abox-4 h5,
#a4-1.abox-4 h5 {
    margin-bottom: 15px;
  }

  #a4-1.abox-4 h5 {
    margin-bottom: 15px;
  }

  #a4-1 .btn {
    margin-top: 5px;
  }

  .about-form {
    margin: 0;
  }

  .about-form form {
    padding: 50px 40px 35px;
  }

  .layer-phone {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  /* SERVICES */
  #services-3 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .g-sboxes {
    margin-bottom: 50px;
  }

  .sbox-2 {
    padding: 0 15%;
  }

  .sbox-3 {
    margin-bottom: 40px;
  }

  .services-txt h3.h3-sm {
    margin-bottom: 15px;
  }

  .sbox-1-txt,
.sbox-3-txt {
    padding-left: 20px;
  }

  .sbox-4-txt {
    padding-left: 20px;
  }

  #services-4 .services-img {
    margin: 0 10%;
  }

  /* CONTENT */
  #content-5,
#content-6 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #content-4 {
    padding-bottom: 80px;
  }

  #content-7-title {
    padding-bottom: 140px;
  }

  #content-7-boxes {
    margin-top: -150px;
    padding-bottom: 40px;
  }

  #content-3 .content-txt {
    padding-left: 0;
    margin-bottom: 40px;
  }

  #content-4 .content-txt {
    padding-right: 0;
    margin-bottom: 40px;
  }

  #content-5 .content-txt,
#content-6 .content-txt {
    padding-left: 15px;
    padding-right: 15px;
  }

  #content-9 .content-txt {
    padding: 40px 25px;
  }

  #content-10 .content-txt {
    padding: 40px 25px 30px;
  }

  .content-txt h3.h3-sm,
.content-txt h3.h3-xs,
.content-txt h3.h3-xl {
    margin-bottom: 15px;
  }

  .content-txt h5.h5-sm {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .content-btn {
    margin-top: 25px;
  }

  .skills.m-top-40 {
    margin-top: 30px;
  }

  .cbox-txt {
    padding: 20px 20px 15px;
  }

  .cbox-txt h5 {
    margin-bottom: 10px;
  }

  .content-3-img,
.content-4-img {
    background-position: center center;
    position: relative;
    left: 0;
    height: 300px;
    width: auto !important;
    margin-left: 15px;
    margin-right: 15px;
  }

  .content-right-img {
    position: relative !important;
    display: inline-block;
    top: 0;
    right: 0;
    width: 90%;
    margin: 30px 35px 0;
  }

  .content-left-img {
    position: relative !important;
    display: inline-block;
    top: 0;
    left: 0;
    width: 90%;
    margin: 0 35px 40px;
  }

  /* PPRICING */
  #pills-tab {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .nav-pills {
    background-color: transparent;
    border: none;
  }

  .nav-pills .nav-link {
    width: 100%;
    float: none !important;
    font-size: 0.9rem;
    padding: 9px 22px;
    border: 1px solid #ccc;
    margin-left: 0;
    margin-bottom: 7px;
  }

  .pricing-notice .col-md-10.m-top-30 {
    margin-top: 0;
  }

  .pricing-notice .row.m-top-25.m-bottom-50 {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .pricing-txt {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  /* BANNER */
  #banner-1,
#banner-4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #banner-3 {
    padding-top: 80px;
  }

  #banner-1,
#banner-2 {
    text-align: center;
  }

  #banner-3 .banner-txt {
    margin-bottom: 30px;
  }

  #banner-1 .banner-txt h2,
#banner-3 .banner-txt h3,
#banner-4 .banner-txt h2 {
    margin-bottom: 15px;
  }

  #banner-3 .banner-txt h3 {
    margin-bottom: 15px;
  }

  #banner-2 .banner-txt h2 span {
    letter-spacing: -1.5px;
  }

  #banner-1 .banner-txt p,
#banner-2 .banner-txt p,
#banner-3 .banner-txt p {
    padding: 0 2%;
  }

  .banner-btn {
    margin-top: 25px;
  }

  #banner-2 .banner-img {
    margin: 0 10%;
  }

  /* VIDEO */
  #video-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #video-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #video-1 .video-btn.m-top-25 {
    margin-top: 15px;
  }

  #video-3 p {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 0 5%;
  }

  /* PORTFOLIO */
  #single-project {
    margin-top: 50px;
  }

  .btn-toolbar {
    display: block;
    margin-bottom: 50px;
  }

  .portfolio-filter button {
    width: 100%;
    display: block !important;
    padding: 0.75rem 0;
    float: none !important;
    font-size: 0.925rem;
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  .masonry-wrap .portfolio-item,
#portfolio-3 .masonry-wrap .portfolio-item {
    width: 100%;
  }

  /* STATISTIC */
  #statistic-2 p {
    padding: 0;
  }

  .statistic-title {
    margin-bottom: 30px;
  }

  .small-statistic {
    margin-top: 25px;
  }

  .small-statistic .col-sm-4 {
    width: 40%;
    float: left;
  }

  .statistic-number {
    font-size: 3.25rem;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  .small-statistic .statistic-number {
    font-size: 2.75rem;
    margin-bottom: 8px;
  }

  .statistic-block h5 {
    margin-bottom: 5px;
  }

  .small-statistic .statistic-block h5 {
    margin-left: 0;
  }

  /* SEARCH */
  #search-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .domain-names {
    margin-top: 25px;
  }

  .dn-box {
    padding: 15px 10px;
  }

  .search-form .form-control {
    height: 54px;
  }

  .search-form .btn {
    height: 54px;
    font-size: 1.05rem;
  }

  /* TESTIMONIALS */
  #reviews-2 {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .review-1 {
    padding: 0 10px;
  }

  .review-1 .testimonial-avatar img,
#reviews-2 img {
    width: 70px;
    height: 70px;
  }

  .review-1 p {
    font-size: 1rem;
  }

  #reviews-2 .review-txt p {
    font-size: 1.25rem;
    padding: 0px 5% 10px;
  }

  #reviews-2 .review-author p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  #reviews-2 .review-author span {
    font-size: 0.8rem;
  }

  /* BRANDS */
  #brands-1 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .brands-section {
    text-align: center;
  }

  #brands-2 .brands-title {
    margin-bottom: 40px;
  }

  .brands-title p {
    padding: 0;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    width: 50%;
    display: inline-block;
    padding: 0 5px;
  }

  #brands-3 .brand-logo {
    padding: 0 5px;
    margin-bottom: 0;
  }

  .brand-logo img {
    padding: 0;
  }

  /* FAQs */
  .questions-holder.ind-30 {
    padding: 0;
  }

  .question {
    margin-bottom: 25px;
  }

  .question h5 {
    margin-bottom: 5px;
  }

  .question p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .more-questions-btn {
    margin-top: 25px;
  }

  .faqs-btn {
    margin-top: 25px;
  }

  /* CALL TO ACTION */
  #cta-4 {
    padding-top: 0;
    text-align: center;
  }

  #cta-4 .bg-inner {
    padding-top: 80px;
  }

  #cta-5 {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }

  #cta-1 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  #cta-3 {
    margin-bottom: 0;
  }

  .banner-logo {
    margin-bottom: 15px;
  }

  .banner-logo img {
    width: 80px;
    height: 80px;
  }

  #cta-3,
#cta-3 .col-lg-6.text-right {
    text-align: center !important;
  }

  #cta-3 .cta-txt {
    margin-bottom: 15px;
  }

  .cta-txt h2.h2-lg {
    padding: 0 2%;
    letter-spacing: 0px;
    margin-bottom: 15px;
  }

  .cta-txt h2.h2-md,
.cta-txt h2.h2-xs {
    margin-bottom: 15px;
  }

  .cta-txt h2.h2-xs {
    padding: 0 8%;
  }

  #cta-1 .cta-txt p {
    padding: 0 5%;
  }

  #cta-2 .cta-txt p {
    padding: 0 5%;
  }

  #cta-3 .cta-txt p {
    padding: 0 8%;
  }

  #cta-1 .cta-btn,
#cta-2 .cta-btn {
    margin-top: 25px;
  }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-txt {
    margin-bottom: 20px;
  }

  #newsletter-1 .newsletter-form {
    margin: 0 10%;
  }

  #newsletter-1 .form-control {
    height: 54px;
    font-size: 1.05rem;
  }

  #newsletter-1 .newsletter-form .btn {
    height: 54px;
  }

  /* BLOG */
  a.share-ico {
    width: 70px;
    height: 30px;
    font-size: 15px;
    line-height: 30px !important;
    margin-right: 2px;
  }

  .comment-form .form-control {
    height: 42px;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .comment-form .btn {
    font-size: 1rem;
  }

  /* BOOKING */
  #booking-form .form-control {
    height: 50px;
  }

  #booking-form .btn {
    height: 50px;
  }

  /* CONTACTS */
  #contacts-4 {
    text-align: center;
  }

  #contacts-1.bg-clouds {
    padding-bottom: 160px;
  }

  #contacts-2.bg-clouds {
    padding-bottom: 100px;
  }

  #contacts-3.bg-clouds {
    padding-bottom: 80px;
  }

  #contacts-4 .contact-info {
    padding: 0 5%;
  }

  .contact-form .form-control {
    height: 48px;
    margin-bottom: 20px;
  }

  #contacts-2 .contact-box {
    margin-bottom: 20px;
  }

  #contacts-3 .contact-box {
    margin-bottom: 30px;
  }

  .form-btn.text-right {
    text-align: left !important;
  }

  .contact-box .svg-inline--fa {
    font-size: 2.75rem;
  }

  .contact-box p {
    font-size: 1rem;
  }

  .contact-box h5 {
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .contacts-section h3.h3-lg {
    margin-bottom: 15px;
  }

  p.support-number-txt {
    margin-top: 20px;
  }

  span.support-number {
    font-size: 1.5rem;
  }

  #gmap {
    height: 320px;
    margin-top: 20px;
  }

  #contacts-2 #gmap {
    height: 280px;
    margin-bottom: 35px;
  }

  #contacts-map #gmap {
    height: 280px;
  }

  /* FOOTER */
  .footer-info {
    padding-right: 5%;
  }

  .footer h4,
.footer h5 {
    margin-bottom: 15px;
  }

  .footer-links li {
    margin: 0 0 8px 0;
  }

  .foo-links {
    margin: 8px auto 0;
  }

  .foo-links a {
    width: 38px;
    height: 38px;
    font-size: 1.05rem;
    line-height: 34px !important;
    margin-right: 3px;
  }

  .footer-form .form-control {
    font-size: 0.95rem;
    font-weight: 300;
  }

  .footer-form .svg-inline--fa {
    font-size: 1.25rem;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 416px) and (max-width: 575.99px) {
  html {
    font-size: 14px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  /* Header H5 */
  h5.h5-xs {
    font-size: 1.125rem;
  }

  /* 15.75px */
  h5.h5-sm {
    font-size: 1.15rem;
  }

  /* 16.1px */
  h5.h5-md {
    font-size: 1.15rem;
  }

  /* 16.1px */
  h5.h5-lg {
    font-size: 1.16rem;
  }

  /* 16.25px */
  h5.h5-xl {
    font-size: 1.16rem;
  }

  /* 16.25px */
  h4.h4-xs {
    font-size: 1.2rem;
  }

  /* 16.8px */
  h4.h4-sm {
    font-size: 1.2rem;
  }

  /* 16.8px */
  h4.h4-md {
    font-size: 1.232rem;
  }

  /* 17.25px */
  h4.h4-lg {
    font-size: 1.283rem;
  }

  /* 17.96px */
  h4.h4-xl {
    font-size: 1.35rem;
  }

  /* 18.9px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 1.415rem;
  }

  /* 19.8px */
  h3.h3-sm {
    font-size: 1.47rem;
  }

  /* 20.5px */
  h3.h3-md {
    font-size: 1.55rem;
  }

  /* 21.7px */
  h3.h3-lg {
    font-size: 1.625rem;
  }

  /* 22.75px */
  h3.h3-xl {
    font-size: 1.7rem;
  }

  /* 23.8px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 1.74rem;
    line-height: 1.3;
  }

  /* 24.4px */
  h2.h2-sm {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  /* 25.2px */
  h2.h2-md {
    font-size: 1.9rem;
    line-height: 1.3;
  }

  /* 26.6px */
  h2.h2-lg {
    font-size: 2rem;
    line-height: 1.3;
  }

  /* 28px */
  h2.h2-xl {
    font-size: 2.1rem;
    line-height: 1.3;
  }

  /* 29.4px */
  /* Countdown */
  #clock .cbox {
    padding: 0;
  }

  #clock span.cbox-digit {
    font-size: 2.35rem;
    letter-spacing: 0;
  }

  #clock span.cbox-txt {
    font-size: 0.875rem;
  }

  /* Section ID */
  span.section-id {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1.05rem;
    padding: 0;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  #hero-1 {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-4 {
    margin-top: 58px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-6-content {
    padding-top: 80px;
    padding-bottom: 170px;
  }

  #hero-8 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-11-txt {
    padding-top: 80px;
    padding-bottom: 560px;
  }

  #hero-12 {
    padding-top: 70px;
  }

  #hero-13-txt {
    padding-top: 80px;
    padding-bottom: 110px;
  }

  #hero-14 {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  #hero-15-txt {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  #hero-16 {
    padding-top: 70px;
  }

  #hero-17 {
    padding-top: 70px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 80px;
  }

  #hero-19-txt {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  #hero-20 {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  #hero-21 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-22 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-23 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-24-content {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  #hero-25 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-26 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-27 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-28 {
    padding-top: 90px;
    padding-bottom: 50px;
  }

  #hero-29 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-30 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  #hero-25 .hero-txt {
    margin-bottom: 40px;
  }

  #hero-26 .hero-txt {
    margin-bottom: 20px;
  }

  .hero-price.text-right {
    text-align: left !important;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 450px;
  }

  .hero-section .slides {
    top: 49%;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 98%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 15px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  #hero-1.hero-section h2 {
    font-size: 1.9rem;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
  }

  .slides h2 {
    font-size: 2.25rem;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }

  #hero-9 .slides h2 {
    font-size: 2.5rem;
  }

  #hero-4.hero-section h2 {
    font-size: 2rem;
  }

  #hero-7.hero-section h2 {
    font-size: 2.5rem;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-10.hero-section h2 {
    font-size: 2.5rem;
  }

  #hero-10.hero-section h4 {
    padding: 0 15%;
    line-height: 1.4;
  }

  #hero-16 h2 {
    font-size: 2.5rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-17 h2 {
    padding: 0 4%;
  }

  #hero-18 h3.h3-xl {
    line-height: 1.3;
    margin-bottom: 25px;
    padding: 0 2%;
  }

  #hero-19 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }

  #hero-20 h4 {
    padding: 4px 15px;
  }

  #hero-20 h2 {
    font-size: 2.5rem;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 2.25rem;
    margin-bottom: 20px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 2.25rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 6rem;
    letter-spacing: -3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 1.5rem;
  }

  #hero-25 .hero-txt h2 {
    font-size: 2.25rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h2 {
    font-size: 3em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 2.25em;
  }

  #hero-29 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 2rem;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0 2%;
    margin-bottom: 25px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1.05rem;
    margin-bottom: 25px;
  }

  .hero-inner-txt p {
    margin-bottom: 25px;
  }

  #hero-1 .hero-txt p,
#hero-5 .hero-txt p,
#hero-15 .hero-txt p {
    padding: 0;
  }

  #hero-2 .hero-txt p,
#hero-4 .hero-txt p,
#hero-6 .hero-txt p,
#hero-8 .hero-txt p,
#hero-11 .hero-txt p,
#hero-3 .hero-txt p,
#hero-9 .hero-txt p,
#hero-11 .hero-txt p,
#hero-18 .hero-txt p {
    padding: 0 2%;
  }

  #hero-14 .hero-txt p {
    padding-right: 5%;
  }

  #hero-16 .hero-txt p {
    padding: 0 5%;
    margin-bottom: 5px;
  }

  #hero-19 .hero-txt p,
#hero-21 .hero-txt p,
#hero-24 .hero-txt p,
#hero-29 .hero-txt p {
    padding: 0 2%;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding: 0 2%;
  }

  #hero-23 .hero-txt p {
    padding: 0 2%;
  }

  #hero-24 .hero-txt p {
    margin-top: 20px;
    padding: 0 5%;
  }

  /* Hero Buttons */
  .hero-btns {
    margin-top: 18px;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin: -540px 5px 0;
  }

  .hero-inner-txt {
    padding: 40px;
    text-align: left !important;
  }

  .hero-inner-img img {
    border-radius: 6px 6px 0 0;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin: -65px 15% 0;
    text-align: center;
    padding: 35px 5%;
  }

  .discount-banner h2.h2-xl {
    font-size: 4rem;
  }

  /* Hero Links */
  .hero-links {
    margin-top: 8px;
  }

  .hero-links span {
    display: block;
    font-size: 0.95rem;
    margin: 0;
    margin-bottom: 12px;
  }

  /* Hero Statistic */
  .hero-statistic {
    margin: -70px 15% 0;
    padding: 30px 20px 0;
  }

  .hero-number {
    font-size: 1.65rem;
    margin-bottom: 5px;
  }

  /* Hero Register Form */
  .hero-form form {
    padding: 55px 40px 55px;
    margin: 40px 4% 0;
  }

  #hero-24 .hero-form form {
    padding: 45px 25px 45px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 25px 5% 0;
  }

  .hero-section .newsletter-form .form-control {
    height: 44px;
    font-size: 1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 44px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -130px;
  }

  #hero-8 .hero-img {
    margin: 40px 12% 0;
  }

  .hero-14-img {
    position: relative !important;
    top: 0;
    right: 0;
    width: 90%;
    margin: 30px 4% 0;
  }

  #hero-16 .hero-img {
    margin: 0 10%;
  }

  #hero-17 .hero-img {
    margin: 30px 10% 0;
  }

  #hero-18 .hero-img {
    margin-top: 40px;
  }

  #hero-21 .hero-img {
    margin: 40px 10% 0;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -100px;
  }

  #hero-28-boxes {
    margin-top: 40px;
  }

  .hbox-1 {
    padding: 35px 30px;
    margin-top: 30px;
    text-align: center;
  }

  .hbox-2 {
    padding-right: 10%;
    margin-bottom: 30px;
  }

  .hbox-1 h5 {
    margin-bottom: 12px;
  }

  .hbox-2 h5 {
    margin-bottom: 10px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* ABOUT */
  #about-4 {
    padding-bottom: 0;
  }

  /* CONTENT */
  .content-txt h2.h2-huge {
    font-size: 2.15rem;
    margin-bottom: 20px;
  }

  /* BRANDS */
  .brands-section {
    width: 100%;
    text-align: center;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    padding: 0 20px;
    margin-bottom: 0px;
  }

  #brands-2 .col-sm-4 {
    width: 50%;
    float: left !important;
  }

  #brands-2 .brand-logo {
    padding: 0 20px;
    margin-bottom: 15px;
  }

  .small-statistic .col-sm-4 {
    width: 33.333%;
  }

  .small-statistic .col-sm-4 .statistic-block {
    margin-bottom: 0;
  }

  /* SCHEDULE */
  .nav-tabs {
    margin-bottom: 40px;
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem 0;
    background-color: rgba(220, 220, 220, 0.6);
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .schedule-event .col-sm-3 {
    width: 28%;
    float: left !important;
  }

  .schedule-event .col-sm-9 {
    width: 72%;
    float: left !important;
  }

  .lrow {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }

  .schedule-event {
    margin-bottom: 5px;
  }

  .event-time span {
    font-size: 1rem;
    padding-left: 10px;
  }

  .schedule-buttons.m-top-20 {
    margin-top: 10px;
  }

  .schedule-buttons .btn.m-right-20 {
    margin-right: 0;
  }

  .schedule-buttons.m-top-20,
.schedule-buttons.m-top-30 {
    margin-top: 10px;
  }

  .agenda-tittle {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 20px 30px;
  }

  .agenda-tittle span {
    font-size: 1rem;
  }

  /* CALL TO ACTION */
  #cta-4 .cta-txt p {
    padding: 0 8%;
    margin-top: 10px;
  }

  #cta-4 .cta-btn {
    margin-top: 25px;
  }

  .cta-4-img {
    margin: 40px 15% 0;
  }

  #cta-5 {
    padding-left: 4%;
    padding-right: 4%;
  }

  #cta-5 .btn {
    margin-top: 8px;
  }

  /* BOOKING FORM */
  #booking-form {
    margin: 0;
  }

  /* QUICK FORM */
  #quick-form {
    display: none;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (min-width: 321px) and (max-width: 415px) {
  html {
    font-size: 14px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  /* Header H5 */
  h5.h5-xs {
    font-size: 1rem;
  }

  /* 14px */
  h5.h5-sm {
    font-size: 1.05rem;
  }

  /* 14.7px */
  h5.h5-md {
    font-size: 1.1rem;
  }

  /* 15.4px */
  h5.h5-lg {
    font-size: 1.1rem;
  }

  /* 15.4px */
  h5.h5-xl {
    font-size: 1.1rem;
  }

  /* 15.4px */
  /* Header H4 */
  h4.h4-xs {
    font-size: 1.1rem;
    line-height: 1.3;
  }

  /* 15.4px */
  h4.h4-sm {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-md {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-lg {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-xl {
    font-size: 1.2rem;
    line-height: 1.3;
  }

  /* 16.8px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 1.283rem;
    line-height: 1.3;
  }

  /* 17.96px */
  h3.h3-sm {
    font-size: 1.35rem;
    line-height: 1.3;
  }

  /* 18.9px */
  h3.h3-md {
    font-size: 1.415rem;
    line-height: 1.3;
  }

  /* 19.8px */
  h3.h3-lg {
    font-size: 1.465rem;
    line-height: 1.3;
  }

  /* 20.5px */
  h3.h3-xl {
    font-size: 1.465rem;
    line-height: 1.3;
  }

  /* 20.5px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 1.515rem;
    line-height: 1.3;
  }

  /* 21.21px */
  h2.h2-sm {
    font-size: 1.52rem;
    line-height: 1.3;
  }

  /* 21.7px */
  h2.h2-md {
    font-size: 1.58rem;
    line-height: 1.3;
  }

  /* 22.12px */
  h2.h2-lg {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  /* 23.8px */
  h2.h2-xl {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  /* 23.8px */
  /* Store Badges */
  a.store {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
  }

  /* Countdown */
  #clock .cbox {
    padding: 0;
  }

  #clock span.cbox-digit {
    font-size: 2.15rem;
    letter-spacing: 0;
  }

  /* Section ID */
  span.section-id {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1rem;
    padding: 0;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  #hero-1 {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  #hero-3 {
    background-position: right center;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-4 {
    background-position: left bottom;
    margin-top: 52px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-5 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #hero-6-content {
    padding-top: 60px;
    padding-bottom: 130px;
  }

  #hero-8 {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-11-txt {
    padding-top: 60px;
    padding-bottom: 500px;
  }

  #hero-12 {
    padding-top: 60px;
  }

  #hero-13-txt {
    padding-top: 70px;
    padding-bottom: 110px;
  }

  #hero-14 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #hero-15-txt {
    padding-top: 70px;
    padding-bottom: 140px;
  }

  #hero-16 {
    padding-top: 60px;
  }

  #hero-17 {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 70px;
  }

  #hero-19-txt {
    padding-top: 100px;
    padding-bottom: 130px;
  }

  #hero-20 {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  #hero-21 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-22 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-23 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-24-content {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  #hero-25 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-26 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-27 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-28 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  #hero-29 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-30 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  #hero-25 .hero-txt {
    margin-bottom: 40px;
  }

  #hero-26 .hero-txt {
    margin-bottom: 20px;
  }

  .hero-price.text-right {
    text-align: left !important;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 420px;
  }

  .hero-section .slides {
    top: 49%;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 98%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 15px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  #hero-1.hero-section h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }

  .slides h2 {
    font-size: 1.65rem;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }

  #hero-9 .slides h2 {
    font-size: 1.75rem;
  }

  #hero-4.hero-section h2 {
    font-size: 1.9rem;
  }

  #hero-7.hero-section h2 {
    font-size: 1.75rem;
    letter-spacing: -2px;
    margin-bottom: 10px;
  }

  #hero-10.hero-section h2 {
    font-size: 1.75rem;
  }

  #hero-10.hero-section h4 {
    padding: 0 15%;
    line-height: 1.4;
  }

  #hero-7.hero-section h4 {
    font-size: 1.05rem;
    line-height: 1.4;
    padding: 0 15%;
  }

  #hero-8.hero-section h2 {
    padding: 0 4%;
  }

  #hero-16 h2 {
    font-size: 1.75rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-17 h2 {
    padding: 0 3%;
  }

  #hero-18 h3.h3-xl {
    margin-bottom: 25px;
    padding: 0 2%;
  }

  #hero-19 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  #hero-20 h4 {
    padding: 0;
    background-color: transparent;
  }

  #hero-20 h2 {
    font-size: 2rem;
    margin-top: 8px;
    margin-bottom: 35px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 2rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 5rem;
    letter-spacing: -3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 1.25rem;
  }

  #hero-25 .hero-txt h2 {
    font-size: 2rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.05rem;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h2 {
    font-size: 2.5em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h2 {
    font-size: 5em;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 2em;
  }

  #hero-29 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 2rem;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0;
    margin-bottom: 25px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .hero-inner-txt p {
    margin-bottom: 25px;
  }

  #hero-1 .hero-txt p,
#hero-15 .hero-txt p {
    padding: 0;
  }

  #hero-4 .hero-txt p,
#hero-5 .hero-txt p,
#hero-8 .hero-txt p,
#hero-11 .hero-txt p,
#hero-17 .hero-txt p,
#hero-18 .hero-txt p {
    padding: 0 2%;
  }

  #hero-2 .hero-txt p,
#hero-3 .hero-txt p,
#hero-6 .hero-txt p,
#hero-9 .hero-txt p {
    padding: 0 5%;
  }

  #hero-14 .hero-txt p {
    padding-right: 5%;
  }

  #hero-16 .hero-txt p {
    padding: 0 2%;
    margin-bottom: 5px;
  }

  #hero-19 .hero-txt p,
#hero-21 .hero-txt p,
#hero-24 .hero-txt p,
#hero-29 .hero-txt p {
    padding: 0 2%;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding: 0 2%;
  }

  #hero-23 .hero-txt p {
    padding: 0 2%;
  }

  #hero-24 .hero-txt p {
    margin-top: 20px;
    padding: 0 5%;
  }

  /* Hero Buttons */
  .hero-btns {
    margin-top: 18px;
  }

  #hero-7 .hero-btns {
    padding: 0 10%;
  }

  #hero-7 .btn.m-right-15 {
    margin: 0 0 12px;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin: -490px 5px 0;
  }

  .hero-inner-txt {
    padding: 40px 30px;
    text-align: left !important;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin: -65px 4% 0;
    text-align: center;
    padding: 35px 5%;
  }

  .discount-banner h2.h2-xl {
    font-size: 4rem;
  }

  /* Hero Links */
  .hero-links {
    margin-top: 8px;
  }

  .hero-links span {
    display: block;
    font-size: 0.95rem;
    margin: 0;
    margin-bottom: 12px;
  }

  /* Hero Statistic */
  .hero-statistic {
    margin: -90px 10% 0;
    padding: 30px 20px 0;
  }

  .hero-number {
    font-size: 1.65rem;
    margin-bottom: 5px;
  }

  /* Hero Register Form */
  .hero-form form {
    padding: 40px 30px 40px;
    margin: 40px 2% 0;
  }

  #hero-24 .hero-form form {
    padding: 45px 20px 45px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 25px 2% 0;
  }

  .hero-section .newsletter-form .form-control {
    height: 44px;
    font-size: 1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 44px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -90px;
  }

  #hero-8 .hero-img {
    margin: 35px 5% 0;
  }

  .hero-14-img {
    position: relative !important;
    top: 0;
    right: 0;
    width: 90%;
    margin: 30px 4% 0;
  }

  #hero-16 .hero-img {
    margin: 0 5%;
  }

  #hero-17 .hero-img {
    margin: 30px 5% 0;
  }

  #hero-18 .hero-img {
    margin-top: 40px;
  }

  #hero-21 .hero-img {
    margin: 40px 10% 0;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -100px;
  }

  #hero-28-boxes {
    margin-top: 40px;
  }

  .hbox-1 {
    padding: 35px 30px;
    margin-top: 30px;
    text-align: center;
  }

  .hbox-2 {
    padding-right: 10%;
    margin-bottom: 30px;
  }

  .hbox-1 h5 {
    margin-bottom: 12px;
  }

  .hbox-2 h5 {
    margin-bottom: 10px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* ABOUT */
  #about-4 {
    padding-bottom: 0;
  }

  .about-form form {
    padding: 50px 20px 35px;
  }

  /* INNER PAGE */
  .page-hero-section {
    margin-top: 50px;
  }

  /* CONTENT */
  .content-3-img,
.content-4-img {
    height: 200px;
  }

  .content-txt h2.h2-huge {
    font-size: 1.85rem;
    margin-bottom: 20px;
  }

  /* STATISTIC */
  .small-statistic .col-sm-4 {
    width: 33.333%;
  }

  .small-statistic .col-sm-4 .statistic-block {
    margin-bottom: 0;
  }

  /* SCHEDULE */
  .nav-tabs {
    margin-bottom: 40px;
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem 0;
    background-color: rgba(220, 220, 220, 0.6);
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .schedule-event .col-sm-3 {
    width: 100%;
    float: none !important;
    text-align: center;
  }

  .schedule-event .col-sm-9 {
    width: 100%;
    float: none !important;
    text-align: center;
  }

  .schedule-event {
    margin-bottom: 5px;
  }

  .event-time {
    margin-bottom: 10px;
  }

  .event-time span {
    font-size: 1rem;
    padding-left: 0;
  }

  .event-description {
    padding-left: 15px;
  }

  .lrow {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }

  .schedule-buttons.m-top-20,
.schedule-buttons.m-top-30 {
    margin-top: 10px;
  }

  .schedule-buttons .btn.m-right-20 {
    margin-right: 0;
  }

  .agenda-tittle {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 15px 20px;
  }

  .agenda-tittle span {
    font-size: 1rem;
  }

  /* BRANDS */
  .brands-section {
    width: 100%;
    text-align: center;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    padding: 0 10px;
  }

  #brands-2 .col-sm-4 {
    width: 50%;
    float: left !important;
  }

  #brands-2 .brand-logo {
    padding: 0 10px;
  }

  /* CALL TO ACTION */
  #cta-4 .cta-txt p {
    padding: 0 8%;
    margin-top: 10px;
  }

  #cta-4 .cta-btn {
    margin-top: 25px;
  }

  .cta-4-img {
    margin: 40px 15% 0;
  }

  #cta-5 {
    padding-left: 4%;
    padding-right: 4%;
  }

  #cta-5 .btn {
    margin-top: 8px;
  }

  /* BOOKING FORM */
  #booking-form {
    margin: 0;
  }

  /* CONTACTS */
  #contacts-1.bg-clouds {
    padding-bottom: 140px;
  }

  #contacts-4 .contact-info {
    padding: 0;
  }

  #contacts-map {
    margin-top: 30px;
  }

  /* QUICK FORM */
  #quick-form {
    display: none;
  }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (max-width: 320px) {
  html {
    font-size: 14px;
  }

  /*------------------------------------------*/
  /*   BASE
   /*------------------------------------------*/
  /* Header H5 */
  h5.h5-xs {
    font-size: 1rem;
  }

  /* 14px */
  h5.h5-sm {
    font-size: 1.05rem;
  }

  /* 14.7px */
  h5.h5-md {
    font-size: 1.1rem;
  }

  /* 15.4px */
  h5.h5-lg {
    font-size: 1.1rem;
  }

  /* 15.4px */
  h5.h5-xl {
    font-size: 1.1rem;
  }

  /* 15.4px */
  /* Header H4 */
  h4.h4-xs {
    font-size: 1.1rem;
    line-height: 1.3;
  }

  /* 15.4px */
  h4.h4-sm {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-md {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-lg {
    font-size: 1.15rem;
    line-height: 1.3;
  }

  /* 16.1px */
  h4.h4-xl {
    font-size: 1.2rem;
    line-height: 1.3;
  }

  /* 16.8px */
  /* Header H3 */
  h3.h3-xs {
    font-size: 1.283rem;
    line-height: 1.3;
  }

  /* 17.96px */
  h3.h3-sm {
    font-size: 1.35rem;
    line-height: 1.3;
  }

  /* 18.9px */
  h3.h3-md {
    font-size: 1.415rem;
    line-height: 1.3;
  }

  /* 19.8px */
  h3.h3-lg {
    font-size: 1.465rem;
    line-height: 1.3;
  }

  /* 20.5px */
  h3.h3-xl {
    font-size: 1.465rem;
    line-height: 1.3;
  }

  /* 20.5px */
  /* Header H2 */
  h2.h2-xs {
    font-size: 1.515rem;
    line-height: 1.3;
  }

  /* 21.21px */
  h2.h2-sm {
    font-size: 1.52rem;
    line-height: 1.3;
  }

  /* 21.7px */
  h2.h2-md {
    font-size: 1.58rem;
    line-height: 1.3;
  }

  /* 22.12px */
  h2.h2-lg {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  /* 23.8px */
  h2.h2-xl {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  /* 23.8px */
  /* Store Badges */
  a.store {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
  }

  /* Countdown */
  #clock .cbox {
    padding: 0;
  }

  #clock span.cbox-digit {
    font-size: 2.15rem;
    letter-spacing: 0;
  }

  /* Section ID */
  span.section-id {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
  }

  .section-title p {
    font-size: 1rem;
    padding: 0;
  }

  /*------------------------------------------*/
  /*   HERO
   /*------------------------------------------*/
  #hero-1 {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  #hero-3 {
    background-position: right center;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-4 {
    background-position: left bottom;
    margin-top: 52px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-5 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #hero-6-content {
    padding-top: 60px;
    padding-bottom: 130px;
  }

  #hero-8 {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  #hero-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-11-txt {
    padding-top: 60px;
    padding-bottom: 500px;
  }

  #hero-12 {
    padding-top: 60px;
  }

  #hero-13-txt {
    padding-top: 70px;
    padding-bottom: 110px;
  }

  #hero-14 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #hero-15-txt {
    padding-top: 70px;
    padding-bottom: 140px;
  }

  #hero-16 {
    padding-top: 60px;
  }

  #hero-17 {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  #hero-18 {
    padding-top: 70px;
  }

  #hero-19-txt {
    padding-top: 100px;
    padding-bottom: 130px;
  }

  #hero-20 {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  #hero-21 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-22 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-23 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #hero-24-content {
    padding-top: 80px;
    padding-bottom: 130px;
  }

  #hero-25 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-26 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-27 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-28 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  #hero-29 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #hero-30 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  #hero-25 .hero-txt {
    margin-bottom: 40px;
  }

  #hero-26 .hero-txt {
    margin-bottom: 20px;
  }

  .hero-price.text-right {
    text-align: left !important;
  }

  /* Hero Text Rotator */
  .hero-section .hero-slider {
    height: 420px;
  }

  .hero-section .slides {
    top: 49%;
  }

  /* Hero Fullscreen Slider */
  .hero-content {
    width: 98%;
  }

  /* Hero Headers */
  .hero-section h4,
.hero-section h3,
.hero-section h2 {
    margin-bottom: 15px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  #hero-1.hero-section h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }

  .slides h2 {
    font-size: 1.65rem;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }

  #hero-9 .slides h2 {
    font-size: 1.75rem;
  }

  #hero-4.hero-section h2 {
    font-size: 1.9rem;
  }

  #hero-7.hero-section h2 {
    font-size: 1.75rem;
    letter-spacing: -2px;
    margin-bottom: 10px;
  }

  #hero-10.hero-section h2 {
    font-size: 1.75rem;
  }

  #hero-10.hero-section h4 {
    padding: 0 15%;
    line-height: 1.4;
  }

  #hero-7.hero-section h4 {
    font-size: 1.05rem;
    line-height: 1.4;
    padding: 0 15%;
  }

  #hero-8.hero-section h2 {
    padding: 0 4%;
  }

  #hero-16 h2 {
    font-size: 1.75rem;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  #hero-17 h2 {
    padding: 0 3%;
  }

  #hero-18 h3.h3-xl {
    margin-bottom: 25px;
    padding: 0 2%;
  }

  #hero-19 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  #hero-20 h4 {
    padding: 0;
    background-color: transparent;
  }

  #hero-20 h2 {
    font-size: 2rem;
    margin-top: 8px;
    margin-bottom: 35px;
  }

  #hero-21 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  #hero-22 .hero-txt h2 {
    font-size: 2rem;
  }

  #hero-23 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  #hero-24 .hero-txt h2 {
    font-size: 5rem;
    letter-spacing: -3px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #hero-24 .hero-txt h3 {
    font-size: 1.25rem;
  }

  #hero-25 .hero-txt h2 {
    font-size: 2rem;
  }

  #hero-26 .hero-txt h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  #hero-26 .hero-txt h3 {
    font-size: 1.05rem;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h2 {
    font-size: 2.5em;
    margin-bottom: 5px;
  }

  #hero-26 .hero-price h4 {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  #hero-28 .hero-txt h2 {
    font-size: 5em;
    letter-spacing: -2px;
    margin-bottom: 20px;
  }

  #hero-28 .hero-txt h3 {
    font-size: 2em;
  }

  #hero-29 .hero-txt h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h2 {
    font-size: 2rem;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  #hero-30 .hero-txt h5 {
    padding: 0;
    margin-bottom: 25px;
  }

  .hero-inner-txt h3 {
    margin-bottom: 20px;
  }

  .hero-section h4 span,
.hero-section h3 span,
.hero-section h2 span {
    letter-spacing: -1px;
    padding-bottom: 11px;
  }

  /* Hero Paragraphs */
  .hero-txt p.p-hero {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .hero-inner-txt p {
    margin-bottom: 25px;
  }

  #hero-1 .hero-txt p,
#hero-15 .hero-txt p {
    padding: 0;
  }

  #hero-4 .hero-txt p,
#hero-5 .hero-txt p,
#hero-8 .hero-txt p,
#hero-11 .hero-txt p,
#hero-17 .hero-txt p,
#hero-18 .hero-txt p {
    padding: 0 2%;
  }

  #hero-2 .hero-txt p,
#hero-3 .hero-txt p,
#hero-6 .hero-txt p,
#hero-9 .hero-txt p {
    padding: 0 5%;
  }

  #hero-14 .hero-txt p {
    padding-right: 5%;
  }

  #hero-16 .hero-txt p {
    padding: 0 2%;
    margin-bottom: 5px;
  }

  #hero-19 .hero-txt p,
#hero-21 .hero-txt p,
#hero-24 .hero-txt p,
#hero-29 .hero-txt p {
    padding: 0 2%;
  }

  #hero-22 .hero-txt p,
#hero-27 .hero-txt p {
    padding: 0 2%;
  }

  #hero-23 .hero-txt p {
    padding: 0 2%;
  }

  #hero-24 .hero-txt p {
    margin-top: 20px;
    padding: 0 5%;
  }

  /* Hero Buttons */
  .hero-btns {
    margin-top: 18px;
  }

  #hero-7 .hero-btns {
    padding: 0 10%;
  }

  #hero-7 .btn.m-right-15 {
    margin: 0 0 12px;
  }

  /* Hero Inner Content */
  .hero-inner-block {
    margin: -490px 5px 0;
  }

  .hero-inner-txt {
    padding: 40px 30px;
    text-align: left !important;
  }

  /* Hero Discount Banner */
  .discount-banner {
    margin: -65px 4% 0;
    text-align: center;
    padding: 35px 5%;
  }

  .discount-banner h2.h2-xl {
    font-size: 4rem;
  }

  /* Hero Links */
  .hero-links {
    margin-top: 8px;
  }

  .hero-links span {
    display: block;
    font-size: 0.95rem;
    margin: 0;
    margin-bottom: 12px;
  }

  /* Hero Statistic */
  .hero-statistic {
    margin: -90px 10% 0;
    padding: 30px 20px 0;
  }

  .hero-number {
    font-size: 1.65rem;
    margin-bottom: 5px;
  }

  /* Hero Register Form */
  .hero-form form {
    padding: 40px 30px 40px;
    margin: 40px 2% 0;
  }

  #hero-24 .hero-form form {
    padding: 45px 20px 45px;
  }

  /* Hero Newsletter Form */
  .hero-section .newsletter-form {
    margin: 25px 2% 0;
  }

  .hero-section .newsletter-form .form-control {
    height: 44px;
    font-size: 1rem;
  }

  .hero-section .newsletter-form .btn {
    height: 44px;
  }

  /* Hero Images */
  .hero-6-image img {
    margin-top: -90px;
  }

  #hero-8 .hero-img {
    margin: 35px 5% 0;
  }

  .hero-14-img {
    position: relative !important;
    top: 0;
    right: 0;
    width: 90%;
    margin: 30px 4% 0;
  }

  #hero-16 .hero-img {
    margin: 0 5%;
  }

  #hero-17 .hero-img {
    margin: 30px 5% 0;
  }

  #hero-18 .hero-img {
    margin-top: 40px;
  }

  #hero-21 .hero-img {
    margin: 40px 10% 0;
  }

  /* Hero Boxes */
  .hero-19-boxes {
    margin-top: -100px;
  }

  #hero-28-boxes {
    margin-top: 40px;
  }

  .hbox-1 {
    padding: 35px 30px;
    margin-top: 30px;
    text-align: center;
  }

  .hbox-2 {
    padding-right: 10%;
    margin-bottom: 30px;
  }

  .hbox-1 h5 {
    margin-bottom: 12px;
  }

  .hbox-2 h5 {
    margin-bottom: 10px;
  }

  /*------------------------------------------*/
  /*   CONTENT
   /*------------------------------------------*/
  /* ABOUT */
  #about-4 {
    padding-bottom: 0;
  }

  .about-form form {
    padding: 50px 20px 35px;
  }

  /* INNER PAGE */
  .page-hero-section {
    margin-top: 50px;
  }

  /* CONTENT */
  .content-3-img,
.content-4-img {
    height: 200px;
  }

  .content-txt h2.h2-huge {
    font-size: 1.85rem;
    margin-bottom: 20px;
  }

  /* STATISTIC */
  .small-statistic .col-sm-4 {
    width: 33.333%;
  }

  .small-statistic .col-sm-4 .statistic-block {
    margin-bottom: 0;
  }

  /* SCHEDULE */
  .nav-tabs {
    margin-bottom: 40px;
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem 0;
    background-color: rgba(220, 220, 220, 0.6);
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .schedule-event .col-sm-3 {
    width: 100%;
    float: none !important;
    text-align: center;
  }

  .schedule-event .col-sm-9 {
    width: 100%;
    float: none !important;
    text-align: center;
  }

  .schedule-event {
    margin-bottom: 5px;
  }

  .event-time {
    margin-bottom: 10px;
  }

  .event-time span {
    font-size: 1rem;
    padding-left: 0;
  }

  .event-description {
    padding-left: 15px;
  }

  .lrow {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }

  .schedule-buttons.m-top-20,
.schedule-buttons.m-top-30 {
    margin-top: 10px;
  }

  .schedule-buttons .btn.m-right-20 {
    margin-right: 0;
  }

  .agenda-tittle {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 15px 20px;
  }

  .agenda-tittle span {
    font-size: 1rem;
  }

  /* BRANDS */
  .brands-section {
    width: 100%;
    text-align: center;
  }

  #pricing-2 .brand-logo,
#brands-1 .brand-logo {
    padding: 0 10px;
  }

  #brands-2 .col-sm-4 {
    width: 50%;
    float: left !important;
  }

  #brands-2 .brand-logo {
    padding: 0 10px;
  }

  /* CALL TO ACTION */
  #cta-4 .cta-txt p {
    padding: 0 8%;
    margin-top: 10px;
  }

  #cta-4 .cta-btn {
    margin-top: 25px;
  }

  .cta-4-img {
    margin: 40px 15% 0;
  }

  #cta-5 {
    padding-left: 4%;
    padding-right: 4%;
  }

  #cta-5 .btn {
    margin-top: 8px;
  }

  /* BOOKING FORM */
  #booking-form {
    margin: 0;
  }

  /* CONTACTS */
  #contacts-1.bg-clouds {
    padding-bottom: 140px;
  }

  #contacts-4 .contact-info {
    padding: 0;
  }

  #contacts-map {
    margin-top: 30px;
  }

  /* QUICK FORM */
  #quick-form {
    display: none;
  }
}
.pictogram {
  width: 4rem;
  height: 4rem;
}
.pictogram-sm {
  width: 3.5rem;
  height: 3.5rem;
}
.pictogram-md {
  width: 4.5rem;
  height: 4.5rem;
}
.pictogram-lg {
  width: 5rem;
  height: 5rem;
}
@media (min-width: 992px) and (max-width: 1199.99px) {
  .pictogram {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-sm {
    width: 3.25rem;
    height: 3.25rem;
  }
  .pictogram-md {
    width: 4rem;
    height: 4rem;
  }
  .pictogram-lg {
    width: 4.5rem;
    height: 4.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991.99px) {
  .pictogram {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-sm {
    width: 3.25rem;
    height: 3.25rem;
  }
  .pictogram-md {
    width: 4rem;
    height: 4rem;
  }
  .pictogram-lg {
    width: 4.5rem;
    height: 4.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767.99px) {
  .pictogram {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-sm {
    width: 3.5rem;
    height: 3.5rem;
  }
  .pictogram-md {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-lg {
    width: 4.25rem;
    height: 4.25rem;
  }
}
@media (max-width: 575px) {
  .pictogram {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-sm {
    width: 3.5rem;
    height: 3.5rem;
  }
  .pictogram-md {
    width: 3.75rem;
    height: 3.75rem;
  }
  .pictogram-lg {
    width: 4.5rem;
    height: 4.5rem;
  }
}
html {
  background-color: #0d0337;
}
body {
  font-weight: 500;
}
small {
  display: block;
}
a, button, .btn {
  transition-property: color;
}
.navbar {
  transition-property: background-color, padding;
}
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*# sourceMappingURL=index.3ea06061.css.map */
