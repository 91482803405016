/* ==========================================================================
  16. PRICING TABLES
  =========================================================================== */

#pricing-2-title {
	position: relative;
	z-index: 3;
	padding-top: 80px;
	padding-bottom: 320px;
}

#pricing-2-title.bg-deepgreen,
#pricing-2-title.bg-purple {
	background-image: url('/vendor/pergo/images/tra-bg.png');
}

#pricing-2-content {
	margin-top: -330px;
	padding-bottom: 80px;
	position: relative;
	z-index: 999;
}

/*------------------------------------------*/
/*   PRICING PAGE TABS FILTER
/*------------------------------------------*/

#pills-tab {
	margin: 30px auto 40px;
}

.nav-pills {
	background-color: rgba(240, 240, 240, 0.3);
	border: 1px solid #ccc;
	border-radius: 100px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #e35029;
	border-color: #e35029;
	color: #fff;
}

.nav-pills.rose-navi .nav-link.active {
	background-color: #ff3366;
	border-color: #ff3366;
	color: #fff;
}

.nav-pills.green-navi .nav-link.active {
	background-color: #22bc3f;
	border-color: #22bc3f;
}

.nav-pills.blue-navi .nav-link.active {
	background-color: #3176ed;
	border-color: #3176ed;
}

.nav-pills .nav-link {
	background-color: transparent;
	border: 1px solid transparent;
	color: #555;
	font-size: 1rem;
	font-weight: 400;
	padding: 10px 30px;
	margin-right: 0px;
	border-radius: 100px;
	border: 1px solid transparent;
	transition: all 450ms ease-in-out;
}

.nav-pills .nav-link span {
	color: #ff3366;
	font-size: 0.95rem;
	font-weight: 700;
	margin-left: 10px;
}

.nav-pills .nav-link.active span,
.nav-pills .nav-link.active span:hover,
.nav-pills .nav-link.active span:focus {
	color: #fff;
}

/*------------------------------------------*/
/*   PRICING TABLE
/*------------------------------------------*/

.pricing-table {
	text-align: center;
	background-color: #fff;
	border: 1px solid #d0d0d0;
	padding: 60px 50px;
	margin-bottom: 40px;
	border-radius: 6px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#pricing-2 .pricing-table {
	background-color: #fdfdfd;
	border: 1px solid #e7e7e7;
	box-shadow: 0 0 6px rgba(40, 40, 40, 0.1);
}

#pricing-3 .pricing-table {
	text-align: center;
	border: 1px solid #ddd;
	background-color: #fff;
	padding: 60px 35px;
	border-radius: 6px;
}

/*------------------------------------------*/
/*    Pricing Table Price Plan
/*------------------------------------------*/

.pricing-plan {
	padding-bottom: 20px;
	border-bottom: 1px solid #d0d0d0;
}

.pricing-plan.rose-border {
	border-color: #ff3366;
}
.pricing-plan.skyblue-border {
	border-color: #42a5f5;
}
.pricing-plan.blue-border {
	border-color: #3176ed;
}
.pricing-plan.green-border {
	border-color: #56a959;
}
.pricing-plan.lightgreen-border {
	border-color: #22bc3f;
}
.pricing-plan.red-border {
	border-color: #e35029;
}
.pricing-plan.purple-border {
	border-color: #6e45e2;
}
.pricing-plan.deepgreen-border {
	border-color: #009587;
}

.pricing-plan h5 {
	font-weight: 700;
	letter-spacing: -0.5px;
	margin-bottom: 20px;
}

.pricing-table span.price {
	font-size: 75px;
	line-height: 1;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.pricing-table sup {
	font-size: 55px;
	font-weight: 600;
	top: -7px;
	right: 2px;
}

.pricing-table sup.pricing-coins {
	top: -25px;
	font-size: 40px;
	font-weight: 600;
}

p.validity {
	color: #888;
	font-style: italic;
	font-size: 1.2rem;
	margin-top: 5px;
}

/*------------------------------------------*/
/*   Plan Price
/*------------------------------------------*/

#pricing-3 .price h5 {
	margin-bottom: 25px;
}

#pricing-3 .price span {
	font-size: 65px;
	line-height: 1;
	font-family: 'Montserrat', sans-serif;
	font-weight: 800;
	letter-spacing: 0.5px;
}

#pricing-3 .pricing-table .price sup {
	font-size: 40px;
	font-weight: 400;
	top: -10px;
	margin-right: 5px;
}

#pricing-3 .price span.price-vat {
	display: block;
	color: #999;
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	line-height: 1.2;
	font-weight: 300;
	margin-top: 14px;
	padding: 0 7%;
}

/*------------------------------------------*/
/*    Pricing Description
/*------------------------------------------*/

.pricing-text {
	margin-top: 30px;
	margin-bottom: 30px;
	padding-bottom: 25px;
	border-bottom: 1px solid #ddd;
}

.pricing-text p {
	font-size: 15px;
	line-height: 23px;
	margin-bottom: 0;
}

.pricing-table p span {
	color: #000;
	font-weight: 800;
}

/*------------------------------------------*/
/*    Pricing Table Body
/*------------------------------------------*/

.pricing-table ul.features {
	padding: 30px 0 35px;
}

.pricing-table ul.features li {
	font-size: 1.05rem;
	line-height: 1;
	font-weight: 400;
	padding: 11px 0;
}

.pricing-table ul.features li:last-child {
	border-bottom: none;
}

/*------------------------------------------*/
/*    Pricing Text
/*------------------------------------------*/

.pricing-txt {
	margin-top: 30px;
	margin-bottom: 40px;
}

.pricing-txt p span {
	font-weight: 700;
}
