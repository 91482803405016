/* ==========================================================================
  18.  TESTIMONIALS
  =========================================================================== */

#reviews-2.ipage {
	background-image: url('/vendor/pergo/images/tra-map.png');
}

.reviews-section.bg-image {
	background-image: url('/vendor/pergo/images/reviews.jpg');
}

/*------------------------------------------*/
/*   TRANSPARENT QUOTE ICON
/*------------------------------------------*/

#reviews-2 .quote-icon {
	width: 125px;
	height: 110px;
	background: url('/vendor/pergo/images/quote-big.png');
	opacity: 0.2;
	margin: 0 auto -50px;
}

/*------------------------------------------*/
/*   TESTIMONIALS CAROUSEL
/*------------------------------------------*/

.review-1 {
	text-align: center;
	margin-bottom: 40px;
	padding: 0 30px;
	padding: 0;
	transform: scale(0.9);
	transition: all 450ms ease-in-out;
}

.review-2 {
	text-align: center;
	margin-bottom: 80px;
}

.review-1 .review-txt {
	background-color: #fff;
	border: 1px solid #eee;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 50px 40px 55px;
	margin: 0 2px;
	transition: all 450ms ease-in-out;
}

.review-1.slick-current {
	transform: scale(1);
}

.review-1.slick-current .review-txt {
	background-color: #fff;
	border: 1px solid #eee;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------*/
/*    Testimonial Message Avatar
/*------------------------------------------*/

.review-1 .testimonial-avatar img {
	width: 90px;
	height: 90px;
	display: inline-block;
	margin: -45px auto 15px;
	border-radius: 100%;
}

#reviews-2 img {
	width: 80px;
	height: 80px;
	display: inline-block;
	margin: 0 auto 15px;
	border-radius: 100%;
}

/*------------------------------------------*/
/*   Testimonial Message Text
/*------------------------------------------*/

.review-1 p {
	color: #666;
	font-size: 1.05rem;
	font-style: italic;
}

#reviews-2 .review-txt p {
	font-size: 1.85rem;
	line-height: 1.3;
	font-weight: 500;
	padding: 20px 8% 35px;
}

p.testimonial-autor {
	color: #000;
	font-size: 1.1rem;
	line-height: 1.2;
	font-weight: 600;
	font-style: normal;
	margin-bottom: 0;
}

p.testimonial-autor.deepblue-color {
	color: #004861;
}

.testimonial-avatar span {
	font-size: 0.95rem;
	line-height: 1;
}

.review-author p {
	color: #333;
	font-size: 1.25rem;
	line-height: 1.2;
	font-style: normal;
	margin-bottom: 0;
}

#reviews-2 .review-author p {
	color: #fff;
	font-size: 1.1rem;
	margin-bottom: 2px;
}

#reviews-2 .review-author span {
	font-size: 0.9rem;
	line-height: 1;
	font-weight: 600;
	text-transform: uppercase;
}

/*------------------------------------------*/
/*   Reviews Slick Carousel Dots
/*------------------------------------------*/

.reviews-section .slick-dots {
	position: absolute;
	z-index: 4;
	bottom: -30px;
}

.reviews-section .slick-dots li {
	margin: 0 2px;
	width: 15px;
	height: 15px;
}

.reviews-section .slick-dots li button:before {
	content: '';
	background: #ccc;
	border-radius: 50%;
	opacity: 1;
	width: 8px;
	height: 8px;
	left: auto;
	transition: all ease-in-out 0.2s;
}

.reviews-section .slick-dots li button:hover:before {
	background: #999;
}

.reviews-section .slick-dots li.slick-active button:before {
	background: #666;
}

/*------------------------------------------*/
/*   Reviews FlexSlider Navigation
/*------------------------------------------*/

.flex-control-paging li a {
	background: rgba(90, 90, 90, 0.25);
	width: 10px;
	height: 10px;
	border: 2px solid rgba(90, 90, 90, 0.02);
}

.flex-control-paging li a.flex-active {
	background: rgba(255, 255, 255, 0.65);
	border: 2px solid rgba(255, 255, 255, 0.02);
}

.rose-nav .flex-control-paging li a.flex-active {
	background: #ff3366;
	border: 2px solid #ff3366;
}

.purple-nav .flex-control-paging li a.flex-active {
	background: #6e45e2;
	border: 2px solid #6e45e2;
}

.skyblue-nav .flex-control-paging li a.flex-active {
	background: #0b88ee;
	border: 2px solid #0b88ee;
}

.green-nav .flex-control-paging li a.flex-active {
	background: #1da036;
	border: 2px solid #1da036;
}
