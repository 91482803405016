/* ==========================================================================
  09.  BANNER
  =========================================================================== */

#banner-1 {
	background-image: url('/vendor/pergo/images/banner-1.jpg');
	background-position: top center;
	padding-top: 100px;
	padding-bottom: 100px;
}

#banner-2 {
	padding-top: 100px;
	padding-bottom: 10px;
}

#banner-4 {
	background-image: url('/vendor/pergo/images/banner-4.jpg');
	background-position: center center;
	padding-top: 150px;
	padding-bottom: 150px;
}

/*------------------------------------------*/
/*    BANNER TYPOGRAPHY
/*------------------------------------------*/

#banner-2 .banner-txt h2,
#banner-3 .banner-txt h2,
#banner-4 .banner-txt h2 {
	margin-bottom: 30px;
}

#banner-1 .banner-txt h2,
#banner-3 .banner-txt h3 {
	margin-bottom: 25px;
}

#banner-2 .banner-txt h2 span {
	font-weight: 800;
	letter-spacing: -1px;
}

#banner-1 .banner-txt p {
	padding-right: 10%;
}

#banner-3 .banner-txt p {
	padding: 0 10%;
}

/*------------------------------------------*/
/*   Banner Button
/*------------------------------------------*/

.banner-btn {
	margin-top: 35px;
}
