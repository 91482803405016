/* ==========================================================================
  03.  PRELOAD SPINNER
  =========================================================================== */

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: #f5f5f5;
}

.cssload-spin-box {
	position: absolute;
	margin: auto;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	box-shadow: 25px 25px rgb(49, 118, 237), -25px 25px rgb(255, 51, 102), -25px -25px rgb(49, 118, 237),
		25px -25px rgb(255, 51, 102);
	animation: cssload-spin ease infinite 4.6s;
}

@keyframes cssload-spin {
	0%,
	100% {
		box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226),
			28px -28px rgb(255, 51, 102);
	}
	25% {
		box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102),
			28px 28px rgb(110, 69, 226);
	}
	50% {
		box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226),
			-28px 28px rgb(255, 51, 102);
	}
	75% {
		box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
	}
}

@-o-keyframes cssload-spin {
	0%,
	100% {
		box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226),
			28px -28px rgb(255, 51, 102);
	}
	25% {
		box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102),
			28px 28px rgb(110, 69, 226);
	}
	50% {
		box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226),
			-28px 28px rgb(255, 51, 102);
	}
	75% {
		box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
	}
}

@-ms-keyframes cssload-spin {
	0%,
	100% {
		box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226),
			28px -28px rgb(255, 51, 102);
	}
	25% {
		box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102),
			28px 28px rgb(110, 69, 226);
	}
	50% {
		box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226),
			-28px 28px rgb(255, 51, 102);
	}
	75% {
		box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
	}
}

@-webkit-keyframes cssload-spin {
	0%,
	100% {
		box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226),
			28px -28px rgb(255, 51, 102);
	}
	25% {
		box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102),
			28px 28px rgb(110, 69, 226);
	}
	50% {
		box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226),
			-28px 28px rgb(255, 51, 102);
	}
	75% {
		box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
	}
}

@-moz-keyframes cssload-spin {
	0%,
	100% {
		box-shadow: 28px 28px rgb(110, 69, 226), -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226),
			28px -28px rgb(255, 51, 102);
	}
	25% {
		box-shadow: -28px 28px rgb(255, 51, 102), -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102),
			28px 28px rgb(110, 69, 226);
	}
	50% {
		box-shadow: -28px -28px rgb(110, 69, 226), 28px -28px rgb(255, 51, 102), 28px 28px rgb(110, 69, 226),
			-28px 28px rgb(255, 51, 102);
	}
	75% {
		box-shadow: 28px -28px #dfdfdf, 28px 28px #4f4d49, -28px 28px #dfdfdf, -28px -28px #4f4d49;
	}
}
