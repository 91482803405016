/* ==========================================================================
  21.  CALL TO ACTION
  =========================================================================== */

#cta-1 {
	padding-top: 130px;
	padding-bottom: 140px;
}

.cta-section.bg-image {
	background-image: url('/vendor/pergo/images/call-to-action.jpg');
}

.cta-section.bg-purple {
	background-image: url('/vendor/pergo/images/tra-bg.png');
}

#cta-4 .bg-image.bg-inner {
	background-image: url('/vendor/pergo/images/cta-4.jpg');
	position: relative;
	z-index: 1;
}

#cta-5 {
	padding-top: 60px;
	padding-bottom: 60px;
}

/*------------------------------------------*/
/*    CALL TO ACTION TYPOGRAPHY
/*------------------------------------------*/

.cta-txt h2.h2-lg {
	letter-spacing: -1px;
	margin-bottom: 25px;
}

.cta-txt h2.h2-xl {
	letter-spacing: -1px;
}

.cta-txt h2.h2-md,
.cta-txt h2.h2-xs {
	margin-bottom: 20px;
}

.cta-txt h2.h2-xs span,
.cta-txt h2.h2-xl span {
	font-weight: 700;
}

.cta-txt h2.h2-xl span {
	font-weight: 800;
	letter-spacing: 0;
}

#cta-1 .cta-txt p {
	padding: 0 8%;
}

#cta-2 .cta-txt p {
	padding: 0 14%;
}

#cta-4 .cta-txt p {
	padding-right: 5%;
	margin-top: 30px;
	margin-bottom: 0;
}

#cta-1 .cta-btn {
	margin-top: 40px;
}

#cta-2 .cta-btn {
	margin-top: 30px;
}

#cta-3 .cta-btn {
	display: inline-block;
}

#cta-3 .cta-btn p span {
	font-weight: 700;
}

#cta-4 .cta-btn {
	margin-top: 40px;
}

/*------------------------------------------*/
/*    CALL TO ACTION IMAGE
/*------------------------------------------*/

.cta-4-img {
	text-align: center;
	margin-top: -80px;
	position: relative;
	z-index: 999;
}
