/* ==========================================================================
  19.  BRANDS
  =========================================================================== */

#brands-1 {
	padding-top: 80px;
	padding-bottom: 60px;
}

.brands-section.bg-dark {
	background-image: url('/vendor/pergo/images/tra-map.png');
}

.brands-section.bg-image {
	background-image: url('/vendor/pergo/images/brands.jpg');
}

/*------------------------------------------*/
/*    BRANDS LOGO HOLDER
/*------------------------------------------*/

#brands-1 .brand-logo,
#pricing-2 .brand-logo {
	width: 20%;
	float: left;
	padding: 0 10px;
	margin-bottom: 20px;
}

#brands-2 .brand-logo {
	padding: 0 30px;
	margin-bottom: 30px;
}

.brand-logo img {
	padding: 0 10px;
}

/*------------------------------------------*/
/*    BRANDS TYPOGRAPHY
/*------------------------------------------*/

.brands-title {
	text-align: center;
}

#brands-2 .brands-title {
	margin-bottom: 50px;
}

.brands-title h5 {
	font-weight: 500;
	margin-bottom: 35px;
}

.brands-title h3 {
	margin-bottom: 15px;
}

.brands-title h5 span {
	font-weight: 700;
}

.brands-title p {
	padding: 0 10%;
}
