/* ==========================================================================
  22.  BLOG
  =========================================================================== */

#single-blog-page {
	margin-top: 65px;
}

/*------------------------------------------*/
/*    BLOG POST
/*------------------------------------------*/

.blog-post {
	margin-bottom: 40px;
}

.posts-holder .blog-post {
	margin-bottom: 60px;
}

/*------------------------------------------*/
/*    BLOG TYPOGRAPHY
/*------------------------------------------*/

.blog-post h5 {
	line-height: 1.4;
	margin-bottom: 15px;
}

#blog-page .blog-post h5 {
	line-height: 1.3;
	margin-bottom: 10px;
}

#single-post-page.blog-post h5 {
	line-height: 1.3;
	margin-top: 30px;
	margin-bottom: 35px;
}

.related-post .blog-post-txt h5 {
	margin-bottom: 10px;
}

.blog-post-txt p.post-meta {
	color: #666;
	margin-bottom: 15px;
}

.related-post .blog-post-txt p.post-meta {
	margin-bottom: 6px;
}

.bg-deepdark .blog-post-txt p {
	color: #ddd;
}

.post-inner-img p.p-sm {
	color: #999;
	font-style: italic;
	margin-top: 15px;
	margin-bottom: 0;
}

.blog-post-txt p.post-meta span,
.sblog-post-txt p.post-meta span {
	font-weight: 700;
}

.blog-post-txt a:hover {
	text-decoration: underline;
}

/*------------------------------------------*/
/*    BLOG PAGE RIGHT SIDEBAR
/*------------------------------------------*/

#sidebar-right h5.h5-sm {
	margin-bottom: 25px;
}

/*------------------------------------------*/
/*   Blog Search Form
/*------------------------------------------*/

#search-field .form-control {
	background-color: #f0f0f0;
	padding-left: 20px;
	border: none;
	border-radius: 6px 0 0 6px;
}

#search-field .btn {
	background-color: #f0f0f0;
	color: #777;
	border: none;
	padding: 22px 18px;
	margin-top: 0;
	box-shadow: 0 0 0;
	border-radius: 0px 6px 6px 0;
}

#search-field .form-control:focus {
	border-color: #999;
	outline: 0px none;
	box-shadow: none;
}

/*------------------------------------------*/
/*   Blog Categories
/*------------------------------------------*/

ul.blog-category-list > li {
	padding: 10px 0;
	border-bottom: 1px dashed #c0c0c0;
	transition: all 300ms ease-in-out;
}

ul.blog-category-list > li:first-child {
	padding: 0 0 10px;
}

ul.blog-category-list > li:last-child {
	padding: 10px 0 0;
	border-bottom: none;
}

ul.blog-category-list > li a,
ul.blog-category-list > li span {
	color: #555;
}

ul.blog-category-list li .svg-inline--fa {
	margin-right: 10px;
}

ul.blog-category-list li a:hover {
	color: #000;
	text-decoration: none;
}

/*------------------------------------------*/
/*   Popular Posts
/*------------------------------------------*/

.popular-posts li {
	padding: 14px 0;
	border-bottom: 1px dashed #c0c0c0;
}

.popular-posts li:first-child {
	padding: 0 0 14px;
}

.popular-posts li:last-child {
	padding: 14px 0 0;
	border-bottom: 0;
}

.popular-posts img {
	text-align: center;
	float: left;
}

.post-summary {
	overflow: hidden;
	padding-left: 20px;
}

/*------------------------------------------*/
/*  Popular Post Typography
--------------------------------------------*/

.post-summary a {
	color: #555;
	transition: all 400ms ease-in-out;
}

.popular-posts .post-summary a:hover {
	text-decoration: underline;
}

.popular-posts p {
	color: #888;
	font-size: 0.875rem;
	margin-top: 6px;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   Blog Page Popular Tags
/*------------------------------------------*/

.badge {
	font-size: 0.75rem;
	font-weight: 600;
	color: #222;
	border: none;
	background-color: #f0f0f0;
	border-radius: 6px;
	margin-bottom: 8px;
	padding: 5px 10px;
	margin-right: 2px;
	transition: all 450ms ease-in-out;
}

.badge a {
	color: #666 !important;
	font-size: 0.75rem;
	font-weight: 300;
	text-transform: uppercase;
	line-height: 1.42857;
}

.badge:hover {
	background-color: #2c353f;
	border-color: #2c353f;
	color: #fff;
}

.badge:hover a {
	color: #fff !important;
}

/*------------------------------------------*/
/*    BLOG PAGE PAGINATION
/*------------------------------------------*/

.page-link {
	color: #666;
	padding: 0.65rem 0.95rem;
}

.page-link:hover,
.page-link:focus {
	color: #444;
	background-color: #eee;
	border-color: #dee2e6;
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #2c353f;
	border-color: #2c353f;
}

/*------------------------------------------*/
/*    SINGLE POST SHARE ICONS
/*-----------------------------------------*/

.share-social-icons {
	display: inline-block;
	padding-left: 0;
}

.share-social-icons li {
	width: auto !important;
	display: inline-block !important;
	vertical-align: top;
	clear: none !important;
	padding: 0;
}

a.share-ico {
	width: 90px;
	height: 35px;
	font-size: 18px;
	line-height: 35px !important;
	text-align: center;
	margin-right: 3px;
	display: block;
	opacity: 0.75;
	border-radius: 6px;
}

a.share-ico.ico-facebook {
	background-color: #3b5998;
	border-color: #3b5998;
	color: #fff;
}
a.share-ico.ico-twitter {
	background-color: #00a9ed;
	border-color: #00a9ed;
	color: #fff;
}
a.share-ico.ico-behance {
	background-color: #00a8e7;
	border-color: #00a8e7;
	color: #fff;
}
a.share-ico.ico-google-plus {
	background-color: #cd1111;
	border-color: #cd1111;
	color: #fff;
}
a.share-ico.ico-linkedin {
	background-color: #015886;
	border-color: #015886;
	color: #fff;
}
a.share-ico.ico-dribbble {
	background-color: #d92d84;
	border-color: #d92d84;
	color: #fff;
}
a.share-ico.ico-instagram {
	background-color: #beb3a8;
	border-color: #beb3a8;
	color: #fff;
}
a.share-ico.ico-pinterest {
	background-color: #ac281a;
	border-color: #ac281a;
	color: #fff;
}
a.share-ico.ico-dropbox {
	background-color: #008ad2;
	border-color: #008ad2;
	color: #fff;
}
a.share-ico.ico-skype {
	background-color: #00a9ed;
	border-color: #00a9ed;
	color: #fff;
}
a.share-ico.ico-youtube {
	background-color: #cd1b20;
	border-color: #cd1b20;
	color: #fff;
}
a.share-ico.ico-tumblr {
	background-color: #3a5976;
	border-color: #3a5976;
	color: #fff;
}
a.share-ico.ico-vimeo {
	background-color: #00adee;
	border-color: #00adee;
	color: #fff;
}
a.share-ico.ico-flickr {
	background-color: #d2d2d2;
	border-color: #d2d2d2;
	color: #fff;
}
a.share-ico.ico-github {
	background-color: #222;
	border-color: #222;
	color: #fff;
}
a.share-ico.ico-renren {
	background-color: #364a83;
	border-color: #364a83;
	color: #fff;
}
a.share-ico.ico-vk {
	background-color: #3b5998;
	border-color: #3b5998;
	color: #fff;
}
a.share-ico.ico-xing {
	background-color: #015f5e;
	border-color: #015f5e;
	color: #fff;
}
a.share-ico.ico-weibo {
	background-color: #be4443;
	border-color: #be4443;
	color: #fff;
}
a.share-ico.ico-rss {
	background-color: #ff6600;
	border-color: #ff6600;
	color: #fff;
}
a.share-ico.ico-digg {
	background-color: #222;
	border-color: #222;
	color: #fff;
}
a.share-ico.ico-deviantart {
	background-color: #57675d;
	border-color: #57675d;
	color: #fff;
}
a.share-ico.ico-envelope {
	background-color: #999;
	border-color: #999;
	color: #fff;
}
a.share-ico.ico-delicious {
	background-color: #0a0a0a;
	border-color: #0a0a0a;
	color: #fff;
}

a.share-ico:hover {
	opacity: 1;
}

/*-----------------------------------------*/
/*    RELATED POST
/*-----------------------------------------*/

.related-post {
	margin-top: 70px;
	margin-bottom: 60px;
}
/*------------------------------------------*/
/*    SINGLE POST META
/*------------------------------------------*/

.comment-meta {
	margin-bottom: 10px;
}

.comment-meta h5 {
	line-height: 1.1;
	margin-bottom: 0;
}

.comment-date {
	color: #888;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.btn-reply a {
	background-color: #888;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	padding: 3px 8px;
	text-transform: uppercase;
	border-radius: 2px;
}

.btn-reply a:hover {
	color: #fff;
	background-color: #2c353f;
}

.comment-reply {
	margin-left: 40px;
}

.single-post-comments hr {
	margin-top: 20px;
	margin-bottom: 20px;
}

/*------------------------------------------*/
/*    SINGLE POST COMMENT FORM
/*------------------------------------------*/

#leave-comment h4.h4-lg {
	margin-bottom: 5px;
}

.comment-form {
	position: relative;
}

.comment-form p {
	color: #333;
	font-size: 15px;
	line-height: 15px;
	font-weight: 700;
	margin-bottom: 10px;
	padding-left: 5px;
	display: block;
}

.comment-form .form-control {
	background-color: #fff;
	border-color: #ccc;
	color: #333;
	height: 46px;
	font-size: 15px;
	font-weight: 300;
	padding: 15px;
	margin-bottom: 20px;
	box-shadow: none;
}

.comment-form .form-control:focus {
	border-color: #48af4b;
	outline: 0px none;
	box-shadow: none;
}

.comment-form textarea {
	min-height: 200px;
}

/*------------------------------------------*/
/*    Forms Message
/*------------------------------------------*/

.comment-form label.error {
	color: #f6412d;
	font-size: 0.95rem;
	line-height: 1;
	margin-bottom: 20px;
}

.comment-form-msg {
	position: absolute;
	bottom: 10px;
	left: 350px;
}

.comment-form-msg .error,
.comment-form-msg .loading {
	color: #45afff;
	font-size: 1.1rem;
	line-height: 1;
	font-weight: 600;
}

.comment-form-msg .error {
	color: #f6412d;
}
