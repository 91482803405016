/* ==========================================================================
  04.  HEADER & NAVIGATION
  =========================================================================== */

/*------------------------------------------*/
/*   LOGO IMAGE
/*------------------------------------------*/

.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
	margin-right: 5rem;
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff;
}

.navbar-light .navbar-nav .nav-link {
	color: #555;
}

.bg-light {
	background-color: #fff !important;
	box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.header .nb-line {
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

/*------------------------------------------*/
/*   NAVIGATION BAR
/*------------------------------------------*/

.navbar {
	padding: 0.75rem 1rem;
	transition: all 400ms ease-in-out;
}

.navbar.bg-tra {
	padding: 2.25rem 1rem;
	background-color: transparent;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.navbar-expand-lg .navbar-nav .nav-link {
	font-size: 1.05rem;
	font-weight: 500;
	padding: 0.65rem 1.5rem;
	margin-left: 1px;
	border-radius: 6px;
}

/*------------------------------------------*/
/*   NAVIGATION MENU TEXT
/*------------------------------------------*/

.navbar-text {
	padding-top: 0;
	padding-bottom: 0;
}

.navbar-text.phone-number {
	font-size: 1.1rem;
	font-weight: 500;
	margin-left: 10px;
}

.navbar-text.phone-number .svg-inline--fa {
	margin-right: 4px;
}

.navbar-dark .navbar-text.lightgreen-color {
	color: #22bc3f;
}

.navbar-dark .navbar-text.yellow-color {
	color: #fcb80b;
}

.navbar-dark .navbar-text.blue-color {
	color: #01b7de;
}

.navbar-dark .navbar-text.rose-color {
	color: #ff3366;
}

/*------------------------------------------*/
/*   Dropdown Menu
/*------------------------------------------*/

.dropdown-menu {
	min-width: 10rem;
	padding: 0.85rem 0.45rem;
	margin: 0.85rem 0 0;
	font-size: 1.05rem;
	color: #555;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 6px;
}

.dropdown-menu::before {
	content: '';
	background-color: #fff;
	position: absolute;
	top: -5px;
	left: 20px;
	height: 0.9375rem;
	width: 0.9375rem;
	transform: rotate(45deg);
	z-index: -2;
}

.dropdown-item {
	font-weight: 500;
	padding: 0.6rem 4.5rem 0.6rem 1rem;
}

.dropdown-toggle::after {
	vertical-align: 0.175em;
}

/*------------------------------------------*/
/*   Navbar Social Icons
/*------------------------------------------*/

.header-socials {
	margin-top: 4px;
	margin-left: 3px;
	display: inline-block;
	text-align: center;
}

.header-socials span {
	float: left;
	width: auto !important;
	display: inline-block !important;
	vertical-align: top;
	clear: none !important;
	margin-left: 5px;
}

.header-socials a {
	width: 38px;
	height: 38px;
	background-color: transparent;
	display: block;
	color: #fff;
	font-size: 1rem;
	line-height: 34px !important;
	border: 2px solid #fff;
	margin-left: 3px;
	border-radius: 100%;
}

.navbar-light .header-socials a {
	color: #333;
	border: 2px solid #333;
}

.header-socials a.ico-facebook:hover,
.navbar.scroll .header-socials a.ico-facebook:hover {
	background-color: #3b5998;
	border-color: #3b5998;
}
.header-socials a.ico-twitter:hover,
.navbar.scroll .header-socials a.ico-twitter:hover {
	background-color: #00a9ed;
	border-color: #00a9ed;
}
.header-socials a.ico-behance:hover,
.navbar.scroll .header-socials a.ico-behance:hover {
	background-color: #2473f6;
	border-color: #2473f6;
}
.header-socials a.ico-google-plus:hover,
.navbar.scroll .header-socials a.ico-google-plus:hover {
	background-color: #cd1111;
	border-color: #cd1111;
}
.header-socials a.ico-linkedin:hover,
.navbar.scroll .header-socials a.ico-linkedin:hover {
	background-color: #015886;
	border-color: #015886;
}
.header-socials a.ico-dribbble:hover,
.navbar.scroll .header-socials a.ico-dribbble:hover {
	background-color: #d92d84;
	border-color: #d92d84;
}
.header-socials a.ico-instagram:hover,
.navbar.scroll .header-socials a.ico-instagram:hover {
	background-color: #beb3a8;
	border-color: #beb3a8;
}
.header-socials a.ico-pinterest:hover,
.navbar.scroll .header-socials a.ico-pinterest:hover {
	background-color: #ac281a;
	border-color: #ac281a;
}
.header-socials a.ico-youtube:hover,
.navbar.scroll .header-socials a.ico-youtube:hover {
	background-color: #cd1b20;
	border-color: #cd1b20;
}
.header-socials a.ico-tumblr:hover,
.navbar.scroll .header-socials a.ico-tumblr:hover {
	background-color: #3a5976;
	border-color: #3a5976;
}
.header-socials a.ico-vk:hover,
.navbar.scroll .header-socials a.ico-vk:hover {
	background-color: #3b5998;
	border-color: #3b5998;
}

/*------------------------------------------*/
/*   Navbar Button
/*------------------------------------------*/

.navbar .btn {
	font-size: 1.05rem;
	font-weight: 500;
	padding: 0.785rem 2.15rem;
	margin-top: 0;
	margin-left: 12px;
}

/*------------------------------------------*/
/*   Navigation Menu Hover
/*------------------------------------------*/

.navbar-light .nav-link:focus,
.navbar-light .nav-link:hover {
	color: #fff;
	background-color: rgba(140, 140, 140, 0.2);
}

.navbar-dark .nav-link:focus,
.navbar-dark .nav-link:hover {
	color: #fff;
	background-color: rgba(240, 240, 240, 0.2);
}

.dropdown-item:focus,
.dropdown-item:hover {
	color: #333;
	text-decoration: none;
	background-color: #eee;
}

/*------------------------------------------*/
/*   Navbar Scroll
/*------------------------------------------*/

.navbar.scroll.bg-tra {
	padding: 12px 0;
	background-color: #fcfcfc;
	box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.navbar.scroll.navbar-dark.bg-tra .nav-link,
.navbar.scroll .navbar-text span.phone-text.white-color {
	color: #555;
}

.navbar.scroll.navbar-dark.bg-tra .nav-link:hover {
	background-color: rgba(200, 200, 200, 0.35);
}

.navbar.scroll .header-socials a,
.navbar.scroll .btn.btn-tra-white {
	color: #555;
	border-color: #555;
}

.navbar.scroll .header-socials a:hover {
	color: #fff;
}

.navbar.scroll .btn.btn-tra-white:hover {
	background-color: #ff3366;
	color: #fff;
	border-color: #ff3366;
}

.navbar.scroll .btn.btn-semiwhite,
.navbar.scroll .btn.tra-hover:hover,
.navbar.scroll .btn.btn-purple.tra-hover:hover,
.navbar.scroll .btn.btn-lightgreen.tra-hover:hover,
.navbar.scroll .btn.btn-semiwhite.tra-hover:hover {
	color: #555;
	background-color: transparent;
	border-color: #555;
}

/*------------------------------------------*/
/*   Responsive Burger Menu
/*------------------------------------------*/

.navbar-light .navbar-toggler,
.navbar.bg-tra .navbar-toggler {
	color: #888;
	border-color: #888;
	padding: 0.25rem 0.5rem;
	font-size: 1.05rem;
}

.navbar-dark.bg-tra .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler:focus {
	outline: 0;
}

/*------------------------------------------*/
/*   Logo Image
/*------------------------------------------*/

.logo-white,
.logo-black {
	display: block;
}

.navbar-light.bg-light .logo-white,
.navbar-dark.bg-tra .logo-black,
.navbar-light.bg-tra .logo-white,
.scroll.navbar-dark.bg-tra .logo-white,
.navbar-dark.bg-dark .logo-black,
.navbar-light.bg-light .logo-white {
	display: none;
}

.navbar-dark.bg-tra .logo-white,
.navbar-light.bg-tra .logo-black,
.scroll.navbar-dark.bg-tra .logo-black,
.navbar-dark.bg-dark .logo-white,
.navbar-light.bg-light .logo-black {
	display: block;
}
