/* ==========================================================================
  07.  SERVICES
  =========================================================================== */

#services-2.bg-image {
	background-image: url('/vendor/pergo/images/services-2.jpg');
}

/*------------------------------------------*/
/*   SERVICES SECTION TITLE
/*------------------------------------------*/

#services-1 .section-title {
	margin-bottom: 60px;
}

/*------------------------------------------*/
/*   SERVICE BOX
/*------------------------------------------*/

.sbox-1,
.sbox-5 {
	margin-bottom: 60px;
}

.sbox-2 {
	margin-bottom: 40px;
	padding: 0 20px;
}

.sbox-3 {
	margin-bottom: 50px;
}

.sbox-4 {
	margin-bottom: 40px;
}

.sbox-6 {
	padding: 70px 40px;
	border: 1px solid #ddd;
	text-align: center;
	margin-bottom: 30px;
	border-radius: 6px;
}

/*------------------------------------------*/
/*   SERVICES BOX TEXT
/*------------------------------------------*/

.sbox-1-txt,
.sbox-3-txt {
	overflow: hidden;
	padding-left: 18px;
}

.sbox-4-txt {
	overflow: hidden;
	padding-left: 30px;
}

.sbox-5-txt1 {
	margin-top: 12px;
}

/*------------------------------------------*/
/*   SERVICES BOX TYPOGRAPHY
/*------------------------------------------*/

.sbox-1 h5,
.sbox-3 h5,
.sbox-4 h5 {
	margin-bottom: 10px;
}

.sbox-2 h5,
.sbox-5 h5 {
	margin-top: 25px;
	margin-bottom: 10px;
}

.sbox-6 h4 {
	margin-top: 30px;
	margin-bottom: 0;
}

.sbox-1 p,
.sbox-2 p,
.sbox-3 p,
.sbox-4 p,
.sbox-5 p,
.sbox-6 p {
	line-height: 1.5;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   Service Box Icon
/*------------------------------------------*/

.sbox-1 span,
.sbox-3 span,
.sbox-4 span {
	text-align: center;
	float: left;
}

.sbox-2 span {
	display: block;
	position: relative;
	z-index: 999;
}

/*------------------------------------------*/
/*   Service Image
/*------------------------------------------*/

.services-img {
	text-align: center;
	margin-bottom: 40px;
}
