/* ==========================================================================
  13.  SEARCH
  =========================================================================== */

#search-1 {
	padding-top: 80px;
	padding-bottom: 80px;
}

/*------------------------------------------*/
/*   SEARCH TYPOGRAPHY
/*------------------------------------------*/

#search-1 h3 {
	margin-bottom: 10px;
}

.domain-names {
	background-color: #fff;
	margin-top: 30px;
}

/*------------------------------------------*/
/*   Domain Name Box
/*------------------------------------------*/

.dn-box {
	padding: 25px 20px;
}

.dn-box p {
	margin-bottom: 0;
}

.dn-box span {
	color: #333;
	font-weight: 700;
	display: block;
}

/*------------------------------------------*/
/*   SEARCH FORM
/*------------------------------------------*/

#hero-28 .search-form {
	margin: 0 15%;
}

.search-form .form-control {
	height: 60px;
	color: #fff;
	font-size: 1.05rem;
	padding: 0.375rem 1.5rem;
	border: 1px solid #ccc;
	border-radius: 6px 0 0 6px;
	transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*    Search Form Input Focus
/*------------------------------------------*/

.search-form .form-control:focus {
	background-color: transparent;
	border: 1px solid #ccc;
	border-right: none;
	box-shadow: none;
}

/*------------------------------------------*/
/*    Search Form Placeholder
/*------------------------------------------*/

.search-form .form-control::-moz-placeholder {
	color: #ccc;
}
.search-form .form-control:-ms-input-placeholder {
	color: #ccc;
}
.search-form .form-control::-webkit-input-placeholder {
	color: #ccc;
}

/*------------------------------------------*/
/*    Search Form Button
/*------------------------------------------*/

.search-form .btn {
	height: 60px;
	font-size: 1.05rem;
	padding: 0.375rem 1.45rem;
	border-radius: 0 6px 6px 0;
}

#hero-28 .search-form .btn {
	padding: 0.375rem 2.45rem;
}
