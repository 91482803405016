/* ==========================================================================
  06.  ABOUT
  =========================================================================== */

#about-2.bg-dark {
	background-image: url('/vendor/pergo/images/tra-map.png');
}

#about-6 .bg-inner {
	position: relative;
	z-index: 1;
}

/*------------------------------------------*/
/*   ABOUT TEXT
/*------------------------------------------*/

.about-txt {
	margin-bottom: 40px;
}

.abox-4 {
	padding: 0 15px;
}

#a4-1.abox-4 {
	padding: 0 35px;
}

.abox-4 h5 {
	margin-bottom: 10px;
}

#a4-1.abox-4 h5 {
	margin-bottom: 25px;
}

#a4-1 .btn {
	margin-top: 20px;
}

#about-4 .a4-img {
	padding: 0;
}

/*------------------------------------------*/
/*   ABOUT TYPOGRAPHY
/*------------------------------------------*/

.about-txt h2.h2-lg,
.about-txt h3.h3-sm,
.about-txt h3.h3-xl {
	margin-bottom: 30px;
}

.layer-phone {
	margin-top: 60px;
	margin-bottom: 40px;
}

.layer-phone h5 {
	line-height: 1;
	font-weight: 400;
	margin-bottom: 10px;
}

/*------------------------------------------*/
/*   About Button
/*------------------------------------------*/

.about-btn {
	margin-top: 45px;
}

/*------------------------------------------*/
/*   ABOUT IMAGE
/*------------------------------------------*/

.about-img {
	text-align: center;
	margin-bottom: 40px;
}

#about-4 .about-img {
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   ABOUT REGISTER FORM
/*------------------------------------------*/

.about-form {
	margin-top: -200px;
	position: relative;
	z-index: 999;
}

.about-form form {
	background: #21262b;
	border: 1px solid #222;
	padding: 70px 40px 55px;
	border-radius: 0;
}

/*------------------------------------------*/
/*    About Form Input
/*------------------------------------------*/

.about-form .col-md-12 {
	padding: 0;
}

.about-form .form-control {
	background-color: #fff;
	color: #333;
	height: 46px;
	font-size: 15px;
	font-weight: 300;
	padding: 4px 10px;
	margin-bottom: 15px;
	border: none;
	border-bottom: 1px solid #eee;
	border-radius: 0;
	box-shadow: none;
}

.about-form .form-control:focus {
	border-color: #ff3366;
	outline: 0px none;
	box-shadow: none;
}

/*------------------------------------------*/
/*    Register Form Button
/*------------------------------------------*/

.about-form .btn {
	display: block;
	width: 100%;
	margin-top: 20px;
}

/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/

.about-form .loading {
	color: #666;
	font-size: 16px;
	font-weight: 700;
	margin-top: 20px;
}

.error {
	color: #e74c3c;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 20px;
}
