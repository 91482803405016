/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
}

body {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: #333;
	font-weight: 300;
}

/*------------------------------------------------------------------*/
/*   IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport {
	width: device-width;
}
@-moz-viewport {
	width: device-width;
}
@-ms-viewport {
	width: device-width;
}
@-o-viewport {
	width: device-width;
}
@viewport {
	width: device-width;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page {
	overflow: hidden;
}

/*------------------------------------------*/
/*  ONSCROLL ANIMATIONS
/*------------------------------------------*/

.animated {
	visibility: hidden;
}
.visible {
	visibility: visible;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.ind-60 {
	padding-right: 60px;
	padding-left: 60px;
}
.ind-50 {
	padding-right: 50px;
	padding-left: 50px;
}
.ind-45 {
	padding-right: 45px;
	padding-left: 45px;
}
.ind-35 {
	padding-right: 35px;
	padding-left: 35px;
}
.ind-30 {
	padding-right: 30px;
	padding-left: 30px;
}
.ind-25 {
	padding-right: 25px;
	padding-left: 25px;
}
.ind-20 {
	padding-right: 20px;
	padding-left: 20px;
}
.ind-15 {
	padding-right: 15px;
	padding-left: 15px;
}
.ind-10 {
	padding-right: 10px;
	padding-left: 10px;
}
.ind-5 {
	padding-right: 5px;
	padding-left: 5px;
}

.wide-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}
.wide-90 {
	padding-top: 100px;
	padding-bottom: 90px;
}
.wide-80 {
	padding-top: 100px;
	padding-bottom: 80px;
}
.wide-70 {
	padding-top: 100px;
	padding-bottom: 70px;
}
.wide-60 {
	padding-top: 100px;
	padding-bottom: 60px;
}
.wide-50 {
	padding-top: 100px;
	padding-bottom: 50px;
}
.wide-40 {
	padding-top: 100px;
	padding-bottom: 40px;
}
.wide-30 {
	padding-top: 100px;
	padding-bottom: 30px;
}
.wide-20 {
	padding-top: 100px;
	padding-bottom: 20px;
}

/*------------------------------------------*/
/*    Margin Top
/*------------------------------------------*/

.m-top-160 {
	margin-top: 160px;
}
.m-top-150 {
	margin-top: 150px;
}
.m-top-140 {
	margin-top: 140px;
}
.m-top-130 {
	margin-top: 130px;
}
.m-top-120 {
	margin-top: 120px;
}
.m-top-110 {
	margin-top: 110px;
}
.m-top-100 {
	margin-top: 100px;
}
.m-top-90 {
	margin-top: 90px;
}
.m-top-80 {
	margin-top: 80px;
}
.m-top-70 {
	margin-top: 70px;
}
.m-top-60 {
	margin-top: 60px;
}
.m-top-50 {
	margin-top: 50px;
}
.m-top-45 {
	margin-top: 45px;
}
.m-top-40 {
	margin-top: 40px;
}
.m-top-35 {
	margin-top: 35px;
}
.m-top-30 {
	margin-top: 30px;
}
.m-top-25 {
	margin-top: 25px;
}
.m-top-20 {
	margin-top: 20px;
}
.m-top-15 {
	margin-top: 15px;
}
.m-top-10 {
	margin-top: 10px;
}
.m-top-5 {
	margin-top: 5px;
}

/*------------------------------------------*/
/*    Margin Bottom
/*------------------------------------------*/

.m-bottom-160 {
	margin-bottom: 160px;
}
.m-bottom-150 {
	margin-bottom: 150px;
}
.m-bottom-140 {
	margin-bottom: 140px;
}
.m-bottom-130 {
	margin-bottom: 130px;
}
.m-bottom-120 {
	margin-bottom: 120px;
}
.m-bottom-110 {
	margin-bottom: 110px;
}
.m-bottom-100 {
	margin-bottom: 100px;
}
.m-bottom-90 {
	margin-bottom: 90px;
}
.m-bottom-80 {
	margin-bottom: 80px;
}
.m-bottom-70 {
	margin-bottom: 70px;
}
.m-bottom-60 {
	margin-bottom: 60px;
}
.m-bottom-50 {
	margin-bottom: 50px;
}
.m-bottom-45 {
	margin-bottom: 45px;
}
.m-bottom-40 {
	margin-bottom: 40px;
}
.m-bottom-35 {
	margin-bottom: 35px;
}
.m-bottom-30 {
	margin-bottom: 30px;
}
.m-bottom-25 {
	margin-bottom: 25px;
}
.m-bottom-20 {
	margin-bottom: 20px;
}
.m-bottom-15 {
	margin-bottom: 15px;
}
.m-bottom-10 {
	margin-bottom: 10px;
}
.m-bottom-5 {
	margin-bottom: 5px;
}
.m-bottom-0 {
	margin-bottom: 0;
}

/*------------------------------------------*/
/*    Margin Left
/*------------------------------------------*/

.m-left-70 {
	margin-left: 70px;
}
.m-left-60 {
	margin-left: 60px;
}
.m-left-50 {
	margin-left: 50px;
}
.m-left-45 {
	margin-left: 45px;
}
.m-left-40 {
	margin-left: 40px;
}
.m-left-35 {
	margin-left: 35px;
}
.m-left-30 {
	margin-left: 30px;
}
.m-left-25 {
	margin-left: 25px;
}
.m-left-20 {
	margin-left: 20px;
}
.m-left-15 {
	margin-left: 15px;
}
.m-left-10 {
	margin-left: 10px;
}
.m-left-5 {
	margin-left: 5px;
}

/*------------------------------------------*/
/*    Margin Right
/*------------------------------------------*/

.m-right-70 {
	margin-right: 70px;
}
.m-right-60 {
	margin-right: 60px;
}
.m-right-50 {
	margin-right: 50px;
}
.m-right-45 {
	margin-right: 45px;
}
.m-right-40 {
	margin-right: 40px;
}
.m-right-35 {
	margin-right: 35px;
}
.m-right-30 {
	margin-right: 30px;
}
.m-right-25 {
	margin-right: 25px;
}
.m-right-20 {
	margin-right: 20px;
}
.m-right-15 {
	margin-right: 15px;
}
.m-right-10 {
	margin-right: 10px;
}
.m-right-5 {
	margin-right: 5px;
}

/*------------------------------------------*/
/*    Padding Top
/*------------------------------------------*/

.p-top-160 {
	padding-top: 160px;
}
.p-top-150 {
	padding-top: 150px;
}
.p-top-140 {
	padding-top: 140px;
}
.p-top-130 {
	padding-top: 130px;
}
.p-top-120 {
	padding-top: 120px;
}
.p-top-110 {
	padding-top: 110px;
}
.p-top-100 {
	padding-top: 100px;
}
.p-top-90 {
	padding-top: 90px;
}
.p-top-80 {
	padding-top: 80px;
}
.p-top-70 {
	padding-top: 70px;
}
.p-top-60 {
	padding-top: 60px;
}
.p-top-50 {
	padding-top: 50px;
}
.p-top-45 {
	padding-top: 45px;
}
.p-top-40 {
	padding-top: 40px;
}
.p-top-35 {
	padding-top: 35px;
}
.p-top-30 {
	padding-top: 30px;
}
.p-top-25 {
	padding-top: 25px;
}
.p-top-20 {
	padding-top: 20px;
}
.p-top-15 {
	padding-top: 15px;
}
.p-top-10 {
	padding-top: 10px;
}

/*------------------------------------------*/
/*    Padding Bottom
/*------------------------------------------*/

.p-bottom-160 {
	padding-bottom: 160px;
}
.p-bottom-150 {
	padding-bottom: 150px;
}
.p-bottom-140 {
	padding-bottom: 140px;
}
.p-bottom-130 {
	padding-bottom: 130px;
}
.p-bottom-120 {
	padding-bottom: 120px;
}
.p-bottom-110 {
	padding-bottom: 110px;
}
.p-bottom-100 {
	padding-bottom: 100px;
}
.p-bottom-90 {
	padding-bottom: 90px;
}
.p-bottom-80 {
	padding-bottom: 80px;
}
.p-bottom-70 {
	padding-bottom: 70px;
}
.p-bottom-60 {
	padding-bottom: 60px;
}
.p-bottom-50 {
	padding-bottom: 50px;
}
.p-bottom-45 {
	padding-bottom: 45px;
}
.p-bottom-40 {
	padding-bottom: 40px;
}
.p-bottom-35 {
	padding-bottom: 35px;
}
.p-bottom-30 {
	padding-bottom: 30px;
}
.p-bottom-25 {
	padding-bottom: 25px;
}
.p-bottom-20 {
	padding-bottom: 20px;
}
.p-bottom-15 {
	padding-bottom: 15px;
}
.p-bottom-10 {
	padding-bottom: 10px;
}
.p-bottom-0 {
	padding-bottom: 0;
}

/*------------------------------------------*/
/*    Padding Left
/*------------------------------------------*/

.p-left-100 {
	padding-left: 100px;
}
.p-left-90 {
	padding-left: 90px;
}
.p-left-80 {
	padding-left: 80px;
}
.p-left-70 {
	padding-left: 70px;
}
.p-left-60 {
	padding-left: 60px;
}
.p-left-50 {
	padding-left: 50px;
}
.p-left-45 {
	padding-left: 45px;
}
.p-left-40 {
	padding-left: 40px;
}
.p-left-35 {
	padding-left: 35px;
}
.p-left-30 {
	padding-left: 30px;
}
.p-left-25 {
	padding-left: 25px;
}
.p-left-20 {
	padding-left: 20px;
}
.p-left-15 {
	padding-left: 15px;
}
.p-left-10 {
	padding-left: 10px;
}
.p-left-5 {
	padding-left: 5px;
}
.p-left-0 {
	padding-left: 0px;
}

/*------------------------------------------*/
/*    Padding Right
/*------------------------------------------*/

.p-right-100 {
	padding-right: 100px;
}
.p-right-90 {
	padding-right: 90px;
}
.p-right-80 {
	padding-right: 80px;
}
.p-right-70 {
	padding-right: 70px;
}
.p-right-60 {
	padding-right: 60px;
}
.p-right-50 {
	padding-right: 50px;
}
.p-right-45 {
	padding-right: 45px;
}
.p-right-40 {
	padding-right: 40px;
}
.p-right-35 {
	padding-right: 35px;
}
.p-right-30 {
	padding-right: 30px;
}
.p-right-25 {
	padding-right: 25px;
}
.p-right-20 {
	padding-right: 20px;
}
.p-right-15 {
	padding-right: 15px;
}
.p-right-10 {
	padding-right: 10px;
}
.p-right-5 {
	padding-right: 5px;
}
.p-right-0 {
	padding-right: 0px;
}

/*------------------------------------------*/
/*   BACKGROUND SETTINGS
/*------------------------------------------*/

.bg-scroll {
	width: 100%;
	background-attachment: fixed !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.bg-fixed {
	width: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

/*------------------------------------------*/
/*   Background Colors for Sections
/*------------------------------------------*/

.bg-dark {
	background-color: #2c353f;
}
.bg-lightdark {
	background-color: #252d35;
}
.bg-deepdark {
	background-color: #1d1d1d;
}
.bg-lightgrey {
	background-color: #f0f0f0;
}
.bg-grey {
	background-color: #e8e8e8;
}
.bg-deepgrey {
	background-color: #ddd;
}
.bg-rose {
	background-color: #ff3366;
}
.bg-red {
	background-color: #e35029;
}
.bg-yellow {
	background-color: #feca16;
}
.bg-green {
	background-color: #42a045;
}
.bg-lightgreen {
	background-color: #59bd56;
}
.bg-deepgreen {
	background-color: #009587;
}
.bg-blue {
	background-color: #2154cf;
}
.bg-lightblue {
	background-color: #1e88e5;
}
.bg-deepblue {
	background-color: #004861;
}
.bg-tinyblue {
	background-color: #e6f9fa;
}
.bg-purple {
	background-color: #6e45e2;
}
.bg-deeppurple {
	background-color: #510fa7;
}
.bg-lightpurple {
	background-color: #715fef;
}

/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-top {
	border-top: 1px solid #ddd;
}
.b-bottom {
	border-bottom: 1px solid #ddd;
}
.b-left {
	border-left: 1px solid #ddd;
}
.b-right {
	border-right: 1px solid #ddd;
}
