html {
	background-color: #0d0337;
}

body {
	font-weight: 500;
}

small {
	display: block;
}

// Remove bad transitions
a, button, .btn {
	transition-property: color;
}

.navbar {
	transition-property: background-color, padding;
}
