/* ==========================================================================
  29.  SCROLL TO TOP
  =========================================================================== */

#scrollUp {
	display: none;
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-image: url('/vendor/pergo/images/back-to-top.png');
	background-repeat: no-repeat;
	background-position: 50% 48%;
	background-color: rgba(35, 35, 35, 0.5);
	border-radius: 100%;
	transition: all 250ms linear;
}

#scrollUp:hover {
	background-color: #666;
}

nav a#pull {
	display: none;
}
