// Our Pictograms used instead of Flaticons so we adapt Pergo's .flaticon styles
.pictogram {
	width: 4rem;
	height: 4rem;

	&-sm {
		width: 3.5rem;
		height: 3.5rem;
	}

	&-md {
		width: 4.5rem;
		height: 4.5rem;
	}

	&-lg {
		width: 5rem;
		height: 5rem;
	}
}

@media (min-width: 992px) and (max-width: 1199.99px) {
	.pictogram {
		width: 3.75rem;
		height: 3.75rem;

		&-sm {
			width: 3.25rem;
			height: 3.25rem;
		}

		&-md {
			width: 4rem;
			height: 4rem;
		}

		&-lg {
			width: 4.5rem;
			height: 4.5rem;
		}
	}
}

@media (min-width: 768px) and (max-width: 991.99px) {
	.pictogram {
		width: 3.75rem;
		height: 3.75rem;

		&-sm {
			width: 3.25rem;
			height: 3.25rem;
		}

		&-md {
			width: 4rem;
			height: 4rem;
		}

		&-lg {
			width: 4.5rem;
			height: 4.5rem;
		}
	}
}

@media (min-width: 576px) and (max-width: 767.99px) {
	.pictogram {
		width: 3.75rem;
		height: 3.75rem;

		&-sm {
			width: 3.5rem;
			height: 3.5rem;
		}

		&-md {
			width: 3.75rem;
			height: 3.75rem;
		}

		&-lg {
			width: 4.25rem;
			height: 4.25rem;
		}
	}
}

@media (max-width: 575px) {
	.pictogram {
		width: 3.75rem;
		height: 3.75rem;

		&-sm {
			width: 3.5rem;
			height: 3.5rem;
		}

		&-md {
			width: 3.75rem;
			height: 3.75rem;
		}

		&-lg {
			width: 4.5rem;
			height: 4.5rem;
		}
	}
}
