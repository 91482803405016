/* ==========================================================================
  24. BOOKING FORM
  ========================================================================== */

#booking {
	background: url('/vendor/pergo/images/tra-bg1.png');
}

#booking-form {
	margin: 0 25px;
}

/*------------------------------------------*/
/*    Register Form Input
/*------------------------------------------*/

#booking-form .form-control {
	height: 54px;
	background-color: #fff;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	color: #333;
	font-size: 1rem;
	padding: 5px 15px;
	margin-bottom: 20px;
	border-radius: 0;
	transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*    Bookingr Form Placeholder
/*------------------------------------------*/

#booking-form .form-control::-moz-placeholder {
	color: #aaa;
}
#booking-form .form-control:-ms-input-placeholder {
	color: #aaa;
}
#booking-form .form-control::-webkit-input-placeholder {
	color: #aaa;
}

/*------------------------------------------*/
/*    Register Form Input Focus
/*------------------------------------------*/

#booking-form .form-control:focus {
	border-color: #64b671;
	outline: 0;
	box-shadow: none;
}

/*------------------------------------------*/
/*   Register Form Submit Button
/*------------------------------------------*/

#booking-form .btn {
	width: 100%;
	height: 54px;
	font-size: 1.1rem;
	font-weight: 600;
	border-radius: 0;
}

/*------------------------------------------*/
/*    Register Form Message
/*------------------------------------------*/

.booking-form-msg {
	margin-top: 20px;
}

.booking-form-msg .loading {
	font-size: 1.1875rem;
	line-height: 1;
	font-weight: 400;
}

.error {
	color: #fe4918;
	font-size: 0.875rem;
	line-height: 1.3;
	font-weight: 300;
}
