/* ==========================================================================
  27. BOTTOM QUICK FORM
  =========================================================================== */

.bottom-form {
	background-color: #fff;
	min-width: 260px;
	max-width: 260px;
	border-radius: 8px 8px 0 0;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.33);
	position: fixed;
	right: 6em;
	bottom: 0;
	z-index: 99999;
	border: none;
	text-shadow: none;
	box-shadow: none;
	outline: 0px none;
}

/*------------------------------------------*/
/*   QUICK FORM HEADER
/*------------------------------------------*/

.bottom-form-header {
	padding: 14px 20px;
	min-height: 45px;
	border-radius: 6px 6px 0 0;
	cursor: pointer;
	border: none;
	text-shadow: none;
	box-shadow: none;
	outline: 0px none;
}

.bottom-form-header .svg-inline--fa {
	text-align: center;
	color: #fff;
	font-size: 23px;
	line-height: 1;
	display: inline-block;
	float: left;
	margin-top: 1px;
	margin-right: 8px;
}

.bottom-form-header p {
	color: #fff;
	font-size: 0.95rem;
	line-height: 23px !important;
	display: inline-block;
	margin-bottom: 0;
}

/*------------------------------------------*/
/*   QUICK FORM FORM
/*------------------------------------------*/

.bottom-form-holder {
	height: 311px;
	padding-bottom: 30px;
	border: 1px solid #eee;
	border-top: none;
}

.bottom-form-control {
	width: 100%;
	border: none;
	border-bottom: 1px solid #eee;
	font-size: 0.9rem;
	line-height: 1;
	padding: 18px;
}

.quick-contact-form textarea {
	min-height: 60px;
	border-bottom: none;
}

.quick-contact-form .btn {
	font-size: 0.9rem;
	padding: 0.6rem 1.75rem;
	margin-right: 30px;
}

/*------------------------------------------*/
/*    Quick Form Message
/*------------------------------------------*/

.quick-contact-form label.error {
	color: #f6412d;
	font-size: 0.95rem;
	line-height: 1;
	margin-bottom: 20px;
	margin-left: 35px;
}

.quick-contact-form .contact-form-msg {
	position: absolute;
	bottom: 80px;
	left: 20px;
}

.quick-contact-form .contact-form-msg .error,
.quick-contact-form .contact-form-msg .loading {
	color: #45afff;
	font-size: 0.9rem;
	line-height: 1;
	font-weight: 300;
}

.quick-contact-form .contact-form-msg .error {
	color: #f6412d;
}
