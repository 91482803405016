/* ===================================================================================
  17.  MORE ITEMS
  =================================================================================== */

/*------------------------------------------*/
/*    MORE APPS TYPOGRAPHY
/*------------------------------------------*/

.more-item-box h5 {
	line-height: 1;
	margin-bottom: 10px;
	margin-top: 35px;
}

.more-item-box span.app-cat {
	color: #888;
	font-size: 0.95rem;
	line-height: 1;
	display: block;
	margin-bottom: 10px;
}

.more-item-box .btn {
	margin-top: 10px;
}
