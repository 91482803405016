/* ==========================================================================
  14.  SCHEDULE
  =========================================================================== */

/*------------------------------------------*/
/*   SCHEDULE TABS NAVIGATION
/*------------------------------------------*/

.nav-tabs {
	margin-bottom: 60px;
}

.bg-dark .nav-tabs {
	border-color: #666;
}

.nav {
	width: 100%;
}

.nav-tabs .nav-link {
	color: #999;
	font-size: 1.15rem;
	font-weight: 500;
	letter-spacing: 0;
	border: none;
	padding: 0.85rem 0;
}

.nav-tabs.rose-nav .nav-link.active,
.nav-tabs.rose-nav .nav-item.show .nav-link {
	color: #fff;
	background-color: #ff3366;
}

.nav-tabs.yellow-nav .nav-link.active,
.nav-tabs.yelloe-nav .nav-item.show .nav-link {
	color: #333;
	background-color: #feca16;
}

.nav-tabs.grey-nav .nav-link.active,
.nav-tabs.grey-nav .nav-item.show .nav-link {
	color: #333;
	background-color: #ccc;
}

/*------------------------------------------*/
/*   SCHEDULE TABS CONTENT
/*------------------------------------------*/

.schedule-event {
	margin-bottom: 15px;
}

/*------------------------------------------*/
/*    Event Time
/*------------------------------------------*/

.event-time span {
	font-size: 1rem;
	line-height: 1.3;
	font-weight: 700;
	display: block;
	padding-left: 10px;
}

/*------------------------------------------*/
/*    Session Description
/*------------------------------------------*/

.event-description {
	padding-left: 0;
}

.event-description h5 {
	margin-bottom: 7px;
}

.event-description p.event-speaker {
	color: #757575;
	margin-bottom: 0;
}

.bg-dark .event-description p.event-speaker {
	color: #ddd;
}

.event-description p.p-small {
	color: #777;
	line-height: 1.3;
	margin-top: 8px;
}

.bg-dark .event-description p.p-small {
	color: #aaa;
}

.event-description hr {
	border-color: #ccc;
	margin-top: 25px;
	margin-right: 25px;
}

.bg-dark .event-description hr {
	border-color: #666;
}

/*------------------------------------------*/
/*    AGENDA TITLE
/*------------------------------------------*/

.agenda-tittle {
	margin-top: 45px;
	margin-bottom: 90px;
	padding: 25px 30px;
	background-color: #444;
}

.agenda-tittle h3 {
	line-height: 1;
	margin-bottom: 3px;
}

.agenda-tittle span {
	font-size: 1.1rem;
	line-height: 1;
	margin-bottom: 0;
}
